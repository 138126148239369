import React from 'react'
import './Modal.css'

const Modal =({open, setOpen, Content})=>{
    
return(
    <> {open &&
        <div className="modal" >
            <div className="modalContent">
            <span className="close" onClick={()=>{setOpen(false)}}>&times;</span>
                <Content open={open} setOpen={setOpen}/>
                {/*<button className="closeButton" onClick={()=>{setOpen(false)}}>Fechar</button> */}  
            </div>
            
        </div>}
    </>
)
}

export default Modal