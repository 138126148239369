import React from 'react'

const Logo = () => {
    return (
        <>    
            <a href="/"><img className={"logo"} src="../logo-black.png"  alt="Tmenu"/></a>
        </>
    );
}

export default Logo
