import React, { useEffect } from "react";
import { Navigate } from 'react-router-dom'
import { UserContext } from '../../UserContext';

function Logout(){
    const { userLogout } = React.useContext(UserContext);

    useEffect(()=>{
        //window.localStorage.removeItem('token')
        userLogout()
    })
    return <Navigate to="/login"/>
}

export default Logout