import React from "react";
import { Grid,
  GridCell,
  Typography,
  Card,
  Badge,
  Button,
  GridRow,
  Icon,
  SimpleMenu,
  IconButton,
  MenuItem,
  Dialog,
  Select,
  TextField,
  DialogActions,
  DialogButton,
  DialogTitle,
  DialogContent,
  CircularProgress,
  SimpleDialog,
  Snackbar,
  SnackbarAction
 } from "rmwc";
import MainNav from "../../MainNav";
import Modal from '../Modal/Modal'
import CreateOrder from '../Orders/CreateOrder'
import { UserContext } from '../../UserContext';
import "./Tables.css";
import {GET_TABLES, GET_COMPANY, PUT_ADD_WAITER_TO_TABLE, 
  PUT_UPDATE_TABLE, GET_LAST_ORDERS, DELETE_NOTIFICATION,
  PUT_CLOSE_ORDER, PUT_CLOSE_TABLE, API_URL} from '../../Api'
import Ws from '@adonisjs/websocket-client'
import DialogCenter from "../Modal/DialogCenter";
import TableAccount from '../Tables/TableAccount'
import {config} from '../../ApiUrls'

const TablesIndex = () => {
  const url  = config.url.URL_WS
  //const [tables, setTables] = React.useState([])
  const [company, setCompany] = React.useState({})
  const [waiters, setWaiters] = React.useState([])
  //const [loadind, setLoading] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const [openWaiter, setOpenWaiter] = React.useState(false)
  const [openOrder, setOpenorder] = React.useState(false)
  const [opneBilling, setOpenbilling] = React.useState(false)
  const [openSnack, setOpenSnack] = React.useState(false)
  const [paginate, setPaginate] = React.useState({total:0, perPage:5, page:1, lastpage:0})
  const { tableid, setTableId, setNewOrder, isManager, getTables, tables, loadind, setLoading } = React.useContext(UserContext);
  const [closeTableId, setCloseTableId] = React.useState(0)
  const [message, setMessage] = React.useState('')
  const [messageAccount, setMessageAccount] = React.useState('')
  const[lastOrder, setOrder] = React.useState(0)
  const token = window.localStorage.getItem('token') 
  const ws = Ws(url).withApiToken(token).connect()
  const ws1 = Ws(url).withApiToken(token).connect()
  const ws2 = Ws(url).withApiToken(token).connect()
  const order = ws.subscribe('notifications')
  const account = ws1.subscribe('account')
  const accountTable = ws2.subscribe('account-table')
  const callWaiter = ws2.subscribe('call-waiter')
  const [arrayTables, setArrayTAbles] = React.useState([])
  const [renderToPortal, setRenderToPortal] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false)
  const [callingWaiter, setCallingWaiter] = React.useState(false)
  const [tableCalling, setTableCalling] = React.useState(0)
  function getWaiters(waiters){
    const wt = {}
    waiters.map(waiter=>{
      wt[waiter.id] = waiter.name      
    })
    return wt
  }
  

  
  const getCompany = async () =>{
    const {url, options} = GET_COMPANY(token)
    const response = await fetch(url, options)
    const {company} = await response.json()
    setCompany(company)
    setWaiters(getWaiters(company.waiters))
  }

  // const getTables = async ()=>{
  //   try {     
  //     const {url, options} = GET_TABLES(token, paginate)
  //     const response = await fetch(url, options)
  //     if(!response.ok) throw new Error(`Error: ${response.statusText}`)
  //     const {tables} = await response.json()
  //     setTables(tables.data)
  //     setPaginate({total:tables.total, perPage:tables.perPage, page:tables.page, lastpage:tables.lastPage})
  //   } catch (error) {
  //     console.log(error)
  //   }finally{
  //     setLoading(false)
  //   }
  // }

  const setWaiter = async event =>{
    event.preventDefault()  
    try {
      const {url, options} = PUT_ADD_WAITER_TO_TABLE(token, tableid, event.target.waiter.value)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      getTables()      
    } catch (error) {
      console.log(error)
    }
  }

const setTable = (table_id) =>{
  setTableId(table_id)
  setOpenWaiter(true)
}

const tableNumber=(messageResponse)=>{
  return messageResponse.slice(20,23)
}
  order.on('new:order', (response)=>{
    const tab = parseInt(tableNumber(response).replace(" ", ""))
    if(!arrayTables.includes(tab)){
      arrayTables.push(tab)
    }
    getTables()
    //setNewOrder(arrayTables.length)
  })
  account.on('new:card', (response)=>{
    console.log("CLS", response)
    setMessageAccount(response)
    getTables()
    //setNewOrder(newOrders+=1)
  })

  accountTable.on('new:table', (response)=>{
    setMessageAccount(response)
    getTables()
  })

  callWaiter.on('call', (response)=>{
    setTableCalling(response)
    setCallingWaiter(true)
    setMessageAccount(`Mesa ${response} chamando garçom!`)
    getTables()
  })
const openTable = async event =>{
  event.preventDefault()
  try {
    const body ={
      status:true
    }
    const {url, options} = PUT_UPDATE_TABLE(token,body, event.target.id)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(`Error: ${response.statusText}`)
    const {table} = await response.json()
    setMessage(`Mesa ${table.number} aberta`)
    setOpenSnack(true)
    getTables()
  } catch (error) {
    console.log(error)
  }
}
 

  const getLastOrders = async (tableId, lastOrder, id) =>{
    try {
      removeNotification(id)
      const {url, options} =GET_LAST_ORDERS(token, lastOrder)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {order} = await response.json()
      setOrder(order)
      setTableId(tableId)
      setAsking(tableId)
      setOpenDialog(true)
    } catch (error) {
      console.log(error)
    }
  }

  const closeTable = async event =>{
    event.preventDefault()
    try {
      const body = {
        data:[]
      }
      const {url, options} = PUT_CLOSE_TABLE(token, closeTableId, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {pdf} = await response.json()
      setMessage('Conta fechada com sucesso!')
      setOpenSnack(true)
      getTables()
      window.open(`${API_URL}download/pdf/${pdf}`)
    } catch (error) {
      console.log(error)
    }
  }

  const openModalBilling = async event =>{
    event.preventDefault()
    try {
      setCloseTableId(event.target.id)   
      setOpenbilling(true)  
    } catch (error) {
      console.log(error)
    }
    
  }

  const removeNotification = async (id) =>{
    try {
      const {url, options} = DELETE_NOTIFICATION(token, id)
      const response = await fetch(url ,options)
      if(response.ok){
        getTables()
      }
    } catch (error) {
      console.log(error)
    }
  }

 const setAsking = async (id) =>{
   try {
     const body ={
      asking:false,
      last_order:0,
      orders:0
     }
     const intId = parseInt(id)
     const idx = tables.findIndex((tab)=>tab.id === intId)
     if(idx>=0){
      
       arrayTables.splice(parseInt(arrayTables.indexOf(tables[idx].number)))
       console.log("CLS idx",  arrayTables)
       setArrayTAbles([...arrayTables])
     }
     //setNewOrder(arrayTables.length)
     const {url, options} = PUT_UPDATE_TABLE(token, body, id)
     const response = await fetch(url, options)
     if(!response.ok) throw new Error(response.statusText)
     getTables()
   } catch (error) {
     console.log(error)
   }
 }

 const openModalAddAttributes=(table_id)=>{
    setTableId(table_id)
    setOpen(true)
 }

 const openDialogAccount=(table_id)=>{
   setTableId(table_id)
   setOpenDialog(true)

 }

 const removeCalledWaiter =(id, number)=>{
  removeNotification(id)
  setMessage(`Envie um garçom para a mesa ${number}`) 
  setMessageAccount('')
   setTableCalling(0)
   setCallingWaiter(false)
   getTables()
   setOpenSnack(true)
   
 }

  React.useEffect(()=>{
    getTables()
    getCompany()
  },[])
  
  return (
    <>          
    <MainNav/>
    { loadind && <div className="loading" ><CircularProgress size={125} /></div>}

    <Modal open={open} setOpen={setOpen} Content={CreateOrder}/>
    <DialogCenter open={openDialog} setOpen={setOpenDialog} Content={TableAccount} getTAbles={getTables} openNewOrder={open} setOpenNewOrder={setOpen}/>

    { !loadind && <Dialog open={opneBilling} onClose={evt => {
      console.log(evt.detail.action);
      setOpenbilling(false);
    }}
  onClosed={evt => console.log(evt.detail.action)} >
      <DialogTitle>Fechar conta da mesa</DialogTitle>
          <form onSubmit={closeTable} >
          <DialogContent>     
             { company.configuration && company.configuration.other_rate &&<div>Adicionais</div>}
             { company.configuration && !company.configuration.other_rate &&<div>Sem Adicionais</div>}
          </DialogContent>
          <DialogActions>
            <DialogButton className={"BtnDefaultTmenu"} action="accept" isDefaultAction>
              Fechar!
            </DialogButton>
          </DialogActions>
          </form>
        </Dialog>}

    <Dialog open={openWaiter} onClose={evt => {
      console.log(evt.detail.action);
      setOpenWaiter(false);
    }}
  onClosed={evt => console.log(evt.detail.action)}>
      <DialogTitle>Selecione o garçom</DialogTitle>
      <form onSubmit={setWaiter}>
      <DialogContent>     
          {company && <Select label="Selecione o garçom" name="waiter" options={waiters}/>}
      </DialogContent>
      <DialogActions>
        <DialogButton className={"BtnDefaultTmenu"} action="accept" isDefaultAction>
          Definir!
        </DialogButton>
      </DialogActions>
      </form>
    </Dialog>

    <Dialog open={openOrder} onClose={evt => {
      //console.log(evt.detail.action);
      //setAsking(evt.detail.action)
      setOpenorder(false);
    }}
  onClosed={evt => console.log(evt.detail.action)}>
      <DialogTitle>Últimos pedidos</DialogTitle>
      <DialogContent>     
         { lastOrder &&
           lastOrder.itens.map(item=>{
             return(
               <div>{item.quantity} {item.product_name} </div>
             )
           })
         }
      </DialogContent>
      <DialogActions>
        <DialogButton className={"BtnDefaultTmenu"} action={lastOrder.table_id} isDefaultAction={false} id={lastOrder.table_id} onClick={()=>setAsking(lastOrder.table_id)}>
          ok
        </DialogButton>
      </DialogActions>
    </Dialog>

     { !loadind && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Mesas</Typography></h1>             
          </div>
          <Grid className={"CardsMesas"} z={1}>
            {tables && tables.map(table=>{
              return(
                <GridCell span={3}>
                <Card>
                  <Grid>  
                  
                      <GridRow>
                        <GridCell span={6}>
                          { table.status && <Badge className={"TmenuSuccess"} align="inline" label="Em atendimento" />} 
                          { !table.status && <Badge className={"TmenuDanger"} align="inline" label="Fechada" />} 
                        </GridCell>
                        <GridCell span={8}>
                          <SimpleMenu handle={<Button style={{color: "black"}} label="Ações" icon="settings" />}>
                                  { table.status && <MenuItem onClick={()=>openModalAddAttributes(table.id)}><Icon icon="add_circle_outline" /> Incluir Pedido</MenuItem>}
                                  { table.status &&<MenuItem onClick={() => {setRenderToPortal(true);  openDialogAccount(table.id);}}><Icon icon="visibility" /> 
                                    Ver Extrato
                                  </MenuItem>}
                                  { table.status && <MenuItem onClick={()=>openDialogAccount(table.id)} id={table.id}><Icon icon="account_balance_wallet" /> Fechar Conta</MenuItem>}
                                  {!table.status && <MenuItem onClick={openTable} id={table.id}><Icon icon="add_to_queue" /> Abrir Mesa</MenuItem>}
                                  { table.status && <MenuItem onClick={()=>setTable(table.id)}><Icon icon="switch_account" /> Definir Garçom</MenuItem>}
                          </SimpleMenu>
                        </GridCell>
                      </GridRow>
                  </Grid>                  
                  
                  <span className={"CardsMesasTitle"}>MESA</span>
                  <span className={"CardsMesasValue"}>{table.number}</span>
                  { table.waiter && <span className={"AttendedBy"}>Mesa atendida por: <span className={"strong"}>{table.waiter.name}</span></span>}
                  { !table.waiter && <span className={"AttendedBy"}>Sem garçom definido</span>}
                  {/* { !table.asking && !table.calling && tableCalling !== table.number && */}
                  {
                    table.notifications.length === 0 &&
                    <a >
                    <div className={`CardsMesasAlertZone`}>
                        Nenhum novo pedido
                    </div>
                  </a>}
                  {/* { table.calling && !callingWaiter && */}
                  { table.notifications.length >= 1 && table.notifications[0].type === 'asking_the_account' &&
                     <a >
                     <div className={"CardsMesasAlertZone AlertZoneRequestTheBill"} onClick={()=>openDialogAccount(table.id)}>                        
                       <Icon icon="notification_important" />
                       <Typography use="overline" className={"strong"} id={table.id} > {table.notifications[0].description}</Typography>
                     </div>
                   </a>
                  }
                  {/* { table.asking && !table.calling && !callingWaiter && */}
                  { table.notifications.length >= 1 && table.notifications[0].type === 'new_order' &&
                    <a>                  
                    <div className={"CardsMesasAlertZone AlertZoneRequestNewOrder"}  onClick={()=>getLastOrders(table.id, table.last_order, table.notifications[0].id)}>                        
                      <Badge align="inline" label={table.orders} style={{ background: '#2196f3' }}/>
                      <Typography use="overline" className={"strong"} id={table.last_order}> { table.orders === 1?"Novo pedido!":"Novos pedidos!"}</Typography>
                    </div>
                    </a>
                  }

                  {/* { callingWaiter && tableCalling === table.number && */}
                    { table.notifications.length >= 1 && table.notifications[0].type === 'call_waiter' &&
                    <a >
                    <div className={"CardsMesasAlertZone AlertZoneRequestCallWaiter"} onClick={()=>removeCalledWaiter(table.notifications[0].id, table.number)}>                        
                      <Icon icon="notification_important" />
                      <Typography use="overline" className={"strong"} id={table.id} > {table.notifications[0].description.slice(7)}</Typography>
                    </div>
                  </a>
                  }

                </Card>
              </GridCell>
              )
            })
              
            } 
            </Grid> 
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />

        </div>   }
      
    </>
  );
};

export default TablesIndex;
