import React from "react";
import { Grid, GridCell, GridRow,
  Typography,
  Button,
  TextField,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  SimpleMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
  Select,
  Badge,
  IconButton,
  Icon,
  CircularProgress,
  Checkbox,
  Snackbar,
  SnackbarAction,
  ImageList,
  ImageListItem,
  ImageListImageAspectContainer,
  ImageListImage,
  ImageListSupporting,
  ImageListLabel,
} from "rmwc";
import MainNav from "../../MainNav";
import { GET_COMPANY, DELETE_TABLE, PUT_UPDATE_TABLE, POST_TABLE,
  PUT_CONFIGURATION, 
  POST_ADD_IMAGE_COMPANY, DELETE_IMAGE_COMPANY,
  API_URL, PUT_COMPANY
} from "../../Api";

const SettingsIndex = () => {
  const [loading, setLoading] = React.useState(true)
  const [outerRate, setOuterRate] = React.useState(false)
  const [waiterRate, setWaiterRate] = React.useState(false)
  const token = window.localStorage.getItem('token')
  const [company, setCompany] = React.useState({})
  const [tables, setTables] = React.useState([])
  const [table, setTable] = React.useState(null)
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openPlan, setOpenPlan] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [message, setMessage] = React.useState('')
  const [openSnack, setOpenSnack] = React.useState(false)
  const [number, setNumber] = React.useState(null)
  const [openform, setOpenform] = React.useState(false)
  const [openDeleteImage, setOpenDeleteImage] = React.useState(false);
  const [img, setImg] = React.useState({})
  const [imgDelete, setImgDelete] = React.useState({})
  const [planId, setPlanId] = React.useState(0)
  const [name, setName] = React.useState('')
  const [email, setEmail]= React.useState('')
  const [cnpj, setCnpj]= React.useState('')
  const [phone, setPhone]= React.useState('')
  const [responsible, setResponsible] = React.useState('')
  const [responsiblePhone, setResponsiblePhone] = React.useState('')

  const getCompany = async ()=>{
    try {
      const {url, options} = GET_COMPANY(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {company} = await response.json()
      setCompany(company)
      setOuterRate(company.configuration.other_rate?true:false)
      setWaiterRate(company.configuration.waiter_rate?true:false)
      setName(company.name)
      setEmail(company.email)
      setCnpj(company.cnpj)
      setPhone(company.phone)
      setResponsible(company.responsible)
      setResponsiblePhone(company.responsible_phone)
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  const toDelete = async (id) =>{
    try {
      const {url, options} = DELETE_TABLE(token, id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      setMessage(`Messa Excluida!`)
      setOpenSnack(true)
      getCompany()
    } catch (error) {
      console.log(error)
    }
  }

  const toUpdate = async event =>{
    event.preventDefault()
    try {
      const body = {
        number:event.target.number.value
      } 
      const {url, options} = PUT_UPDATE_TABLE(token, body, table.id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      setMessage(`Mesa Atualizada!`)
      setOpenSnack(true)
      getCompany()
    } catch (error) {
      console.log(error)
    }
  }
  const editTable = (id)=>{
    const idx = company.tables.findIndex((table)=> table.id === id)
    setTable(company.tables[idx])
    setNumber(company.tables[idx].number)
    setOpenEdit(true)
  }
  const deleteTable = (id)=>{
    const idx = company.tables.findIndex((table)=> table.id === id)
    setTable(company.tables[idx])
    setOpenDelete(true)
  }

  const handleSubmit = async event =>{
    event.preventDefault()
    try {
      const body = {
        number: event.target.number.value
      }
      const {url, options} = POST_TABLE(token, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {table} = await response.json()
      setMessage(`Mesa número ${table.number} cadastrada com sucesso.`)
      setOpenSnack(true)
      setOpenform(false)
      getCompany()
    } catch (error) {
      console.log(error)
    }
  }

  const updateOuterRate = async (checked)=>{
   try {
    setOuterRate(checked)
    const body ={
      other_rate: checked
    }
    const {url, options} = PUT_CONFIGURATION(token, company.configuration.id, body)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    setMessage("Configuração atualizada")
    setOpenSnack(true)
   } catch (error) {
     console.log(error)
   } 
  }

  const updateWaiterRate = async (checked)=>{
    try {
      setWaiterRate(checked)
      const body ={
        waiter_rate: checked
      }
      const {url, options} = PUT_CONFIGURATION(token, company.configuration.id, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      setMessage("Configuração atualizada")
      setOpenSnack(true)
     } catch (error) {
       console.log(error)
     } 
  }

  const handleImgSubmit = async event=>{
    event.preventDefault()
    try {
        const file = img.raw
        const formData = new FormData()
        for(let i = 0; i < file.length; i++){
            formData.append(`file[${i}]`, file[i])
        }
        const {url, options} = POST_ADD_IMAGE_COMPANY(token,formData)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {message} = await response.json()
        setMessage("Imagem salva!")
        setOpenSnack(true)
        getCompany()
    } catch (error) {
        console.log(error)
    }
 }

 const handleImgChange = async ({target})=>{
    setImg({
        raw:target.files
    })
 }

 const deleteImage = (id)=>{
  const idx = company.images.findIndex((image)=> image.id === id)
  setImgDelete( company.images[idx])
  setOpenDeleteImage(true)
}

const toDeleteImage = async (id)=>{
  try {
    const {url, options} = DELETE_IMAGE_COMPANY(token, id)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    setMessage("Imagem excluido com Sucesso!")
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    console.log(error)
  }
}

const updatePlan = async event =>{
  event.preventDefault()
  console.log(planId)
  try {
    const body = {plan_id:planId}
    const {url, options} = PUT_COMPANY(token, body, company.id)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    setMessage("Imagem excluido com Sucesso!")
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    console.log(error)
  }
} 

const updateCompany = async ()=>{
  try {
    const body={
      name:name,
      email:email,
      cnpj:cnpj,
      phone:phone,
      responsible:responsible,
      responsible_phone:responsiblePhone
    }
    const {url, options} = PUT_COMPANY(token, body, company.id)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    setMessage("Dados atualizados com Sucesso!")
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    console.log(error)
  }
}

const updateAddress = async ()=>{
  try {
    
  } catch (error) {
    console.log(error)
  }
} 
  React.useEffect(()=>{
    getCompany()
  },[])
  return (
    <>  

      <Dialog
            open={openDeleteImage}
            onClose={evt => {
            console.log(evt.detail.action);
            setOpenDeleteImage(false);
            }}
            onClosed={evt => console.log(evt.detail.action)}
        >
            <DialogTitle>Excluir Imagem?</DialogTitle>
            <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
            <DialogActions> 
            <DialogButton action="Denied">Cancelar</DialogButton>      
            <DialogButton action="accept" onClick={()=>toDeleteImage(imgDelete.id)}>
                Excluir
            </DialogButton>
            </DialogActions>
        </Dialog>

    <Dialog
        open={openEdit}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenEdit(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Editar a mesa {table?table.number:""}?</DialogTitle>
        <form onSubmit={toUpdate}>
        <DialogContent>
        <Typography use="overline"><strong>Número:</strong></Typography>
        <TextField type="text" name="number" fullwidth placeholder="Número da mesa" value={number} onChange={({target})=>setNumber(target.value)}/>
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept">
            Salvar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog>

    <Dialog
        open={openDelete}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenDelete(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Excluir a mesa {table?table.number:""}?</DialogTitle>
        <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
        <DialogActions> 
          <DialogButton action="Denied">Cancelar</DialogButton>      
          <DialogButton action="accept" onClick={()=>toDelete(table.id)}>
            Excluir
          </DialogButton>
        </DialogActions>
      </Dialog>

      <Dialog
            open={openPlan}
            onClose={evt => {
            console.log(evt.detail.action);
            setOpenPlan(false);
            }}
            onClosed={evt => console.log(evt.detail.action)}
        >
            <DialogTitle>Alterar Plano</DialogTitle>
            <form onSubmit={updatePlan}>
            <DialogContent>
              { company.plan_id !== 1 && <Checkbox label={"Taxa por comanda"} name="comanda" id="1" onChange={evt => setPlanId(evt.currentTarget.id)}/>}
              { company.plan_id !== 2 && <Checkbox label={"Taxa sobre comição de garçom"} name="garcom" id="2" onChange={evt => setPlanId(evt.currentTarget.id)}/>}
            </DialogContent>
            <DialogActions>     
            <DialogButton action="accept">
                Alterar
            </DialogButton>
            </DialogActions>
            </form>
        </Dialog>


    <MainNav/>
    
    { loading && <div className="loading" ><CircularProgress size={125} /></div>}
     {!loading && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Configurações</Typography></h1>             
          </div>
          <div  className="settingsContainer">
          
            <Grid>
              <GridRow>
                <GridCell span={12}>
                  <GridRow>
                    <GridCell span={6}><Typography use="overline">
                      Nome:
                      <input type="text" name="name" value={name} onChange={({target})=>setName(target.value)} className="inputCusston" size="15"/>
                      </Typography></GridCell>
                    <GridCell span={6}>
                      <Typography use="overline">
                        Email: 
                        <input type="text" name="email" value={email} onChange={({target})=>setEmail(target.value)} className="inputCusston" size="15"/>
                        </Typography></GridCell>
                </GridRow>
                <GridRow>
                    <GridCell span={6}>
                      <Typography use="overline">
                        CNPJ: 
                        <input type="text" name="cnpj" value={cnpj} onChange={({target})=>setCnpj(target.value)} className="inputCusston" size="15"/>
                        
                      </Typography></GridCell>
                    <GridCell span={6}>
                      <Typography use="overline">
                        Fone: 
                        <input type="text" name="phone" value={phone} onChange={({target})=>setPhone(target.value)} className="inputCusston" size="15"/>
                        
                      </Typography></GridCell>
                </GridRow>
                <GridRow>
                    <GridCell span={6}><Typography use="overline">
                      Responsável: 
                      <input type="text" name="responsible" value={responsible} onChange={({target})=>setResponsible(target.value)} className="inputCusston"/>
                      
                      </Typography></GridCell>
                    <GridCell span={6}><Typography use="overline">
                      Telefone Responsável: 
                      <input type="text" name="reponsible_phone" value={responsiblePhone} onChange={({target})=>setResponsiblePhone(target.value)} className="inputCusston"/>
                      </Typography></GridCell>
                </GridRow>
                <GridRow>
                    {/*<GridCell span={6}><Typography use="overline">Categoria: {company.category}</Typography></GridCell>*/}
                    <GridCell span={6}><Typography use="overline">
                      Status: {company.status?"Ativo":"Inativo"}
                      </Typography></GridCell>
                </GridRow>
                <GridRow>
                <GridCell span={8}></GridCell>
                  <GridCell span={4}>
                    <Button label="Atualizar" onClick={()=>updateCompany()} className={"BtnDefaultTmenu"}/>
                  </GridCell>
                </GridRow>
                
                <hr/>
                <GridRow>
                <GridCell span={12}><Typography use="headline5">Configurações</Typography></GridCell>
                </GridRow>
                <GridRow>
                <GridCell span={12}><Typography onClick={()=>setOpenPlan(true)} use="overline">Plano: {company.plan_id === 1?"Taxa por comanda":"Taxa sobre comição de garçom"} <Badge className={"TmenuSuccess"} align="inline"  onClick={()=>openPlan}  label="Alterar" /></Typography></GridCell>
                </GridRow>
                
                <GridRow>
                  <GridCell span={12}>
                    <Checkbox
                        label="Taxa de Garçon"
                        checked={waiterRate}
                        onChange={evt => updateWaiterRate(!!evt.currentTarget.checked)}
                      />
                      <Checkbox
                        label="Outras Taxas"
                        checked={outerRate}
                        onChange={evt =>  updateOuterRate(!!evt.currentTarget.checked)}
                      />
                  </GridCell>
                </GridRow>
                <hr/>
                <GridRow>
                    <GridCell span={12}><Typography use="headline5">Endereço</Typography></GridCell>
                </GridRow>
                <GridRow>
                    <GridCell span={6}><Typography use="overline">
                      Rua: {company.address.street}
                      </Typography></GridCell>
                    <GridCell span={6}><Typography use="overline">
                      Número: {company.address.number}
                      </Typography></GridCell>
                </GridRow>
                <GridRow>
                    <GridCell span={4}><Typography use="overline">
                      Bairro: {company.address.district}
                      </Typography></GridCell>
                    <GridCell span={4}><Typography use="overline">
                      Cidade: {company.address.city}
                      </Typography></GridCell>
                    <GridCell span={4}><Typography use="overline">
                      Estado: {company.address.state}
                      </Typography></GridCell>
                </GridRow>
                <GridRow>
                    <GridCell span={6}><Typography use="overline">
                      Complemento: {company.address.complement}
                      </Typography></GridCell>
                    <GridCell span={6}><Typography use="overline">
                      CEP: {company.address.zipcode}
                      </Typography></GridCell>
                </GridRow>
                {/* <GridRow>
                  <GridCell span={8}>

                  </GridCell>
                  <GridCell span={4}>
                  <Button label="Atualizar" onClick={()=>updateAddress()} className={"BtnDefaultTmenu"}/>
                  </GridCell>
                </GridRow> */}
                <hr/>
                <GridRow>
                <GridCell span={12}>
                    <h5><Typography use="headline5">Fotos de {company.name}</Typography></h5>
                </GridCell>
                </GridRow>
               
                <GridRow>
                    <GridCell span={12}>
                        <h5><Typography use="overline">Adicionar imagem a {company.name}</Typography></h5>
                    </GridCell>
                </GridRow>
                    <form onSubmit={handleImgSubmit}>
                        <GridRow>
                            <GridCell span={6}>
                                <input type="file" name="file[]" onChange={handleImgChange} multiple required/>
                            </GridCell>
                            <GridCell span={6}>
                            <Button label="Salvar imagem" outlined icon="add" className={"BtnDefaultSearch"} type="submit"/>
                            </GridCell>
                        </GridRow>
                    </form>
                    <GridRow>
                        <GridCell span={12}>
                        {company.images.length > 0 && 
                        <ImageList>
                        { company.images.map(image=>(
                          
                            <ImageListItem
                            key={image.id}
                            style={{ margin: '2px', width: 'calc(100% / 5 - 4.2px)' }}
                          > <div  className="prodcutImageDetail">
                            <ImageListImageAspectContainer
                              style={{ paddingBottom: 'calc(100% / 1.5)' }}
                            >
                              <ImageListImage src={`${API_URL}download/img/${image.path}`} />
                            </ImageListImageAspectContainer>
                            
                            <ImageListSupporting className="optionDelete">
                                <ImageListLabel onClick={()=>deleteImage(image.id)}>Excluir imagem</ImageListLabel>
                            </ImageListSupporting>
                            </div>
                          </ImageListItem>
                        ))
                       }                               
                        </ImageList>}
                        </GridCell>
                    </GridRow>
                    <hr/>
                <GridRow>
                <GridCell span={12}><Typography use="headline5">Mesas</Typography></GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={12}>
                    <Button className={"BtnDefaultTmenu"} label={openform?"Fechar":"Adicionar Mesa"} icon="add"  onClick={()=>setOpenform(!openform)}/>

                    {openform && 
              <form lassName="formContainer" onSubmit={handleSubmit}>
              <Grid >
                
                <GridRow>
                    <GridCell span={4}>
                      <TextField type="text" name="number" fullwidth placeholder="Número" />
                    </GridCell>
                    <GridCell span={3}>
                      <Button type="submit" className={"BtnDefaultTmenu"} label="Cadastrar" icon="add" /> 
                    </GridCell>
                </GridRow>
                
              </Grid>
              </form>
            }
                  </GridCell>
                </GridRow>
                <br/>
                <GridRow>
                  <GridCell>
                    <DataTable className={"TabelaProdutos"}>
                      <DataTableContent>
                        <DataTableHead>
                          <DataTableRow>
                            <DataTableHeadCell>Número</DataTableHeadCell>
                            <DataTableHeadCell alignEnd>Código</DataTableHeadCell>
                            <DataTableHeadCell alignEnd></DataTableHeadCell>   
                            <DataTableHeadCell alignEnd></DataTableHeadCell>                          
                          </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                          {
                            company.tables.map(table=>{
                              return(
                                <DataTableRow key={table.id} >
                                  <DataTableCell >{table.number}</DataTableCell>
                                  <DataTableCell >{table.hashcode}</DataTableCell>
                                  <DataTableCell > 
                                    <Button onClick={()=>editTable(table.id)} className={"btnEdit"} icon="edit">
                                    Editar
                                    </Button></DataTableCell>
                                  <DataTableCell >
                                    <Button onClick={()=>deleteTable(table.id)} className={"btnDelete"} icon="delete">
                                    Excluir
                                    </Button></DataTableCell>
                                </DataTableRow>
                              )
                            })
                          }
                        </DataTableBody>
                      </DataTableContent>
                    </DataTable>
                  </GridCell>
                </GridRow>

                </GridCell>
              </GridRow>
              
            </Grid> 
            
            </div>
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        }
      />
           
        </div>}   
      
    </>
  );
};

export default SettingsIndex;
