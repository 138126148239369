import React, { useRef } from 'react'
import { Grid, GridCell, GridRow,
    Typography,
    Button,
    TextField,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,
    SimpleMenu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogButton,
    DialogContent,
    Select,
    Badge,
    IconButton,
    Icon,
    Switch,
    Radio,
    ImageList,
    ImageListItem,
    ImageListImageAspectContainer,
    ImageListImage,
    ImageListSupporting,
    ImageListLabel,
    CircularProgress,
    Snackbar,
    SnackbarAction,
    Checkbox
  } from "rmwc";
import useForm from '../../Hooks/UseForm'
import { API_URL, GET_CLIENTS, GET_PRODUCTS, GET_TABLE, GET_TABLES, POST_CREATE_ORDER, GET_LAST_ORDERS } from '../../Api';
//import {objectToSelect} from '../../Utils'
import { UserContext } from '../../UserContext';
//import IFrame from '../IFrame/IFrame'
import Datelist from '../Datelist/Datelist'

import { PrintOrderCard } from '../Printer/PrintOrderCard' 
import {useReactToPrint} from 'react-to-print';
import './Order.css'
const CreateOrder =()=>{
    const[loadind, setLoading] = React.useState(true)
    const[waiting, setWaiting] = React.useState(false)
    const [clients, setClients] = React.useState([])
    const [table, setTable] = React.useState({})
    const [tables, setTables] = React.useState([])
    const [products, setProducts] = React.useState([])
    const [productlist, setProductlis] = React.useState([])
    const [product, setProduct] = React.useState(null)
    const [user, setUser] = React.useState(null)
    const [order, setOrder] = React.useState([])
    const [pdforder, setPdforder] = React.useState(null)
    const [openSnack, setOpenSnack] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [attributes, setAttributes]= React.useState(null)
    const [attributesArray, setAttributesArrays] = React.useState([])
    const [itens, setItens] = React.useState([])
    const [productOn, setProductOn] = React.useState(null)
    const [orderToShow, setOrderToShow] = React.useState(null) 

    const componentRef = useRef()
    const inputData = useForm()
    const token = window.localStorage.getItem('token')
    const { tableid, setTableId, functionReload, clientInTable, setClientInTable} = React.useContext(UserContext);
    const getData = async ()=>{
        try {
            if(tableid){
                getTable(tableid)
            }else{
                getTables()
            }
            getClient()
            getProducts()
            
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    const getClient = async ()=>{
        try {
            const {url, options} = GET_CLIENTS(token,{page:1, perPage:null})
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {clients} = await response.json()
            setClients(clients.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function getTable(id) {
        try {
            const {url, options} = GET_TABLE(token, id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {table} = await response.json()
            setTable(table)
        } catch (error) {
            console.log(error)
        }
    }

     async function addTable(id){
         setTableId(id)
         getTable(id)

     }
    async function getTables(){
        try {
            const {url, options} = GET_TABLES(token,{page:1, perPage:null})
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {tables} = await response.json()
            setTables(tables.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function getProducts(){
        try {
            const {url, options} = GET_PRODUCTS(token, {page:1,perPage:null})
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {products} = await response.json() 
            setProducts(products.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async () =>{
        try {
            setWaiting(true)
            const body = setBody()
            const {url, options} = POST_CREATE_ORDER(token, body)
            
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            setUser(null)
            setMessage('Pedido adicionado!')
            setOpenSnack(true)
            const {orderCard} = await response.json()
            getOrderCard(orderCard.id)
            //setPdforder(path)
        } catch (error) {
            console.log("CLS error: ",error)
        }finally{
            setWaiting(false)
        }
    }

    const getOrderCard = async (id)=>{
        try {
            const {url, options} = GET_LAST_ORDERS(token, id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {order} = await response.json()
            setOrderToShow(order)
        } catch (error) {
            console.log(error)
        }
    }

     function setBody(){
        productlist.map( product=>{
            //itens.push({product_id:product.id,	quantity:product.quantity,observation:"",attributes:attributesArray})
            const idx = itens.findIndex((item)=>item.product_id === product.id)
            if(itens[idx].attributes){
                itens[idx].quantity = product.quantity
                itens[idx].observation=""
               
            }else{
                itens[idx].quantity = product.quantity
                itens[idx].observation=""
                itens[idx].attributes = []
                
            }
            setItens([...itens])
        })
        const body = {
            table_id:tableid,
            user_id:user.user?user.user.id:user.id,
            itens:itens
        }
    
        return body
    }

    const addProduct =(product)=> {    
        setProductlis([...productlist, product])
        setProductOn(product)
        itens.push({product_id:product.id})
        setItens([...itens])
        setAttributesArrays([])
    }

    const addQuantity = (event) =>{
        event.preventDefault()
        try {
            const idx = productlist.findIndex((product) => product.id == event.target.id)
            productlist[idx].quantity = event.target.value   
        } catch (error) {
            console.log(error)
        }
    }

    const addAttributes =(attributes)=>{
        setAttributes(null)
        addCount(attributes)
        setAttributes(attributes)
        setOpen(true)
    }

  const addComplement=(value,checked)=>{
    const idx = attributes.findIndex((attribute)=> attribute.id === value.attribute_id)
    //const idxI = itens.findIndex((item)=>item.product_id=== attributes[0].product_id)
    
    if(checked){
        const arrayVAl = []
        attributes[idx].count +=1
        value.checked = true
        const idxA = attributesArray.findIndex((attribute)=> attribute.attribute_id === value.attribute_id)
        if(idxA<=-1){
            arrayVAl.push({value_name:value.name, additional_value:value.additional_value, quantity:value.max_item})
            attributesArray.push({
                attribute_id:attributes[idx].id,
                quantity:arrayVAl.length,
                values:arrayVAl
            })
            setAttributesArrays([...attributesArray])
           
        }else{
            const idxV = attributesArray.findIndex((att)=>att.attribute_id === value.attribute_id)
            attributesArray[idxV].values.push({value_name:value.name, additional_value:value.additional_value, quantity:value.max_item})
            attributesArray[idxV].quantity = attributesArray[idxV].values.length
            setAttributesArrays([...attributesArray])
        }       
        setAttributes([...attributes])
    }else{
        attributes[idx].count -=1
        value.checked = false
        setAttributes([...attributes])
    }
    
  }

  const addValue =()=>{
        const idx = itens.findIndex((item)=>item.product_id === productOn.id)
        itens[idx].attributes = attributesArray
        setItens(itens)
  }

  const addCount=(attributes)=>{
    attributes.map(att=>{
        att.count = 0
        att.values.map(value=>{
            value.checked = false
        })
    })
}

const removeAttribute = (product_id)=>{
    const idx = productlist.findIndex((prod)=>prod.id === product_id)
    const idxI = itens.findIndex((pro)=>pro.product_id===product_id)
    if(idx>=0){
        productlist.splice(idx, 1)
    }if(idxI>=0){
        itens.splice(idxI,1)
    }
    setProductlis([...productlist])
    setItens([...itens])
    
}

    const addItemToData = event=>{
        //event.preventDefault()
        const idx = products.findIndex((dt)=>dt.name === event)
        if(products[idx]){
            addProduct(products[idx])
        }    
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    React.useEffect(()=>{
        getData()
    },[])
    return (
        <>
             { loadind && <div className="loading" ><CircularProgress size={125} /></div>}  
             { !loadind && 
                <div className="container">
                    <Dialog
                        open={open}
                        onClose={evt => {
                        console.log(evt.detail.action);
                        setOpen(false);
                        }}
                        onClosed={
                            evt =>setAttributes(null)
                            
                        }
                    >
                        <DialogTitle>Adicione os complementos</DialogTitle>
                        <DialogContent>
                            {
                               
                                attributes && attributes.map(attribute=>{
                                    return(
                                        <div>
                                            <div><strong>{attribute.title}</strong> <div className="requiredStyle">{attribute.required?"Obrigatório":"Opcional"}</div></div>
                                            <div className="descriptionStyle">{attribute.description}</div>
                                        {attribute.values && attribute.values.map(value=>{
                                            return(
                                                <div key={value.id} className="attValue">
                                                    <div className="nameValue"> {value.name} </div>
                                                    <div className="addValue">
                                                    <Checkbox label="" onChange={evt=>addComplement(value, evt.currentTarget.checked) } disabled={attribute.count === attribute.max_item && value.checked === false ?true:false}/>
                                                    </div>
                                                </div>
                                            )
                                        })}</div>
                                    )
                                })
                            }
                        </DialogContent>
                        <DialogActions>
                        <DialogButton action="close">Cancelar</DialogButton>
                        <DialogButton action="accept" isDefaultAction onClick={addValue}>
                            Adicionar
                        </DialogButton>
                        </DialogActions>
                    </Dialog>


                 { tableid && <div className={"PageTitle"}>        
                    <h1><Typography use="headline1">Adicionar Pedido na Mesa {table.number}</Typography></h1>             
                </div>}
                {
                    !tableid && <div className={""}>        
                   <Typography use="headline4">Selecione a Mesa</Typography>
                    <Grid>
                        <GridRow>
                            <GridCell span={12}>
                                <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label={"Selecione a mesa"} icon="filter_list" />}>
                                    {
                                        tables && tables.map(table=>{
                                            return(
                                                <MenuItem id={table.number} onClick={()=>addTable(table.id)}>Mesa {table.number}</MenuItem>
                                            )
                                        })
                                    }
                                </SimpleMenu> 
                            </GridCell>
                        </GridRow>
                    </Grid>   
                               
                </div>

                }
                <Grid className="gridContainer">
                    <GridRow>
                        <GridCell span={12}>
                            <GridRow>
                                <GridCell span={6}>
                                    {
                                        table.cards && <Datelist dataList={clients} setData={setUser} data={user}/>
                                    }    
                                </GridCell>
                                <GridCell span={6}>
                                    { products &&
                                        <div className="dataListProduct">
                                             <label for="products" className='dataListLabel'>Selecione o produto</label>
                                            <input list="products" name="products" id="browser" onChange={(evt)=>addItemToData(evt.currentTarget.value)}/>
                                            <datalist id="products">
                                            {
                                                    products && products.map(item=>{
                                                        return (
                                                            <option value={item.name} />
                                                        )
                                                    })
                                            }
                                            </datalist> 
                                        </div>
                                    }
                                </GridCell>
                            </GridRow>
                            <br/>
                            <GridRow>
                                <GridCell span={12}>
                                <Typography use="headline5">Produtos a serem incluídos</Typography>
                                </GridCell>
                            </GridRow>
                            <GridRow>
                                <GridCell span={6}>
                                <DataTable className={"TabelaProdutos"}>
                                    <DataTableContent>
                                        <DataTableHead>
                                            <DataTableRow>
                                                <DataTableHeadCell>Código</DataTableHeadCell>
                                                <DataTableHeadCell>Produto</DataTableHeadCell>
                                                <DataTableHeadCell>Quantidade</DataTableHeadCell>
                                                <DataTableHeadCell>Complementos</DataTableHeadCell>
                                                <DataTableHeadCell></DataTableHeadCell>
                                                
                                            </DataTableRow>
                                        </DataTableHead>
                                          <DataTableBody>
                                         
                                        { productlist.map(product=>{
                                            return(
                                                <DataTableRow key={product.id}>                                       
                                                    <DataTableCell>{product.id}</DataTableCell>
                                                    <DataTableCell>{product.name}</DataTableCell>
                                                        <DataTableCell> 
                                                            <TextField type="number" name="quatity" id={product.id} fullwidth placeholder="Quatidade"  min="1" onChange={addQuantity} required/>
                                                        </DataTableCell>
                                                      
                                                        <DataTableCell>{product.attributes.length > 0 &&<Button className="btComplement" onClick={()=>addAttributes(product.attributes)} label="Complemento"/>  }</DataTableCell>  
                                                            {/*product.attributes.length === 0 && <DataTableCell></DataTableCell>*/}
                                                            <DataTableCell> <Button className={"btnRemove"} label="Remover" onClick={()=>removeAttribute(product.id)} icon={{icon:'delete', size:'small'}}/></DataTableCell>
                                                    </DataTableRow>
                                            )
                                            })
                                        }
                                        </DataTableBody>
                                    </DataTableContent>
                                </DataTable>
                                </GridCell>
                            </GridRow>
                        </GridCell>
                    </GridRow>
                </Grid>
                <Grid>
                    <GridRow>
                        <GridCell span={8}>

                        </GridCell>
                        <GridCell span={4}>
                        { !waiting&& <Button outlined icon="add" onClick={handleSubmit} className={"BtnDefaultSearch"} type="button">Adicionar pedido</Button>}
                        { waiting && <Button><CircularProgress size={10} /></Button>}
                        </GridCell>
                    </GridRow>
                </Grid>
                { orderToShow &&
                    <div className="containerPrint">  
                        <div className="btnPrintOrder">
                            <Button label="Imprimir" onClick={handlePrint} icon="print"/>
                        </div>
                     
                        <div ref={componentRef}>
                        <PrintOrderCard data={orderToShow}/>
                        </div>
                    </div>
                    }
                <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
                 </div>}
            
        </>
    )
}

export default CreateOrder