import React from "react";
import {  Grid, GridCell, GridRow,
  Typography,
  Button,
  TextField,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  SimpleMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
  Select,
  Badge,
  IconButton,
  Icon,
  CircularProgress,
  Snackbar,
  SnackbarAction

} from "rmwc";
import MainNav from '../../MainNav'
import {GET_PROFILE, UPDATE_USER} from '../../Api'
import './Account.css'
import {convertDate} from '../../Utils'

const AccountIndex = () => {
  const [loading, setLoading] = React.useState(true)
  const [profile, setProfile] = React.useState({})
  const [name, setName] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [openSnack, setOpenSnack] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const token = window.localStorage.getItem('token')
  const getProfile = async ()=>{
    try {
      const {url, options} = GET_PROFILE(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {profile} = await response.json()
      setProfile(profile)
      setName(profile.user.name)
      setEmail(profile.user.email)
      setPhone(profile.user.phone)
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSubmit =async  event=>{
    event.preventDefault()
    try {
      const body = {
        name: name,
        email: email,
        phone: phone
      }
      
      const {url, options} = UPDATE_USER(token, profile.user.id, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      setMessage('Dados atualizados com sucesso')
      setOpenSnack(true)
      getProfile()
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(()=>{
    getProfile()
  },[])
  return (
    <>    
    <MainNav />   
    { loading && <div className="loading" ><CircularProgress size={125} /></div>} 
     { !loading && profile.user &&<div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Meu Perfil</Typography></h1>             
          </div>
          <div  className="panelContainer">
            <form onSubmit={handleSubmit}>
            <Grid>
              <GridRow>
                <GridCell span={12}>
              <GridRow>
                <GridCell span={6}> 
                  <Typography use="overline"><strong>Nome: </strong>
                  <input type="text" name="name" value={name} onChange={({target})=>setName(target.value)} className="inputCusston"/>
                  </Typography>
                </GridCell>
                <GridCell span={4}> 
                  <Typography use="overline"><strong>Código: {profile.id} </strong>
                  </Typography>
                </GridCell>
              </GridRow>
              <GridRow>
                  <GridCell span={6}>
                  <Typography use="overline"><strong>Email: </strong>
                  <input type="text" name="email" value={email} onChange={({target})=>setEmail(target.value)} className="inputCusston"/>
                  </Typography>
                  </GridCell>
                  <GridCell span={6}>
                  <Typography use="overline"><strong>Telefone: </strong>
                  <input type="text" name="phone" value={phone} onChange={({target})=>setPhone(target.value)} className="inputCusston"/>
                  </Typography>
                  </GridCell>
              </GridRow>
              <GridRow>
                  <GridCell span={6}>
                  <Typography use="overline"><strong>Data de cadastro: </strong>{convertDate(profile.user.created_at)}</Typography>
                  </GridCell>
                  <GridCell span={6}>
                  <Typography use="overline"><strong>Cargo: </strong>{profile.role}</Typography>
                  </GridCell>
              </GridRow>
              <br/>
              <GridRow>
                <GridCell span={6}>

                </GridCell>
                <GridCell span={6}>
                <Button type="submit" className={"BtnDefaultTmenu"} label="Atualizar dados" icon="update" />
                </GridCell>
              </GridRow>
              </GridCell>
              </GridRow>
            </Grid>
            </form> 
            </div>
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
        </div>}   
    </>
  );
};

export default AccountIndex;
