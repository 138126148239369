import React, {memo} from 'react'
import {delteAttributeValue} from './Services'
import { Typography,
    Grid,
    GridRow,
    GridCell,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,
    Button,
    Snackbar,
    SnackbarAction
    } from 'rmwc'
const Values =({values})=>{
    const [valuesList, setValuesList] = React.useState([])
    const [openSnack, setOpenSnack] = React.useState(false)
    const [valueToEclude, setValueToExclude] = React.useState(null)
    const [message, setMessage] = React.useState('')
    const token = window.localStorage.getItem('token')
    const showValues=(value)=>{
        setValuesList(value)
    }
    const confirmExclude =async ()=>{
        try {
            if(delteAttributeValue(token, valueToEclude.id)){
                const idx = valuesList.findIndex(val => val.id === valueToEclude.id)
                valuesList.splice(idx)
                setValuesList([...valuesList])
            }else{
                window.alert("Eror")
            }
        } catch (error) {
            console.log(error)
        }finally{
            setValueToExclude(null)
        }
    }

    const deleteValue =  (value)=>{
       try {
           setValueToExclude(value)
        setMessage("ATENÇAÕ! CONFIMAR  EXCLUISÃO?")
        setOpenSnack(true)
       } catch (error) {
            console.log(error)
       }
    }
    
    React.useEffect(()=>{
        showValues(values)
    },[values])

    return(
        <>
        {valuesList && valuesList.length<=0 && <p> Ainda não tem Valoes cadastrados</p>}
        {valuesList && valuesList.length >0 && <Grid>
        <GridRow>
            <GridCell>
            <Typography><h3>Valores</h3></Typography>
            </GridCell>
        </GridRow>
        <GridRow>
            <GridCell>
            <DataTable className={"TabelaProdutos"}>
                            <DataTableContent>
                                <DataTableHead>
                                    <DataTableRow>
                                        <DataTableHeadCell alignEnd>Nome</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Descrição</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Qtd. items</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Valor Adiconal</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Excluir</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd></DataTableHeadCell>
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableBody>
                                {valuesList  && valuesList.map(value => {
                                    return(
                                        <>
                                            <DataTableRow key={value.id} >
                                            <DataTableCell>{value.name}</DataTableCell>
                                            <DataTableCell>{value.description}</DataTableCell>
                                            <DataTableCell>{value.max_item}</DataTableCell>
                                            <DataTableCell>{value.aditional_value}</DataTableCell>
                                            <DataTableCell><Button icon="delete" className='btnDelete' onClick={()=>deleteValue(value)}/></DataTableCell>
                                            </DataTableRow>
                                        </>
                                    )
                                } )}
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>    
            </GridCell>
        </GridRow>
        </Grid>}
        <Snackbar
            open={openSnack}
            onClose={evt => setOpenSnack(false)}
            message={message}
            dismissesOnAction
            action={
            <SnackbarAction
                label="SIM"
                onClick={confirmExclude}
        />
                        
                        } 
                    />
        </>
    )
}

export default memo(Values)