import React from "react";
import { Grid, GridCell, GridRow,
  Typography,
  Button,
  TextField,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  SimpleMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
  Select,
  Badge,
  IconButton,
  Icon,
  CircularProgress,
  SimpleDialog
} from "rmwc";
import { GET_BILLINGS } from "../../Api";
import MainNav from "../../MainNav";
import Pagination from '../Pagination/Pagination'
import ExtractBilling from "./ExtractBilling";
import './Invoices.css';
import { UserContext } from '../../UserContext';
import {convertOnlyDate, isExpired} from '../../Utils'
const InvoicesIndex = () => {

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState([])
  const [paginate, setPaginate] = React.useState({total:0, perPage:5, page:1, lastpage:0})
  const { billingid, setBillingId } = React.useContext(UserContext);
  const [id, setId] = React.useState(0)
  const token = window.localStorage.getItem('token')
  const getData = async () =>{
    try {
      const{url, options} = GET_BILLINGS(token, paginate)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {billings} = await response.json()
      setData(billings.data)
      setPaginate({total:billings.total, perPage:billings.perPage, page:billings.page, lastpage:billings.lastPage})
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  const paginateUpdate =async event =>{
    try {
        paginate.page=event.target.id
        setPaginate(paginate)
        const {url, options} = GET_BILLINGS(token, paginate)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {billings} = await response.json()
        setData(billings.data)
        
    } catch (error) {
        console.log(error)
    }
  }
  const openModalBillingExtract = (id)=>{
    setId(id)
    setOpen(true)
  }

  React.useEffect(()=>{
    getData()
  },[])
  return (
    <>          
    <MainNav/>
    <Dialog
        open={open}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpen(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >

          { id >0 && <ExtractBilling id={id}/>}

        <DialogActions>
          <DialogButton action="accept" isDefaultAction>
            OK
          </DialogButton>
        </DialogActions>
      </Dialog>
    { loading && <div className="loading" ><CircularProgress size={125} /></div>}
    { !loading && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Minhas Faturas</Typography></h1>             
          </div>
            <Grid className={"CustomContainer"}>
            <GridRow>
              <GridCell>
                <DataTable className={"TabelaProdutos"}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>Referência</DataTableHeadCell>                        
                        {/* <DataTableHeadCell alignEnd>Comandas Fechadas</DataTableHeadCell> */}
                        <DataTableHeadCell alignEnd>Total a Pagar</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Vencimento</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Ações</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Status</DataTableHeadCell>                      
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      { data.map(billing=>{
                        return(
                          <DataTableRow>
                            {console.log(billing)}
                            <DataTableCell>{billing.description}</DataTableCell>
                            {/* <DataTableCell alignEnd>{billing.cards}</DataTableCell> */}
                            <DataTableCell alignEnd className={"strong"}>R$ {billing.value}</DataTableCell>
                            <DataTableCell>{
                              convertOnlyDate(billing.due_date)
                            }</DataTableCell>
                            <DataTableCell alignEnd>
                              <Button  icon="visibility" outlined  
                              onClick={()=> openModalBillingExtract(billing.id)} >Ver Extrato</Button>
                              <Button label="Pagar Fatura" icon="attach_money" id={billing.billing_link} onClick={()=>window.open(billing.billing_link,"_blank")} unelevated style={{background:"#4CAF50"}} disabled={billing.status==='PAGA' || billing.status==='CANCELADA'} />
                            </DataTableCell>
                            <DataTableCell alignEnd>                           
                                  { billing.status==='CANCELADA' && <Badge className={"TmenuDanger"} align="inline" label={billing.status} />}
                                  { billing.status==='PAGA' && <Badge className={isExpired(billing.due_date)?"TmenuDanger":"TmenuSuccess"} align="inline" label={isExpired(billing.due_date)?"VENCIDA":billing.status} />}
                                  { billing.status==='ENVIADA' && <Badge className={isExpired(billing.due_date)?"TmenuDanger":"TmenuInProcess"} align="inline" label={isExpired(billing.due_date)?"VENCIDA":billing.status} />}                                                                            
                            </DataTableCell>                        
                          </DataTableRow>
                        )
                      })}
                    
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
                </GridCell>
                </GridRow>
                <GridRow>
                  <Pagination paginate={paginate} paginateUpdate={paginateUpdate}/>
                </GridRow>
            </Grid>
        </div>   }
      
    </>
  );
};

export default InvoicesIndex;
