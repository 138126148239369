
import React from "react";
import { 
  Grid, Typography, GridCell, 
  GridRow,DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell, 
  TextField,
  Button,
  CircularProgress,
  Select,
  Snackbar,
  SnackbarAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  SimpleMenu,
  MenuItem
} from "rmwc";
import { GET_CATEGORIES, GET_PRINTERS, POST_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } from "../../Api";
import MainNav from "../../MainNav";
import Pagination from '../Pagination/Pagination'
const CategoryIndex =()=>{
    const [loading, setLoading] = React.useState(true)
    const [openSnack, setOpenSnack] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [categories, setCategories] = React.useState([])
    const [printers, setPrinters] = React.useState({id:'', name:''})
    const [printersArray, setPrintersArray] = React.useState([])
    const [printer, setPrinter] = React.useState({id:'', name:''})
    const [category, setCategory] = React.useState(null)
    const token = window.localStorage.getItem('token')
    const [openform, setOpenform] = React.useState(false)
    const [paginate, setPaginate] = React.useState({total:0, perPage:5, page:1, lastpage:0})
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openPrinterModal, SetOpenPrinterModal] = React.useState(false);
    const [name, setName] = React.useState('')
    const getCategories = async ()=>{
        try {
            const {url, options} = GET_CATEGORIES(token, paginate)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {categories} = await response.json()
            setCategories(categories.data)
            setPaginate({total:categories.total, perPage:categories.perPage, page:categories.page, lastpage:categories.lastPage})
            getPrinters()
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }


    const setData=(data)=>{
        const interData = {}
        data.map(obj => {
            interData[obj.id]=obj.name
        })
        return interData
    }

    const handleSubmit= async event=>{
        event.preventDefault()
       try {
        const {url, options} = POST_CATEGORY(token, {name: event.target.name.value, printer_id:printer})
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {category} = await response.json()
        setMessage(`Categoria "${category.name}" cadastrada com sucesso!`)
        setOpenSnack(true)
        getCategories()
        setOpenform(false)
       } catch (error) {
           console.log(error)
       }

    }
    const paginateUpdate =async event =>{
        try {
            paginate.page=event.target.id
            setPaginate(paginate)
            const {url, options} = GET_CATEGORIES(token, paginate)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {categories} = await response.json()
            setCategories(categories.data)
        } catch (error) {
            console.log(error)
        }
      }

      const getPrinters = async ()=>{
        try {
            const {url,options} = GET_PRINTERS(token)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(`Error: ${response.statusText}`)
            const {printers} = await response.json()
            setPrinters(setData(printers))
            setPrintersArray(printers)
          } catch (error) {
            console.log(error)
          }finally{
            setLoading(false)
          }
      }


      const deleteCategory = async (id)=>{
          try {
            const idx = categories.findIndex((category)=> category.id === id)
            setCategory(categories[idx])
            setOpenDelete(true)
          } catch (error) {
              console.log(error)
          }
      } 

      const toDelete = async (id)=>{
        try {
            const {url, options} = DELETE_CATEGORY(token, id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            setMessage(`Categoria excluída!`)
            setOpenSnack(true)
            getCategories()
        } catch (error) {
            console.log(error)
        }
      }

      const updateCategory=(id)=>{
        try {
          const idx = categories.findIndex((category)=> category.id === id)
          setCategory(categories[idx])
          setName(categories[idx].name)
          setOpenEdit(true)
        } catch (error) {
          console.log(error)
        }
      }

      const toUpdate = async event =>{
        event.preventDefault()
        try {
          const body = {
            name:event.target.name.value
          } 
          const {url, options} = UPDATE_CATEGORY(token, category.id, body)
          const response = await fetch(url, options)
          if(!response.ok) throw new Error(response.statusText)
          setMessage(`Categoria Atualizada!`)
          setOpenSnack(true)
          getCategories()
        } catch (error) {
          console.log(error)
        }
      }


      const openAddPrinter = async (id)=>{
        const idx = categories.findIndex((category)=> category.id === id)
        setCategory(categories[idx])
        SetOpenPrinterModal(true)
      }

      const updatePrinter = async event =>{
        event.preventDefault()
        try {
          const body = {
            printer_id: printer
          }
          const {url, options} = UPDATE_CATEGORY(token, category.id, body)
          const response = await fetch(url, options)
          if(!response.ok) throw new Error(response.statusText)
          const data = await response.json()
          setMessage(`Categoria ${data.category.name} atualizada com sucesso!`)
          setOpenSnack(true)
          getCategories()
        } catch (error) {
          console.log(error)
        }
      }
    React.useEffect(()=>{
        getCategories()
    },[])
    return(
        <>

<Dialog
        open={openPrinterModal}
        onClose={evt => {
          console.log(evt.detail.action);
          SetOpenPrinterModal(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Editar impressora de { category && category.name}</DialogTitle>
        <form onSubmit={updatePrinter}>
        <DialogContent>
         <Select label="Selecione uma impressora" 
            options={printers}
            onChange={(evt)=>setPrinter(evt.currentTarget.value)}
            required
          />
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept">
            Salvar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog>


<Dialog
        open={openEdit}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenEdit(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Editar {printer?printer.name:""}?</DialogTitle>
        <form onSubmit={toUpdate}>
        <DialogContent>
        <TextField type="text" name="name" fullwidth placeholder="Nome da categoria" value={name} onChange={({target})=>setName(target.value)}/>
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept">
            Salvar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog>

    <Dialog
        open={openDelete}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenDelete(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Excluir {category?category.name:""}?</DialogTitle>
        <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
        <DialogActions> 
          <DialogButton action="Denied">Cancelar</DialogButton>      
          <DialogButton action="accept" onClick={()=>toDelete(category.id)}>
            Excluir
          </DialogButton>
        </DialogActions>
      </Dialog>
            <MainNav/>  
            { loading && <div className="loading" ><CircularProgress size={125} /></div>} 
            { !loading && <div className={"PageContainer"}>
                <div className={"PageTitle"}>        
                    <h1><Typography use="headline1">Categorias</Typography></h1>             
                </div>
                <Button className={"BtnDefaultTmenu"} label={openform?"Fechar":"Cadastrar Categoria"} icon="add" onClick={()=>setOpenform(!openform)} />
                {
              openform && 
              <form lassName="formContainer" onSubmit={handleSubmit}>
              <Grid >
                
                <GridRow>
                    <GridCell span={4}>
                      <TextField type="text" name="name" fullwidth placeholder="Nome" />
                    </GridCell>
                    <GridCell span={3}>
                    <Select label="Selecione uma impressora" 
                            options={printers}
                            onChange={(evt)=>setPrinter(evt.currentTarget.value)}
                            required
                            />
                    </GridCell>
                    <GridCell span={3}>
                      <Button type="submit" className={"BtnDefaultTmenu"} label="Cadastrar" icon="add" /> 
                    </GridCell>
                </GridRow>
                
              </Grid>
              </form>
            }
                <Grid className={"CustomContainer"}>
                    <GridRow>
                    <GridCell>
                    <DataTable className={"TabelaProdutos"}>
                        <DataTableContent>
                            <DataTableHead>
                                <DataTableRow>
                                    <DataTableHeadCell>Código</DataTableHeadCell>
                                    <DataTableHeadCell>Nome</DataTableHeadCell>
                                    <DataTableHeadCell>Impressora</DataTableHeadCell>
                                    <DataTableHeadCell></DataTableHeadCell>
                                    <DataTableHeadCell></DataTableHeadCell>
                                </DataTableRow>
                            </DataTableHead>
                            <DataTableBody>
                                {
                                    categories.map(category=>{
                                        return(
                                            <DataTableRow key={category.id}>
                                                <DataTableCell>{category.id}</DataTableCell>
                                                <DataTableCell>{category.name}</DataTableCell>
                                                <DataTableCell ><Button onClick={()=>openAddPrinter(category.id)}>{category.printer?category.printer.name:"Sem impressora"}</Button></DataTableCell>
                                                <DataTableCell>
                                                    <Button onClick={()=>updateCategory(category.id)} className={"btnEdit"} icon="edit">
                                                    Editar
                                                    </Button>
                                                </DataTableCell>
                                                <DataTableCell >
                                                    <Button onClick={()=>deleteCategory(category.id)} className={"btnDelete"} icon="delete">Excluir</Button>
                                                </DataTableCell>
                                            </DataTableRow>
                                        )
                                    })
                                }
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable>
                    </GridCell>
                    </GridRow>
                    <GridRow>
                        <Pagination paginate={paginate} paginateUpdate={paginateUpdate}/>
                    </GridRow>
                
                </Grid>
                    <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
                </div>}
        </>
    )
}
export default CategoryIndex