import React, { useRef } from "react";
import { Grid, GridCell, GridRow,
  Typography,
  Button,
  TextField,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  SimpleMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
  Select,
  Badge,
  IconButton,
  Icon,
  CircularProgress,
  Snackbar,
  SnackbarAction,
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
  List,
  ListItem

} from "rmwc";
import MainNav from "../../MainNav";
import { convertDate, valorFormatado, addComman } from "../../Utils"
import { UserContext } from '../../UserContext';
import {GET_ORDERS, GET_TABLES, UPDATE_STATUS_ORDER, GET_ORDER, API_URL, GET_LAST_ORDERS} from '../../Api'
import Pagination from '../Pagination/Pagination'
import  Modal from '../Modal/Modal'
import CreateOrder from "./CreateOrder"
import {PrinterOrder} from '../Printer/PrinterOrder'
import {useReactToPrint} from 'react-to-print';
import './Order.css'
const OrdersIndex = () => {
  const [loadind, setLoading] = React.useState(true)
  const [data, setData] = React.useState([])
  const [paginate, setPaginate] = React.useState({total:0, perPage:20, page:1, lastpage:0})
  const [open, setOpen] = React.useState(false)
  const [openNewOrder, setOpenNewOrder] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [status, setStatus] = React.useState(false)
  const [tables, setTables] = React.useState([])
  const [openSnack, setOpenSnack] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [order, setOder] = React.useState(null)
  const [orderToShow, setOrderToShow] = React.useState(null)
  const { tableid, setTableId, setUpdateData } = React.useContext(UserContext);
  const token = window.localStorage.getItem('token')
  const componentRef = useRef()

  const getData = async ()=>{
    try {
      const {url, options} = GET_ORDERS(token, paginate)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {orders} = await response.json()
      setData(orders.data)
      setPaginate({total:orders.total, perPage:orders.perPage, page:orders.page, lastpage:orders.lastPage})
      getTables()
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }




  const paginateUpdate =async event =>{
    try {
        paginate.page=event.target.id
        setPaginate(paginate)
        const {url, options} = GET_ORDERS(token, paginate)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(`Error: ${response.statusText}`)
        const {orders} = await response.json()
        setData(orders.data)
    } catch (error) {
        console.log(error)
    }
  }

  const getTables = async ()=>{
    try {
      const {url, options} = GET_TABLES(token, {page:1, perPage:20})
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {tables} = await response.json()
      setTables(tables.data)
    } catch (error) {
      console.log(error)
    }
  }

  const searchToTable = async event =>{
    event.preventDefault()
    try {
      const search = `&table=${event.target.id}`
      const {url, options} = GET_ORDERS(token, {page:1, perPage:20}, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {orders} = await response.json()
      setData(orders.data)
      setPaginate({total:orders.total, perPage:orders.perPage, page:orders.page, lastpage:orders.lastPage})
    } catch (error) {
      console.log(error)
    }
  }

  const searchToStatus = async event =>{
    event.preventDefault()
    try {
      const search = `&status=${event.target.id}`
      const {url, options} = GET_ORDERS(token, {page:1, perPage:20}, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {orders} = await response.json()
      setData(orders.data)
      setPaginate({total:orders.total, perPage:orders.perPage, page:orders.page, lastpage:orders.lastPage})
    } catch (error) {
      console.log(error)
    }
  }
  const searchToCode = async event =>{
    event.preventDefault()
    try {
      const search = `&code=${event.target.code.value}`
      const {url, options} = GET_ORDERS(token, {page:1, perPage:20}, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {orders} = await response.json()
      setData(orders.data)
      setPaginate({total:orders.total, perPage:orders.perPage, page:orders.page, lastpage:orders.lastPage})
    } catch (error) {
      console.log(error)
    }
  }

  const updateStatus = async (id, status)=>{
    try {
      const body = {
        status:status
      }
      const {url, options} = UPDATE_STATUS_ORDER(token, id, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {item} = await response.json()
      setMessage(`O pedido número ${item.id} foi atualizado`)
      setOpenSnack(true)
      getData()
    } catch (error) {
      console.log(error)
    }
  }

  const openDetail = async (id) =>{
    try {
      const {url, options} = GET_ORDER(token, id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {item} = await response.json()
      setOder(item)
      setOpenModal(true)
    } catch (error) {
      console.log(error)
    }

  }
  const setNewOrder =()=>{
    setTableId(null)
    setOpenNewOrder(true)
    //setUpdateData(getData)
  }
  const getOrderCard = async (id)=>{
    try {
        const {url, options} = GET_ORDER(token, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {item} = await response.json()
        console.log("CLS",item)
        setOrderToShow(item)
        handlePrint()
    } catch (error) {
        console.log(error)
    }
}

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(()=>{
    getData()
  },[])
  return (
    <> 
    {/** Make the drawer appear right-to-left */}
    <div ref={componentRef} className="printOrderCard">
        <PrinterOrder data={orderToShow}/>
      </div>
    <Drawer
        dir="rtl"
        modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        {/** Set the content back to left-to-right */}
        <DrawerHeader dir="ltr">
          {console.log(order)}
          <DrawerTitle>Detalhes do Pedido {order && order.id} - <Typography use="headline6">"{order &&  order.product_name}"</Typography></DrawerTitle>
          <DrawerSubtitle></DrawerSubtitle>
        </DrawerHeader>

        <DrawerContent dir="ltr" className={"drawerOrder"}>
        <Typography use="headline6">{order &&  order.quantity} {order &&  order.product_name}</Typography>
        { order && <div>
            { order.attributes &&
              order.attributes.map(attribute=>{
                return(<>
                  {attribute.attribute_name}
                  {
                    attribute.values && attribute.values.map(value=>{
                      return(
                        <div>{value.name_value}</div>
                      )
                    }) 
                  }
                  </>
                )
              })
            }           
          </div>}
        <Typography use="headline6">Valor unitário: </Typography>
        <Typography use="headline6">{order && addComman(order.product_value)}</Typography><br/>
        <Typography use="headline6">Quantidade: </Typography>
        <Typography use="headline6">{order && order.quantity}</Typography><br/>
        <Typography use="headline6">Total: </Typography>
        <Typography use="headline6">{order && valorFormatado(order.value)}</Typography><br/>
        <Typography use="headline6">Status:</Typography>
        <Typography use="headline6">{order && order.status}</Typography><br/>
         
        </DrawerContent>
      </Drawer>
 
    <MainNav/>
    { loadind && <div className="loading" ><CircularProgress size={125} /></div>}
     { !loadind &&  <div className={"PageContainer"}>
     <Modal open={openNewOrder} setOpen={setOpenNewOrder} Content={CreateOrder}/>
        <div className={"PageTitle"}>        
          <h1><Typography use="headline1">Pedidos</Typography></h1>             
        </div>
         
          <Grid className={"CustomContainer"}>
            <GridRow>
                    <GridCell span={8}>
                      <Button className={"BtnDefaultTmenu"} label="Novo pedido" icon="add" onClick={setNewOrder} />
                      <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label="Filtrar por Mesa" icon="filter_list" />}>
                        {
                          tables.map(table =>{
                            return (
                              <MenuItem id={table.number} onClick={searchToTable}>Mesa {table.number}</MenuItem>
                            )
                          }) 
                        }
                        </SimpleMenu>
                      <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label="Filtrar por Status" icon="filter_list" />}>
                        <MenuItem id={"Finalizado"} onClick={searchToStatus} >Finalizado</MenuItem>
                        <MenuItem id={"Cancelado"} onClick={searchToStatus}>Cancelado</MenuItem>
                        <MenuItem id={"Em Andamento"} onClick={searchToStatus}>Em Andamento</MenuItem>
                      </SimpleMenu>                     
                    </GridCell>                    
                    <GridCell span={4}>    
                        <form onSubmit={searchToCode}>                  
                        <TextField className={"CustomInputSearch"} name="code" outlined label="Buscar por Nome ou Código..." />
                        <Button label="Pesquisar" outlined icon="search" className={"BtnDefaultSearch"}/>
                        </form>
                    </GridCell>
                </GridRow>       
            </Grid>

            <Grid className={"CustomContainer"}>
            <GridRow>
              <GridCell>
                <DataTable className={"TabelaProdutos"}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>Canal</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Iniciado em</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nº do Pedido</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Total</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Ações</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Status</DataTableHeadCell>
                        <DataTableHeadCell alignEnd></DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      { data.map(order=>{
                        return (
                          <DataTableRow key={order.id}>
                          <DataTableCell><strong>Mesa {order.table}</strong></DataTableCell>
                          <DataTableCell alignEnd>{convertDate(order.created_at)}</DataTableCell>
                          <DataTableCell alignEnd>{order.id}</DataTableCell>
                          <DataTableCell alignEnd className={"strong"}> {valorFormatado(order.value)}</DataTableCell>
                            <SimpleMenu handle={<IconButton icon="zoom_in"/>}>
                              <MenuItem onClick={()=>openDetail(order.id)}><Icon icon={{ icon: 'info', size: 'small' }} /> Ver Detalhes</MenuItem>                            
                              <MenuItem onClick={()=>getOrderCard(order.id)}><Icon icon={{ icon: 'print', size: 'small' }} /> Imprimir</MenuItem>
                            </SimpleMenu>
                          <DataTableCell alignEnd>                           
                                { order.status === 'Cancelado' && <Badge className={"TmenuDanger"} align="inline" label={order.status} />}
                                { order.status === 'Finalizado' && <Badge className={"TmenuSuccess"} align="inline" label={order.status} />}
                                { order.status === 'Em Andamento' && <Badge className={"TmenuInProcess"} align="inline" label={order.status} />}                                                                            
                          </DataTableCell>
                          <SimpleMenu handle={<IconButton icon="keyboard_arrow_down" label="Aterar status" />}>
                                  <MenuItem id="Finalizado" onClick={()=>updateStatus(order.id, "Finalizado")}>Atualizar para:  <strong className="TmenuSuccessText"> Finalizado</strong></MenuItem>
                                  <MenuItem id="Cancelado" onClick={()=>updateStatus(order.id, "Cancelado")}>Atualizar para:  <strong className="TmenuDangerText"> Cancelado</strong></MenuItem>
                                  <MenuItem id="Em Andamento" onClick={()=>updateStatus(order.id, "Em Andamento")}>  Atualizar para:  <strong className="TmenuInProcessText"> Em Andamento</strong></MenuItem>
                          </SimpleMenu>  
                        </DataTableRow>
                        )
                      })}                     
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
                </GridCell>
                </GridRow>
                <GridRow>
                  <Pagination paginate={paginate} paginateUpdate={paginateUpdate}/>
                </GridRow>
            </Grid>
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
        </div>   }
      
    </>
  );
};

export default OrdersIndex;
