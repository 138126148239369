import React from "react";
import { Grid, GridCell, Card, Typography, Icon, CircularProgress } from "rmwc";
import MainNav from "../MainNav";

import {GET_TABLES, GET_ORDERS, GET_CARDS} from  '../Api'

const Home = () => {
  const [loading, setLoading] = React.useState(true)
  const [tables, setTables]  = React.useState([])
  const [orders, setOrders]  = React.useState([])
  const [cards, setCards] = React.useState(null)
  const token = window.localStorage.getItem('token')

  const getTables = async ()=>{
    const {url, options} = GET_TABLES(token, {page:1, perPage:null})
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(response.statusText)
    const {tables} = await response.json()
    setTables(tables.data)
  }

  const getOrders = async ()=>{
    try {
      const {url, options} = GET_ORDERS(token, {page:1, perPage:null})
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(response.statusText)
    const {orders} = await response.json()
    setOrders(orders.data)      
    } catch (error) {
      console.log(error)
    }
  }

  const getCards = async ()=>{
    try {
      const {url, options} = GET_CARDS(token)
      const response = await fetch(url, options)
      if (!response.ok) throw new Error(response.statusText)
      const {cards} = await response.json()
      setCards(cards)
    } catch (error) {
      console.log(error)
    }
  }
  
  const getData = async ()=>{
    try {
      getTables()
      getOrders()
      getCards()
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  React.useEffect(()=>{
    getData()
  },[])
  return (
    <>      
    <MainNav/>     
    { loading && <div className="loading" ><CircularProgress size={125} /></div>} 
     { !loading && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">PAINEL</Typography></h1>             
          </div>
          <Grid className={"CardsHome"} z={1}>
              <GridCell>
                <Card>
                  <Icon icon={{ icon: 'view_comfy', size: 'xlarge' }} style={{ color: '#16A104'}} />
                  <span className={"CardsHomeTitle"}>TOTAL DE MESAS CADASTRADAS</span>
                  <span className={"CardsHomeValue"} style={{ color: '#16A104'}}>{tables.length}</span>
                </Card>
              </GridCell>
              <GridCell>
                <Card>
                  <Icon icon={{ icon: 'receipt_long', size: 'xlarge' }} style={{ color: '#169FFF'}} />
                  <span className={"CardsHomeTitle"}>TOTAL DE COMANDAS FATURADAS</span>
                  <span className={"CardsHomeValue"} style={{ color: '#169FFF'}}>{cards?cards:0}</span>
                </Card>
              </GridCell>
              <GridCell>
                <Card>
                <Icon icon={{ icon: 'shopping_cart', size: 'xlarge' }} style={{ color: '#FF144F'}} />
                  <span className={"CardsHomeTitle"}>TOTAL DE PEDIDOS REALIZADOS</span>
                  <span className={"CardsHomeValue"} style={{ color: '#FF144F'}}>{orders.length}</span>
                </Card>
              </GridCell>            
            </Grid>
        </div>   }
      
    </>
  );
};

export default Home;
