import React from 'react';
import { Grid, GridCell, GridRow
  } from "rmwc";
  import {valorFormatado, addComman, calcRates} from '../../Utils'
  import './Printer.css'

  export const PrinterCard = React.forwardRef(( {data}, props, ref)=>{
        const date = new Date()
        const mounht = `0${date.getMonth()+1}`.slice(-2)
        const day = `0${date.getUTCDate()}`.slice(-2)
        const year = date.getUTCFullYear()
        let item = 0
    return(
        <div  className="containerPrinter">
        {   
            data && 
            <Grid>
            <GridRow>
                <GridCell span={12}>
                <h3 className="title">{data && data.company.name}</h3>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={8}>
                <p>CNPJ: {data.company.cnpj}</p>
                </GridCell>
                <GridCell span={4}>
                <p>{`${day}/${mounht}/${year}`}</p>
                </GridCell>
                
            </GridRow>
            <GridRow>
                <GridCell span={12}>
                <p>{`${data.address.street} Nº ${data.address.number}`}</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>Cliente: {data.client.name}</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={8}>
                <p>Garçom: {data.waiter?data.waiter.name:''}</p>
                </GridCell>
                <GridCell span={4}>
                <p>Mesa: {data.table.number}</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={2}>
                <p>Item</p>
                </GridCell>
                <GridCell span={4}>
                <p>PRODUTO</p>
                </GridCell>
                <GridCell span={2}>
                <p>QTD.</p>
                </GridCell>
                <GridCell span={2}>
                <p>PREÇO</p>
                </GridCell>
                <GridCell span={2}>
                <p>VALOR</p>
                </GridCell>
            </GridRow>
            {   
                data.orders.map(order=>{
                    item += 1
                    return(
                        <GridRow key={order.id}>
                        <GridCell span={2}>
                        <p>{item}</p>
                        </GridCell>
                        <GridCell span={5}>
                        <p>{order.name.slice(0,15)}</p>
                        </GridCell>
                        <GridCell span={1}>
                        <p>{order.quantity}</p>
                        </GridCell>
                        <GridCell span={2}>
                        <p>{addComman(order.preco)}</p>
                        </GridCell>
                        <GridCell span={2}>
                        <p>{addComman(order.total)}</p>
                        </GridCell>
                    </GridRow>
                    )
                })
            }
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>OUTRAS DESPESAS</p>
                </GridCell>
            </GridRow>
            {
                data.rates && data.rates.map(rate=>{
                    return(
                        <GridRow key={rate.id}>
                            <GridCell span={8}>
                                <p>{rate.name}</p>
                            </GridCell>
                            <GridCell span={4}>
                                <p>{addComman((rate.value * rate.quantity))}</p>
                            </GridCell>
                        </GridRow>
                    )
                })
            }
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
            <GridCell span={4}>
               <p></p>
                </GridCell>
                <GridCell span={4}>
               <p>Total: </p>
                </GridCell>
                <GridCell span={4}>
               <p>{valorFormatado(calcRates(data.rates, data.card.value))}</p>
                </GridCell>
            </GridRow>
        </Grid>
        }
        </div>
        
    )
})

