import React from 'react'
import './Datelist.css'

const Datelist =({dataList, data, setData})=>{



    const addItemToData = async event=>{
        const idx = dataList.findIndex((dt)=>dt.name === event)
        setData(dataList[idx]) 
    }
    

    return(
        <div className='dataListProduct'>
        <label for="clients" className='dataListLabel'>Selecione o cliente</label>
        <input list="clients" name="clients" id="browser" onChange={(evt)=>addItemToData(evt.currentTarget.value)}/>

        <datalist id="clients">
        {
                dataList && dataList.map(item=>{
                    return (
                        <option value={item.name} />
                    )
                })
        }
        </datalist> 
        </div>

    )
}

export default Datelist

 