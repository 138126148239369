import React from "react";
import { Grid, GridCell, GridRow,
  Typography,
  Button,
  TextField,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  SimpleMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
  Select,
  Badge,
  IconButton,
  Icon,
  CircularProgress,
  Snackbar,
  SnackbarAction,
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
  List,
  ListItem

} from "rmwc";
import MainNav from "../../MainNav";
import {GET_ORDERS, GET_TABLES, UPDATE_STATUS_ORDER, GET_ORDER} from '../../Api'
import Pagination from '../Pagination/Pagination'

const OrdersIndex = () => {
  const [loadind, setLoading] = React.useState(true)
  const [data, setData] = React.useState([])
  const [paginate, setPaginate] = React.useState({total:0, perPage:20, page:1, lastpage:0})
  const [open, setOpen] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [status, setStatus] = React.useState(false)
  const [tables, setTables] = React.useState([])
  const [openSnack, setOpenSnack] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [order, setOder] = React.useState(null)
  const token = window.localStorage.getItem('token')
  const getData = async ()=>{
    try {
      const {url, options} = GET_ORDERS(token, paginate)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {orders} = await response.json()
      console.log(orders)
      setData(orders.data)
      setPaginate({total:orders.total, perPage:orders.perPage, page:orders.page, lastpage:orders.lastPage})
      getTables()
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  const paginateUpdate =async event =>{
    try {
        paginate.page=event.target.id
        setPaginate(paginate)
        const {url, options} = GET_ORDERS(token, paginate)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(`Error: ${response.statusText}`)
        const {orders} = await response.json()
        setData(orders.data)
    } catch (error) {
        console.log(error)
    }
  }

  const getTables = async ()=>{
    try {
      const {url, options} = GET_TABLES(token, {page:1, perPage:null})
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {tables} = await response.json()
      setTables(tables.data)
    } catch (error) {
      console.log(error)
    }
  }

  const searchToTable = async event =>{
    event.preventDefault()
    try {
      const search = `&table=${event.target.id}`
      const {url, options} = GET_ORDERS(token, paginate, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {orders} = await response.json()
      setData(orders.data)
    } catch (error) {
      console.log(error)
    }
  }

  const searchToStatus = async event =>{
    event.preventDefault()
    try {
      const search = `&status=${event.target.id}`
      const {url, options} = GET_ORDERS(token, paginate, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {orders} = await response.json()
      setData(orders.data)
    } catch (error) {
      console.log(error)
    }
  }
  const searchToCode = async event =>{
    event.preventDefault()
    try {
      const search = `&code=${event.target.code.value}`
      const {url, options} = GET_ORDERS(token, paginate, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {orders} = await response.json()
      setData(orders.data)
    } catch (error) {
      console.log(error)
    }
  }

  const updateStatus = async (id, status)=>{
    try {
      console.log(id)
      const body = {
        status:status
      }
      const {url, options} = UPDATE_STATUS_ORDER(token, id, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {item} = await response.json()
      setMessage(`O pedido número ${item.id} foi atualizado`)
      setOpenSnack(true)
      getData()
    } catch (error) {
      console.log(error)
    }
  }

  const openDetail = async (id) =>{
    try {
      const {url, options} = GET_ORDER(token, id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {item} = await response.json()
      setOder(item)
      console.log('ITEM DE GET',item)
      setOpenModal(true)
    } catch (error) {
      console.log(error)
    }

  }

  React.useEffect(()=>{
    getData()
  },[])
  return (
    <> 
    {/** Make the drawer appear right-to-left */}
    <Drawer
        dir="rtl"
        modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        {/** Set the content back to left-to-right */}
        <DrawerHeader dir="ltr">
          <DrawerTitle>Detalhes do Pedido {order && order.id}</DrawerTitle>
          <DrawerSubtitle><Typography use="headline5">{order &&  order.product_name}</Typography></DrawerSubtitle>
        </DrawerHeader>

        <DrawerContent dir="ltr">
        <Typography use="headline6">Valor unitário: </Typography>
        <Typography use="headline6">{order && order.product_value}</Typography><br/>
        <Typography use="headline6">Quantidade: </Typography>
        <Typography use="headline6">{order && order.quantity}</Typography><br/>
        <Typography use="headline6">Total: </Typography>
        <Typography use="headline6">{order && order.value}</Typography><br/>
        <Typography use="headline6">Status:</Typography>
        <Typography use="headline6">{order && order.status}</Typography><br/>
          { order && <List>
            { order.attributes &&
              order.attributes.map(attribute=>{
                return(
                  <ListItem>Cookies</ListItem>
                )
              })
            }           
          </List>}
        </DrawerContent>
      </Drawer>
 
    <MainNav/>
    { loadind && <div className="loading" ><CircularProgress size={125} /></div>}
     { !loadind &&  <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Pedidos</Typography></h1>             
          </div>
         
        <Dialog open={open} onClose={evt => {
            console.log(evt.detail.action);
            setOpen(false);
          }}
        onClosed={evt => console.log(evt.detail.action)}>
        <DialogTitle>Novo pedido</DialogTitle>
        <DialogContent>
           <TextField fullwidth placeholder="Pesquisar item" />
           <Select label="Selecione a mesa" options={['Mesa 1', 'Mesa 5', 'Mesa 10']}/>
        </DialogContent>
        <DialogActions>
          <DialogButton className={"BtnSecondaryAction"} action="close">Cancelar</DialogButton>
          <DialogButton className={"BtnDefaultTmenu"} action="accept" isDefaultAction>
            Enviar!
          </DialogButton>
        </DialogActions>
      </Dialog>
          <Grid className={"CustomContainer"}>
            <GridRow>
                    <GridCell span={8}>
                      <Button className={"BtnDefaultTmenu"} label="Novo pedido" icon="add" />
                      <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label="Filtrar por Mesa" icon="filter_list" />}>
                        {
                          tables.map(table =>{
                            return (
                              <MenuItem id={table.number} onClick={searchToTable}>Mesa {table.number}</MenuItem>
                            )
                          }) 
                        }
                        </SimpleMenu>
                      <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label="Filtrar por Status" icon="filter_list" />}>
                        <MenuItem id={"Finalizado"} onClick={searchToStatus} >Finalizado</MenuItem>
                        <MenuItem id={"Cancelado"} onClick={searchToStatus}>Cancelado</MenuItem>
                        <MenuItem id={"Em Andamento"} onClick={searchToStatus}>Em Andamento</MenuItem>
                      </SimpleMenu>                     
                    </GridCell>                    
                    <GridCell span={4}>    
                        <form onSubmit={searchToCode}>                  
                        <TextField className={"CustomInputSearch"} name="code" outlined label="Buscar por Nome ou Código..." />
                        <Button label="Pesquisar" outlined icon="search" className={"BtnDefaultSearch"}/>
                        </form>
                    </GridCell>
                </GridRow>       
            </Grid>

            {console.log(data)}
            <Grid className={"CustomContainer"}>
            <GridRow>
              <GridCell>
                <DataTable className={"TabelaProdutos"}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>Canal</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Iniciado em</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nº do Pedido</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Total</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Ações</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Status</DataTableHeadCell>
                        <DataTableHeadCell alignEnd></DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      { data.map(order=>{
                        return (
                          <DataTableRow key={order.id}>
                          <DataTableCell><a href="">Mesa {order.table}</a></DataTableCell>
                          <DataTableCell alignEnd>{order.created_at}</DataTableCell>
                          <DataTableCell alignEnd>{order.id}</DataTableCell>
                          <DataTableCell alignEnd className={"strong"}>R$ {order.value}</DataTableCell>
                            <SimpleMenu handle={<IconButton icon="zoom_in"/>}>
                              <MenuItem onClick={()=>openDetail(order.id)}><Icon icon={{ icon: 'info', size: 'small' }} /> Ver Detalhes</MenuItem>                            
                              <MenuItem><Icon icon={{ icon: 'print', size: 'small' }} /> Imprimir</MenuItem>
                            </SimpleMenu>
                          <DataTableCell alignEnd>                           
                                { order.status === 'Cancelado' && <Badge className={"TmenuDanger"} align="inline" label={order.status} />}
                                { order.status === 'Finalizado' && <Badge className={"TmenuSuccess"} align="inline" label={order.status} />}
                                { order.status === 'Em Andamento' && <Badge className={"TmenuInProcess"} align="inline" label={order.status} />}                                                                            
                          </DataTableCell>
                          <SimpleMenu handle={<IconButton icon="keyboard_arrow_down" label="Aterar status" />}>
                                  <MenuItem id="Finalizado" onClick={()=>updateStatus(order.id, "Finalizado")}>Atualizar para:  <strong className="TmenuSuccessText"> Finalizado</strong></MenuItem>
                                  <MenuItem id="Cancelado" onClick={()=>updateStatus(order.id, "Cancelado")}>Atualizar para:  <strong className="TmenuDangerText"> Cancelado</strong></MenuItem>
                                  <MenuItem id="Em Andamento" onClick={()=>updateStatus(order.id, "Em Andamento")}>  Atualizar para:  <strong className="TmenuInProcessText"> Em Andamento</strong></MenuItem>
                          </SimpleMenu>  
                        </DataTableRow>
                        )
                      })}                     
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
                </GridCell>
                </GridRow>
                <GridRow>
                  <Pagination paginate={paginate} paginateUpdate={paginateUpdate}/>
                </GridRow>
            </Grid>
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="Dismiss"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
        </div>   }
      
    </>
  );
};

export default OrdersIndex;
