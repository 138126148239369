import React from 'react'
import MainNav from '../../MainNav'
import Pagination from '../Pagination/Pagination'
import Values from './Values'
import { Typography,
    Grid,
    GridRow,
    GridCell,
    Button,
    TextField,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton,
    Switch,
    Snackbar,
    SnackbarAction } from 'rmwc'

import { GET_ATTRIBUTES, POST_ATTRIBUTE, DELETE_ATTRIBUTE, PUT_ATTRIBUTE } from '../../Api'
import { getAttribute } from './Services'
import useForm from '../../Hooks/UseForm'
const ComplementIndex=()=>{
    const [attributeToAction, setAttributeToAction]= React.useState(null)
    const [openAddAttribute, setOpenAddAttribute] = React.useState(false)
    const [showAttributeValues, setShowAttributeValues] = React.useState(false)
    const [openDeleteAttribute, setOpenDeleteAttribute] = React.useState(false)
    const [openEditAttribute, setOpenEditAttribute] = React.useState(false)
    const [attributes, setAttributes] = React.useState(null) 
    const [attributeValues, setAttributeValues] = React.useState(null) 
    const [openSnack, setOpenSnack] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [loadind, setLoadind] = React.useState(true)
    const [disableBtnSave, setDisablebtnSave] = React.useState(true)
    const [checkedRequiredAttribute, setCheckedRequiredAttribute] = React.useState(false) 
    const [paginate, setPaginate] = React.useState({total:0, perPage:10, page:1, lastpage:0})
    const search = useForm()
    const addAttributeTitle = useForm()
    const addAttributeDescription = useForm()
    const addAttributeMaxItem = useForm()
    const token = window.localStorage.getItem('token')



    const enableBtnSave=()=>{
        if(addAttributeTitle.value && addAttributeDescription.value &&  addAttributeMaxItem.value){
            if( disableBtnSave){
                setDisablebtnSave(false)
            }
        }
    }
    enableBtnSave()
    const getAttributes = async ()=>{
        try {       
            const {url, options} = GET_ATTRIBUTES(token, paginate, search.value)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {attributes} = await response.json()
            setAttributes(attributes.data)
            setPaginate({total:attributes.total, perPage:attributes.perPage, page:attributes.page, lastpage:attributes.lastPage})
        } catch (error) {
            console.log(error)
        }finally{
            setLoadind(false)
        }
    }

    const paginateUpdate = async event =>{
        try {
            paginate.page=event.target.id
            setPaginate(paginate)
            const {url, options} = GET_ATTRIBUTES(token, paginate, search.value)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {attributes} = await response.json()
            setAttributes(attributes.data)
            
        } catch (error) {
            console.log(error)
        }
    }
    const setBody =()=>{
        const body = {
            title:addAttributeTitle.value,
            description:addAttributeDescription.value,
            max_item: addAttributeMaxItem.value,
            required:checkedRequiredAttribute
        }
        return body
    }
    const addComplement = async ()=>{
        try {
            const body = setBody()
            const {url, options} = POST_ATTRIBUTE(token, body)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {attribute} = await response.json()
            setMessage(`Complemento ${attribute.title} cadastrado com sucesso!`)
            setOpenSnack(true)
            addAttributeTitle.setValue('')
            addAttributeDescription.setValue('')
            addAttributeMaxItem.setValue('')
            setCheckedRequiredAttribute(false)
            setDisablebtnSave(true) 
            getAttributes()           
        } catch (error) {
            console.log(error)
        }
    }
    const toDelete= async (attribute)=>{
        try {
            const {url, options} = DELETE_ATTRIBUTE(token,attribute.id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            setMessage(`O complemento ${attribute.title} foi excluído!`)
            setOpenSnack(true)
            getAttributes() 
        } catch (error) {
            console.log(error)
        }
    }
    const addAttributeToAction=(attribute, action)=>{
        setAttributeToAction(attribute)
        if(action === 'delete'){
            setOpenDeleteAttribute(true)
        }else{
            addAttributeTitle.setValue(attribute.title)
            addAttributeDescription.setValue(attribute.description)
            addAttributeMaxItem.setValue(attribute.max_item)
            setCheckedRequiredAttribute(attribute.required)
            setOpenEditAttribute(true)
        }
        
    }

    const toEdit = async (attribute)=>{
        try {
            const body = setBody()
            const {url, options} = PUT_ATTRIBUTE(token, attribute.id, body)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            setMessage(`O complemento ${attribute.title} foi autalizado!`)
            setOpenSnack(true)
            getAttributes()

        } catch (error) {
            console.error(error)
        } 
    }


    const openModalValues = (values)=>{
        setAttributeValues(values)
        setShowAttributeValues(true)
    }

    React.useEffect(()=>{
        getAttributes()
    },[])

    return(
        <>  
        <Dialog
                open={showAttributeValues}
                onClose={evt => {
                console.log(evt.detail.action);
                setShowAttributeValues(false);
                setAttributeValues(null)
                }}
                onClosed={evt => console.log(evt.detail.action)}
            >
                <Values values={attributeValues}/>

            </Dialog>


            <Dialog
                open={openEditAttribute}
                onClose={evt => {
                console.log(evt.detail.action);
                setOpenEditAttribute(false);
                }}
                onClosed={evt => console.log(evt.detail.action)}
            >
                <DialogTitle>Editar {attributeToAction?attributeToAction.title:""}?</DialogTitle>
                <DialogContent>
                    <TextField className={"CustomInputSearch"}  outlined label="Nome do complemento" {...addAttributeTitle}/>
                    <TextField className={"CustomInputSearch"}  outlined label="Descrição" {...addAttributeDescription}/>
                    <TextField className={"CustomInputSearch"} type="number" outlined label="Número máximo de itens" {...addAttributeMaxItem}/>
                    <Switch
                    checked={checkedRequiredAttribute}
                    onChange={(evt) => setCheckedRequiredAttribute(!!evt.currentTarget.checked)}
                    label="Complemento obrigatório?"
                    />
                </DialogContent>
                <DialogActions> 
                <DialogButton action="Denied" onClick={()=>setAttributeToAction(null)}>Cancelar</DialogButton>      
                <DialogButton action="accept" onClick={()=>toEdit(attributeToAction)} icon="save" className={"btnSave"}>
                    Salvar
                </DialogButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteAttribute}
                onClose={evt => {
                console.log(evt.detail.action);
                setOpenDeleteAttribute(false);
                }}
                onClosed={evt => console.log(evt.detail.action)}
            >
                <DialogTitle>Excluir {attributeToAction?attributeToAction.title:""}?</DialogTitle>
                <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
                <DialogActions> 
                <DialogButton action="Denied" onClick={()=>setAttributeToAction(null)}>Cancelar</DialogButton>      
                <DialogButton action="accept" onClick={()=>toDelete(attributeToAction)} icon="delete" className={"btnDelete"}>
                    Excluir
                </DialogButton>
                </DialogActions>
            </Dialog>
            
            <Dialog
                open={openAddAttribute}
                onClose={evt => {
                console.log(evt.detail.action);
                setOpenAddAttribute(false);
                }}
                onClosed={evt => console.log(evt.detail.action)}
            >
                <DialogTitle>Cadastrar Complemento</DialogTitle>
                <DialogContent >
                <TextField className={"CustomInputSearch"}  outlined label="Nome do complemento" {...addAttributeTitle}/>
                <TextField className={"CustomInputSearch"}  outlined label="Descrição" {...addAttributeDescription}/>
                <TextField className={"CustomInputSearch"} type="number" outlined label="Número máximo de itens" {...addAttributeMaxItem}/>
                <Switch
                checked={checkedRequiredAttribute}
                onChange={(evt) => setCheckedRequiredAttribute(!!evt.currentTarget.checked)}
                label="Complemento obrigatório?"
                />
                </DialogContent>
                <DialogActions> 
                <DialogButton action="Denied">Cancelar</DialogButton>      
                <DialogButton action="accept" onClick={addComplement} icon="save" className={"btnSave"} disabled={disableBtnSave}>
                    Cadastrar
                </DialogButton>
                </DialogActions>
            </Dialog>

            <MainNav />
            { loadind && <div className="loading" ><CircularProgress size={125} /></div>}
            {!loadind && <div className={"PageContainer"}>
                <div className={"PageTitle"}>        
                    <h1><Typography use="headline1">Complementos</Typography></h1>             
                </div>
                <Grid className={"CustomContainer"}>
                    <GridRow>
                        <GridCell span={12}>                    
                            <TextField  outlined label="Buscar complemento" {...search}/>
                            <Button label="Pesquisar" outlined icon="search" className={"BtnDefaultSearch"} onClick={getAttributes}/>
                            <Button label="Novo Complemento" icon="add" className={"BtnDefaultSearch"} onClick={()=>setOpenAddAttribute(true)}/>
                        </GridCell>                       
                    </GridRow>
                </Grid>
                <Grid className={"CustomContainer"}>
                    <GridRow>
                        <GridCell>
                        <DataTable className={"TabelaProdutos"}>
                            <DataTableContent>
                                <DataTableHead>
                                    <DataTableRow>
                                        <DataTableHeadCell>Cód.</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Nome</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Qtd. items</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Descrição</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Obrigatório</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd>Valores</DataTableHeadCell>
                                        <DataTableHeadCell alignEnd></DataTableHeadCell>
                                        <DataTableHeadCell alignEnd></DataTableHeadCell>
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableBody>
                                    {
                                        attributes && attributes.map(attribute=>{
                                            return(
                                                <DataTableRow key={attribute.id} >
                                                    <DataTableCell>{attribute.id}</DataTableCell>
                                                    <DataTableCell>{attribute.title}</DataTableCell>
                                                    <DataTableCell>{attribute.max_item}</DataTableCell>
                                                    <DataTableCell>{attribute.description}</DataTableCell>
                                                    <DataTableCell>{attribute.required?"Sim":"Não"}</DataTableCell>
                                                    <DataTableCell> 
                                                    <Button label="Valores" onClick={()=>openModalValues(attribute.values)}/>
                                                    </DataTableCell>
                                                    <DataTableCell><Button label="Editar" className={"btnEdit"} icon="edit" onClick={()=>addAttributeToAction(attribute, 'edit')}/></DataTableCell>
                                                    <DataTableCell><Button label="Excluir" className={"btnDelete"} icon="delete" onClick={()=>addAttributeToAction(attribute, 'delete')}/></DataTableCell>
                                                </DataTableRow>
                                            )
                                        })
                                    }
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                        </GridCell>
                    </GridRow> 
                    <GridRow>
                  <Pagination paginate={paginate} paginateUpdate={paginateUpdate}/>
                </GridRow>   
                </Grid>
            </div>}
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
        </>
    )
}

export default ComplementIndex