export function objectToSelect(data){
    const interData = {}
    data.map(obj => {
        interData[obj.id]=obj.name
    })
    return interData
}


export function serialJson(key, val){
    let seen = []
    if (typeof val == "object") {
      if (seen.indexOf(val) >= 0) {
          return
      }
      seen.push(val);
  }
  return val;
  }

export function convertDate(newDate){
    if(newDate){
        const day = newDate.slice(8,10)
        const month = newDate.slice(5,7)
        const year =newDate.slice(0,4)
        const hour = newDate.slice(11,13)
        const minute = newDate.slice(14,16)
        return `${day}/${month}/${year} ${hour}:${minute}`
    }
    return '00/00/0000'
}

export function convertOnlyDate(newDate){
    if(newDate){
        console.log("NEW DATA",newDate)
        const day = newDate.slice(8,10)
        const month = newDate.slice(5,7)
        const year =newDate.slice(0,4)
    return `${day}/${month}/${year}`
    }
    return '00/00/0000'
}

export function isExpired(newDate){
    if(newDate){
        const dateNow = new Date()
        const year = dateNow.getUTCFullYear()
        const month = `0${dateNow.getUTCMonth()+1.}`.slice(-2)
        const day = dateNow.getUTCDate()

        const dayComp = newDate.slice(8,10)
        const monthComp = newDate.slice(5,7)
        const yearComp = newDate.slice(0,4)
        if(year>yearComp)
            return true
        if(month>monthComp)
            return true
        if(month === monthComp && day>dayComp)
            return true
        return false
    }
    return false
}

function randoRgb(){
    return Math.floor(Math.random() * 255 ) + 1
}

export function setBackgroundColor(dataLength){
    const backround = []
    for(let i = 0; i<=dataLength; i++){
        backround.push(`rgba(${randoRgb()}, ${randoRgb()},${randoRgb()}, 0.6)`)
    }
    return backround
}

export function setMonth(newDate){
    const months = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO']
    const month = newDate.slice(5,7)
    return months[parseInt(month)-1]
}

export function setMonthYear(newDate){
    const year = newDate.slice(0,4)
    const months = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO']
    const month = newDate.slice(5,7)
    return `${months[parseInt(month)-1]}/${year}`
}

export function valorFormatado(valor){
    var formatado = valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    return formatado;
    }

    export function addComman(valor){
        //var formatado = valor.toLocaleString('pt-br',{style: 'currency', currency: ''});
        return valor.toFixed(2).replace(".", ",");
        }   

export function calcRates(listRate, value){
    if(Array.isArray(listRate)){
        listRate.map(rate=>{
            value += (rate.value*rate.quantity)
        })
        return value
    }
    return 0
}