import React from "react";
import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import  ProtectedRoute  from './Helper/ProtectedRoute'
import RedirectToHome from './Helper/RedirectToHome'
import Home from "./Components/Home";
import MenuIndex from "./Components/Menu/MenuIndex";
import CreateItemMenu from "./Components/Menu/CreateItemMenu";
import AccountIndex from "./Components/Account/AccountIndex";
import WaiterAccountIndex from "./Components/WaiterAccount/WaiterAccountIndex";
import CustomersIndex from "./Components/Customers/CustomersIndex";
import InvoicesIndex from "./Components/Invoices/InvoicesIndex";
import OrdersIndex from "./Components/Orders/OrdersIndex";
import WaiterOrdersIndex from "./Components/WaiterOrders/WaiterOrdersIndex";
import TablesIndex from "./Components/Tables/TablesIndex";
import WaiterTableIndex from "./Components/WaiterTables/WaiterTablesIndex";
import UsersIndex from "./Components/Users/UsersIndex";
import ReportsIndex from "./Components/Reports/ReportsIndex";
import SettingsIndex from "./Components/Settings/SettingsIndex";
import PrintersIndex from "./Components/Printers/PrintersIndex";
import CategoryIndex from "./Components/Category/CategoryIndex";
import Login from "./Components/Auth/Login"
import Logout from "./Components/Logout/Logout"
import { UserStorage } from './UserContext';
import Product from "./Components/Menu/Product";
import PrinterIndex from "./Components/Printer/PrinterIndex";
import ComplementIndex from "./Components/Complements/ComplementIndex";
const App = () => {
  return (
    <>          
    <BrowserRouter>  
      <div className={"MainContainer"}>
          <UserStorage>
            <Routes>
              <RedirectToHome path="/login" element={<Login />} />
              <ProtectedRoute path="/" element={<Home />} end />
              <ProtectedRoute path="/cardapio" element={<MenuIndex />} />
              <ProtectedRoute path="/complementos" element={<ComplementIndex />} />
              <ProtectedRoute path="/pedidos" element={<OrdersIndex />} />
              <ProtectedRoute path="/mesas" element={<TablesIndex />} />
              <ProtectedRoute path="/clientes" element={<CustomersIndex />} />
              <ProtectedRoute path="/minhas-faturas" element={<InvoicesIndex />} />
              <ProtectedRoute path="/usuarios" element={<UsersIndex />} />
              <ProtectedRoute path="/relatorios" element={<ReportsIndex />} />          
              <ProtectedRoute path="/configuracoes" element={<SettingsIndex />} />
              <ProtectedRoute path="/impressoras" element={<PrintersIndex />} />
              <ProtectedRoute path="/categorias" element={<CategoryIndex />} />
              <ProtectedRoute path="/meu-perfil" element={<AccountIndex />} />
              <ProtectedRoute path="/logout" element={<Logout />} />
              <ProtectedRoute path="/add-product" element={<CreateItemMenu/>} />
              <ProtectedRoute path="/product/:id" element={<Product/>}/>
              <ProtectedRoute path="/garcom-mesas" element={<WaiterTableIndex />} />
              <ProtectedRoute path="/garcom-pedidos" element={<WaiterOrdersIndex />} />
              <ProtectedRoute path="/garcom-perfil" element={<WaiterAccountIndex />} />
              <ProtectedRoute path="/printer/:slug" element={<PrinterIndex />} />
            </Routes>
          </UserStorage>
      </div>    
      </BrowserRouter>  
    </>
  );
};

export default App;
