import React from "react";
import { Grid, GridCell, GridRow, Typography,
  TabBar, Tab, GridTilePrimaryContent, LinearProgress, TextField, Button,
  Snackbar,
  SnackbarAction } from "rmwc";
import MainNav from "../../MainNav";
import { Bar, Line } from 'react-chartjs-2';
import  { CARDS_TO_MONTH, PRODUCTS_TO_MONTH, GET_REPORT_TABLE,
  GET_REPORT_CARDS, GET_CARDS_BY_DAY, GET_RATES } from '../../Api'
import {setBackgroundColor, setMonth, setMonthYear, valorFormatado, convertOnlyDate} from '../../Utils'
import useForm from '../../Hooks/UseForm'
import './Reports.css'


const ReportsIndex = () => {
  const [loading, setLoading] = React.useState(true)
  const [tabWaiter, setTabWaiter] = React.useState(true)
  const [tabTable, setTabTable] = React.useState(false)
  const [tabClient, setTabClient] = React.useState(false)
  const [tabCard, setTabCard] = React.useState(false)
  const [tabProduct, setTabProduct] = React.useState(false)
  const [openSnack, setOpenSnack] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [tables, setTables] = React.useState(null)
  const [tablesData, setTablesData] = React.useState(null)
  const [cards, setCards] = React.useState([])
  const [cardsByDay, setCardsByDay] = React.useState([])
  const [products, setProducts] = React.useState([])
  const [dataCard, setDataCard] = React.useState([])
  const [maxTicket, setMaxTicket] = React.useState([])
  const [canceled, setCanceled] = React.useState([])
  const [dataProduct, setDataProduct] = React.useState([])
  const [rates, setRates] = React.useState(null)
  const start = useForm()
  const end = useForm()
  const token = window.localStorage.getItem('token')
  const getData = async () =>{
    
    getProducts()
  }
  const getCards = async ()=>{
    try {
      const {url, options} = CARDS_TO_MONTH(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {cards} = await response.json()
      const labels = []
      const comandas = []
      cards.map(card=>{
        labels.push(setMonth(card.mes))
        comandas.push(card.comandas)
      })
      const data = {
        labels:labels,
        datasets:[
          {
            label: 'Comandas',
            data:comandas,
            backgroundColor: setBackgroundColor(cards.length)
          }
        ]
        }
        //setCards(data)
        setDataCard(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getProducts = async ()=>{
    try {
      const {url, options} = PRODUCTS_TO_MONTH(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {orders} = await response.json()
      const labels = []
      const produtos = []
      orders.map(order=>{
        labels.push(`${setMonth(order.mes)}/${order.product_name}`)
        produtos.push(order.produtos)
      })
      const data = {
        labels:labels,
        datasets:[
          {
            label: 'Produtos',
            data:produtos,
            backgroundColor: setBackgroundColor(orders.length)
          }
        ]
        }
        setProducts(data)
        setDataProduct(orders)
    } catch (error) {
      console.log(error)
    }
  } 

  const getCardsByDay= async ()=>{
    try {
      const search = {}
      if(start.value && end.value){
        search.start = start.value
        search.end = end.value
      }
      const {url, options} = GET_CARDS_BY_DAY(token, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {cards, maxTicket, canceled} = await response.json()
      setMaxTicket(maxTicket[0])
      setCanceled(canceled[0])
      console.log(maxTicket, canceled)
      const labels = []
      const cardsDay = []
      cards.map(card=>{
        labels.push(`Dia ${convertOnlyDate(card.dia)}`)
        cardsDay.push(card.comandas)
      })
      const dataValues = {
        labels:labels,
        datasets:[
          {
            label: 'Comandas',
            data:cardsDay,
            backgroundColor: setBackgroundColor(cards.length)
          }
        ]
        }
      setCardsByDay(dataValues)
    } catch (error) {
      console.log(error)
    }
  }
  const getDataWaiters = async()=>{
    try {
      getRates()
      setMessage('Dados dos garçons atualizados!')
      setOpenSnack(true)
      start.setValue('')
      end.setValue('')
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  const getDataTables =async ()=>{
    try {
      const search = {}
      if(start.value && end.value){
        search.start = start.value
        search.end = end.value
      }
      const {url, options} = GET_REPORT_TABLE(token, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {tables} = await response.json()
      setTables(tables)
      const labels = []
      const cards = []
      tables.map(table=>{
        labels.push(`Mesa ${table.number}`)
        cards.push(totalTableValue(table.cards))
      })
      const dataValues = {
        labels:labels,
        datasets:[
          {
            label: 'Faturamento',
            data:cards,
            backgroundColor: setBackgroundColor(tables.length)
          }
        ]
        }
        setTablesData(dataValues)
      setMessage('Dados das mesas atualizados!')
      setOpenSnack(true)
      start.setValue('')
      end.setValue('')
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  const getDataClients = async()=>{
    try {
      
    } catch (error) {
      
    }finally{
      setLoading(false)
    }
  }
  const getDataCards = async()=>{
    try {
      const search = {}
      if(start.value && end.value){
        search.start = start.value
        search.end = end.value
      }
      const {url, options} = GET_REPORT_CARDS(token, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {cards} = await response.json()
      setCards(cards)
      setMessage('Dados das comandas atualizados!')
      setOpenSnack(true)
      start.setValue('')
      end.setValue('')
      getCards()
      getCardsByDay()
    } catch (error) {
      
    }finally{
      setLoading(false)
    }
  }
  const getDataProducts = async()=>{
    try {
      
    } catch (error) {
      
    }finally{
      setLoading(false)
    }
  }

  const setActiveTab=(tab)=>{
    const tabs = [{tab:'waiter', fnctn: setTabWaiter, gtFunction:getDataWaiters},
     {tab:'table', fnctn:setTabTable, gtFunction: getDataTables}, 
     {tab:'client', fnctn:setTabClient, gtFunction: getDataClients}, 
     {tab:'card', fnctn:setTabCard,  gtFunction:getDataCards}, 
     {tab:'product', fnctn:setTabProduct,  gtFunction:getDataProducts}]
    tabs.map((tb)=>{
      if(tb.tab === tab){
        tb.fnctn(true)
        tb.gtFunction()
      }else{
        tb.fnctn(false)
      }
    })
  }
  const totalTableValue = (data)=>{
    let total = 0
    if(data && data.length){
    data.map(card=>{
      total+=card.value
    })
  }
    return total
  }

  const totalCards=(data)=>{
    let total = 0
   if(data && data.length){
    data.map(table=>{
      table.cards.map(card=>{
        total += card.value
      })
    })
   }
    return total
  }
  const totalCardsValue=(cards)=>{
    let total = 0
    if(cards && cards.length>0){
    cards.map(card=>{
      total+=card.value
    })
  }
    return total
  }
  const averageTicketCard=(cards)=>{
    let total = 0
    let average = 0
    if(cards && cards.length>0){
      cards.map(card=>{
        total +=card.value
      })
    }
    if(total>0){
      average = total/cards.length
    }
    return average
  }

  const getRates = async ()=>{
    try {
      const search = {}
      if(start.value && end.value){
        search.start = start.value
        search.end = end.value
      }
      const {url, options} = GET_RATES(token, search)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {rates} = await response.json()
      console.log("CLS Rates", rates)
      setRates(rates)
      console.log(rates)
    } catch (error) {
      console.log(error)
    }

  }

  const totalRate =(rates)=>{
    let total = 0
    if(rates && rates.length>0){
      rates.map(rate=>{
        if(rate){
          total += rate.value
        }
      })
    }
    return total
  }
  const typeRates =(rates, type)=>{
    let total = 0
    if(rates && rates.length>0){
      rates.map(rate=>{
        if(rate && rate.name === type ){
          if(rate){
            total += rate.value
          }
        }
      })
    }
    return total
  }

  React.useEffect(()=>{
    //getData()
    getDataWaiters()
  },[])
  return (
    <>          
    <MainNav/>
     <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Relatórios</Typography></h1>             
          </div>
          <div className="infoContainer">
          <TabBar>
            <Tab icon="supervised_user_circle" label="Garçons"  onClick={()=>setActiveTab('waiter')}/>
            <Tab icon="view_comfy" label="Mesas" onClick={()=>setActiveTab('table')}/>
            <Tab icon="people" label="Clientes" onClick={()=>setActiveTab('client')}/>
            <Tab icon="receipt_long" label="Comandas" onClick={()=>setActiveTab('card')}/>
            <Tab icon="restaurant" label="Produtos" onClick={()=>setActiveTab('product')}/>
          </TabBar>
          {loading && <LinearProgress />}
          {
            tabWaiter &&  
            <Grid>
              <GridCell span={12} className={"inputDates"}>
                <p>Período</p> 
              <TextField label="Inicio" type="date" {...start}/> <TextField label="Final" type="date" {...end}/><Button className={"btnReportUpdate"} label="Atualizar" onClick={()=>getDataWaiters()}/>
              </GridCell>
            <GridCell span={6} className={"dataWaiterContainer"}>
              <h4>Total de Taxas </h4>
              <div className="rateCard">
                <div className="rate">{valorFormatado(totalRate(rates))}</div>
                <div className="rateDetail">
                  <div className="rateInfo">
                  <strong>Taxa de Garçom</strong>
                    {valorFormatado(typeRates(rates, 'Taxa do garçom'))}
                  </div>
                    <div className="rateInfo">
                    <strong>Taxa de Serviços</strong>
                    {valorFormatado(typeRates(rates, 'Taxa de serviço'))}
                    </div>
                </div>
              </div>
            </GridCell>
            <GridCell span={6}>

            </GridCell>

            <GridCell span={6}>

            </GridCell>
            <GridCell span={6}>
              
            </GridCell>
        </Grid>
          }
          {
            tabTable && 
            <Grid>
                <GridCell span={12} className={"inputDates"}>
                 <p>Período</p> 
                <TextField label="Inicio" type="date" {...start}/> <TextField label="Final" type="date" {...end}/><Button className={"btnReportUpdate"} label="Atualizar" onClick={()=>getDataTables()}/>
                </GridCell>
                <GridCell span={6}>
                <div className="tablesData">
                <h4>Faturamento {tables && valorFormatado(totalCards(tables))}</h4>
                <div >
                <Grid className={"tablesCard"}>
                {
                  tables && tables.map(table=>{
                    return(
                      <GridCell span={6}>
                      <div className="tableData">
                      <div className="tableTitle">Mesa  {table.number}</div>
                      <div>Comandas: {table.cards.length}</div>
                      <div>Faturamento: {valorFormatado(totalTableValue(table.cards))}</div> 
                      </div>
                      </GridCell>
                    )
                  })
                }
                </Grid>
                </div>
                </div>
                
                </GridCell>
                <GridCell span={6}>
                <div className="tablesData">
                <h4>Faturamento</h4>
                <div >
                <Grid className={"tablesCard"}>
                <Bar data={tablesData} options={{}}/>
                </Grid>
                </div>
                </div>
                </GridCell>

                <GridCell span={6}>

                </GridCell>
                <GridCell span={6}>

                </GridCell>

            </Grid>
          }
          {
            tabClient &&  <Grid>
 
            <GridCell span={6}>

            </GridCell>
            <GridCell span={6}>

            </GridCell>

            <GridCell span={6}>

            </GridCell>
            <GridCell span={6}>
              
            </GridCell>

        </Grid>
          }
          {
            tabCard &&  <Grid>
              <GridCell span={12} className={"inputDates"}>
                <p>Período</p> 
              <TextField label="Inicio" type="date" {...start}/> <TextField label="Final" type="date" {...end}/><Button className={"btnReportUpdate"} label="Atualizar" onClick={()=>getDataCards()}/>
                </GridCell>
              <GridCell span={6}>
              <div className="cardsDataContainer">
                <h4>Comandas Fechadas</h4>
                <div >
                <Grid >
                {
                 cards && <div className="cardsData">
                   <div className="totalCards">{cards.length}</div>
                   <div className="detailsTotalCards">
                   <strong>Faturado</strong>
                   <div className="">{valorFormatado(totalCardsValue(cards))}</div>
                   <strong>Ticket médio</strong>
                   <div className="">{valorFormatado(averageTicketCard(cards))}</div>
                   </div>

                 </div>
                }
                </Grid>
                </div>
                </div>
              </GridCell>
              <GridCell span={6}>
              <div className="cardsDataContainer">
                <h4>Comandas / Mês</h4>
                <div >
                <Grid >
                {
                 cards && <div className="cardsDataChart">
                   <Bar data={dataCard} options={{}} />
                 </div>
                }
                </Grid>
                </div>
                </div>
              </GridCell>

              <GridCell span={6}>
              <div className="cardsDataContainer">
                <h4>Comandas / Dia</h4>
                <div >
                <Grid >
                {
                 cards && <div className="cardsDataChart">
                   <Line data={cardsByDay} options={{}} />
                 </div>
                }
                </Grid>
                </div>
                </div>
              </GridCell>
              <GridCell span={6}>
              <div className="cardsTicketContainer">
              <h4>Outras Informações</h4>
              <Grid>
                <GridCell span={6}>
                  <div className="dataTicket">
                    <strong>Maior Ticket</strong>
                    <div className="maxTicket">
                      {maxTicket.max?valorFormatado(maxTicket.max):0}
                    </div>
                  </div> 
                </GridCell>
                <GridCell span={6}>
                  <div className="dataTicket">
                    <strong>Pedidos Cancelados</strong>
                    <div className="maxTicket">
                      {canceled.count?canceled.count:0}
                    </div>
                  </div> 
                </GridCell>
                <GridCell span={6}></GridCell>
                <GridCell span={6}></GridCell>
              </Grid>
              </div>
              </GridCell>

        </Grid>
          }
          {
            tabProduct &&  <Grid>
 
            <GridCell span={6}>

            </GridCell>
            <GridCell span={6}>

            </GridCell>

            <GridCell span={6}>

            </GridCell>
            <GridCell span={6}>
              
            </GridCell>

        </Grid>
          }
          </div>           
        </div>    
        <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />   
    </>
  );
};

export default ReportsIndex;
