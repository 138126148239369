import React from 'react';
import { GET_COMPANY, LOGIN, GET_VALIDATE_TOKEN, GET_USER,
  GET_TABLES
} from './Api';
import { useNavigate } from 'react-router-dom';

export const UserContext = React.createContext();

export const UserStorage =({children})=>{
    const [data, setData] = React.useState(null);
    const [clientInTable, setClientInTable] = React.useState(null);
    const [login, setLogin] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [tableid, setTableid] = React.useState(0)
    const [billingid, setBillingid] = React.useState(0)
    const [newOrders, setNewOrders] = React.useState(0)
    const [isManager, setIsManager] = React.useState(false)
    const [functionReload, setFunctionReload] = React.useState(null)
    const [paginate, setPaginate] = React.useState({total:0, perPage:5, page:1, lastpage:0})
    const [tables, setTables] = React.useState([])
    const navigate = useNavigate();
    const userLogout = React.useCallback(
        async function () {
          setData(null);
          setError(null);
          setLoading(false);
          setLogin(false);
          setIsManager(false)
          window.localStorage.removeItem('token');
          navigate('/login');
        },
        [navigate],
      );
    
      async function getUser(role){
       // const { url, options } = GET_USER(token);
        //const response = await fetch(url, options);
        //const {user} = await response.json();
        //console.log('MANAGER SET',user.role)
        //setData(user);
        //setLogin(true);
        if(role === 'manager'){
          setIsManager(true)
        }
        
      }
      async function validaToken(token){
        const { url, options } = GET_VALIDATE_TOKEN(token);
        const response = await fetch(url, options);
        if (!response.ok) throw new Error('Token inválido');
        const {checked, role} = await response.json()
        return {checked, role}
      }

      async function userLogin(body) {
        try {
          setError(null);
          setLoading(true);
          const { url, options } = LOGIN(body);
          const response = await fetch(url, options);
          if (!response.ok) throw new Error(`Error: ${response.statusText}`);
          const { data } = await response.json();
          const {checked, role} = await validaToken(data.token)
          getUser(role)
          if(!checked){
            setError('Unautorized');
            navigate('/login');
          }else{
            window.localStorage.setItem('token', data.token);
            setLogin(true);
            if(role==='manager' ){
              navigate('/');
            }else{
              navigate('/mesas')
            }
            
          }
         
        } catch (err) {
          setError(err.message);
          setLogin(false);
        } finally {
          setLoading(false);
        }
      }
      const getVerifyManager = async (token)=>{
        const {checked, role} = await validaToken(token)
        if(role === 'manager'){
          setIsManager(true)
        }
      }
      function setTableId(id){
        setTableid(id)
      }

      function setBillingId(id){
        setBillingid(id)
      }

      function setNewOrder(quantity){
        setNewOrders(quantity)
      }

      function setUpdateData(getData){
        setFunctionReload(getData)
      }
      const totalNewOrders=(tables)=>{
        let total = 0
        tables.map(table=>{
          total += table.status?1:0
        })
        setNewOrders(total)
      }

      const getTables = async ()=>{
        try {     
          const token = window.localStorage.getItem('token') 
          const {url, options} = GET_TABLES(token, paginate)
          const response = await fetch(url, options)
          if(!response.ok) throw new Error(`Error: ${response.statusText}`)
          const {tables} = await response.json()
          setTables(tables.data)
          setPaginate({total:tables.total, perPage:tables.perPage, page:tables.page, lastpage:tables.lastPage})
          totalNewOrders(tables.data)
        } catch (error) {
          console.log(error)
        }finally{
          setLoading(false)
        }
      }

      React.useEffect(() => {
        async function autoLogin() {
          const token = window.localStorage.getItem('token');
          if (token) {
            try {
              setError(null);
              setLoading(true);         
              setLogin(true);
              //await getUser(token);
              //await getCompany(token)
            } catch (err) {
              userLogout();
              setError(err.message);
             
            } finally {
              setLoading(false);
            }
          }
        }
        autoLogin();
      }, [userLogout]);
    
      return (
        <UserContext.Provider
          value={{ userLogin, userLogout, data, error, loading, login, tableid, billingid, newOrders, isManager,functionReload,
             tables, clientInTable, setTables, setClientInTable,
            setTableId, 
            setNewOrder, getUser, userLogout, getVerifyManager,setUpdateData, setBillingId, getTables }}
        >
          {children}
        </UserContext.Provider>
      );
}