import {DELETE_ATTRIBUTE_VALUE, GET_ATTRIBUTE} from '../../Api'
export const  getAttribute = async (token, id)=>{
    try {
        const {url, options} = GET_ATTRIBUTE(token, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {attribute} = await response.json()
        return attribute
    } catch (error) {
        console.log(error)
    }
}

export const delteAttributeValue = async(token, id)=>{
    try {
        const {url, options} = DELETE_ATTRIBUTE_VALUE(token, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        console.log(response)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}