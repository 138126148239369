import React from 'react'
const  DialogCenter = ({open, setOpen, Content, getTables, openNewOrder, setOpenNewOrder})=>{
    return(
      <>
      {open &&
        <div className="modal">
            <div className="dialogContent">
            <span className="close" onClick={()=>{setOpen(false)}}>&times;</span>
                <Content getTables={getTables} openNewOrder={openNewOrder} setOpenNewOrder={setOpenNewOrder} 
                setOpenFather={setOpen}/>
                {/*<button className="closeButton" onClick={()=>{setOpen(false)}}>Fechar</button> */}  
            </div>
            
        </div>}
        </>
    )
}

export default DialogCenter