import React from "react";
import { 
  Grid, Typography, GridCell, 
  GridRow,DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell, 
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  Snackbar,
  SnackbarAction
} from "rmwc";
import { GET_PRINTERS, POST_PRINTER, DELETE_PRINTER, UPDATE_PRINTER } from "../../Api";
import MainNav from "../../MainNav";


const PrintersIndex = () => {
  const [data, setData] = React.useState([])
  const [openform, setOpenform] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [printer, setPrinter] = React.useState(null)
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [name, setName] = React.useState('')
  const [code, setCode] = React.useState('')
  const token = window.localStorage.getItem('token')
  const [openSnack, setOpenSnack] = React.useState(false)
  const [message, setMessage] = React.useState('')


  const getData = async ()=>{
    try {
      const {url,options} = GET_PRINTERS(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {printers} = await response.json()
      setData(printers)
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

    const handleSubmit = async event =>{
      event.preventDefault()
      const token = window.localStorage.getItem('token')
      if(!token) throw new Error('Token inválido')
      const {url,options} = POST_PRINTER(token,{name:event.target.name.value, code:event.target.code.value})
      const response = await fetch(url,options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {printer} = await response.json()
      setMessage(`Inpressora ${printer.name} criada com sucesso`)
      setOpenSnack(true)
      setOpenform(false)
      getData()
    }

    const updatePrinter=(id)=>{
      try {
        const idx = data.findIndex((printer)=> printer.id === id)
        setPrinter(data[idx])
        setName(data[idx].name)
        setCode(data[idx].code)
        setOpenEdit(true)
      } catch (error) {
        console.log(error)
      }
    }
    const deletePrinter=(id)=>{
      try {
        const idx = data.findIndex((printer)=> printer.id === id)
        setPrinter(data[idx])
        setOpenDelete(true)
      } catch (error) {
        console.log(error)
      }
    }

    const toDelete = async (id)=>{
      try {
        const {url, options} = DELETE_PRINTER(token, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        setMessage(`Inpressora excluída com sucesso`)
        setOpenSnack(true)
        getData()
      } catch (error) {
        console.log(error)
      }
    }


    const toUpdate = async event =>{
      event.preventDefault()
      try {
        const body = {
          name:event.target.name.value,
          code:event.target.code.value
        } 
        const {url, options} = UPDATE_PRINTER(token, printer.id, body)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        setMessage(`Inpressora ${event.target.name.value} atualizada com sucesso`)
        setOpenSnack(true)
        getData()
      } catch (error) {
        console.log(error)
      }
    }

React.useEffect(()=>{
  getData()
},[])

  return (
    <>     
    <MainNav/> 

    <Dialog
        open={openEdit}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenEdit(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Editar {printer?printer.name:""}?</DialogTitle>
        <form onSubmit={toUpdate}>
        <DialogContent>
        <TextField type="text" name="name" fullwidth placeholder="Nome da impressora" value={name} onChange={({target})=>setName(target.value)}/>
        <TextField type="text" name="code" fullwidth placeholder="Codigo da impressora" value={code} onChange={({target})=>setCode(target.value)}/>
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept">
            Salvar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog>

    <Dialog
        open={openDelete}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenDelete(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Excluir {printer?printer.name:""}?</DialogTitle>
        <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
        <DialogActions> 
          <DialogButton action="Denied">Cancelar</DialogButton>      
          <DialogButton action="accept" onClick={()=>toDelete(printer.id)}>
            Excluir
          </DialogButton>
        </DialogActions>
      </Dialog>

    { loading && <div className="loading" ><CircularProgress size={125} /></div>} 
     { !loading && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Impressoras</Typography></h1>             
          </div>
          <Button className={"BtnDefaultTmenu"} label="Cadastrar Impressora" icon="add" onClick={()=>setOpenform(!openform)} />
            {
              openform && 
              <form lassName="formContainer" onSubmit={handleSubmit}>
              <Grid >
                
                <GridRow>
                    <GridCell span={4}>
                      <TextField type="text" name="name" fullwidth placeholder="Nome" />
                    </GridCell>
                    <GridCell span={3}>
                      <TextField type="text" name="code" fullwidth placeholder="Código" />
                    </GridCell>
                    <GridCell span={3}>
                      <Button type="submit" className={"BtnDefaultTmenu"} label="Cadastrar" icon="add" /> 
                    </GridCell>
                </GridRow>
                
              </Grid>
              </form>
            }

            <Grid className={"CustomContainer"}>
                <GridRow>
                  <GridCell>
                    <DataTable className={"TabelaProdutos"}>
                      <DataTableContent>
                        <DataTableHead>
                          <DataTableRow>
                            <DataTableHeadCell>Nome</DataTableHeadCell>
                            <DataTableHeadCell alignEnd>Código</DataTableHeadCell>
                            <DataTableHeadCell></DataTableHeadCell>
                            <DataTableHeadCell></DataTableHeadCell>
                          </DataTableRow>
                        </DataTableHead>
                          <DataTableBody>
                            { data.map( printer =>{
                              return(
                                <DataTableRow key={printer.id}>
                                  <DataTableCell><Typography use="overline"> {printer.name}</Typography></DataTableCell>
                                  <DataTableCell >{printer.code}</DataTableCell>
                                  <DataTableCell><Button onClick={()=>updatePrinter(printer.id)} label="Editar" className={"btnEdit"} icon="edit" /></DataTableCell>
                                  <DataTableCell><Button onClick={()=>deletePrinter(printer.id)} label="Excluir" className={"btnDelete"} icon="delete" /></DataTableCell>
                                </DataTableRow>
                              )
                            })
                              
                            }
                          </DataTableBody>  
                      </DataTableContent>
                    </DataTable>
                  </GridCell>
                </GridRow>
            </Grid> 
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />

        </div>}
    </>
  );
};

export default PrintersIndex;
