import React from 'react'
import { UserContext } from '../../UserContext';
import { GET_BILLING } from "../../Api";
import {CircularProgress,  Grid, GridCell, GridRow,
    Typography,
    Button,
    TextField,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,} from "rmwc";
    import {convertDate, convertOnlyDate, isExpired } from '../../Utils'
const ExtractBilling =({id})=>{
    const { billingid } = React.useContext(UserContext);
    const [viewTable, setViewTable] = React.useState(false)
    const token = window.localStorage.getItem('token')
    const [loadind, setLoading] = React.useState(true)
    const [billing, setBilling] = React.useState({})
    const getBilling = async ()=>{
        try {
            setViewTable(false)
            const {url, options} = GET_BILLING(token, id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {billing} = await response.json()
            setBilling(billing)
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    React.useEffect(()=>{
        getBilling()
    }, [id])
    return (
        <>
         { loadind && <div ><CircularProgress size={80} /></div>}
         {
             !loadind && billing && <div className="billingContainer">
                 <Grid>
                     <GridRow>
                         <GridCell span={12}>
                         <Typography use="headline5">{billing.description}</Typography>                              
                         </GridCell>
                     </GridRow>
                     <GridRow>
                         <GridCell span={6}>
                         <Typography use="overline">Data de faturamento: {convertDate(billing.created_at)}</Typography>                              
                         </GridCell>
                         <GridCell span={6}>
                         <Typography use="overline">Valor: $R {billing.value}</Typography>                              
                         </GridCell>
                     </GridRow>
                     <GridRow>
                         <GridCell span={6}>
                         <Typography use="overline">Data de vencimento: {convertOnlyDate(billing.due_date)}</Typography>                              
                         </GridCell>
                         <GridCell span={6}>
                                                    
                         </GridCell>
                     </GridRow>
                     <GridRow>
                         <GridCell span={12}>
                         <Typography use="overline">Link de pagamento: <a href={billing.billing_link} target="_blank" >{billing.billing_link}</a></Typography>                              
                         </GridCell>
                     </GridRow>
                     <GridRow>
                         <GridCell span={12}>
                         <Typography use="overline">Status: {
                             isExpired(billing.due_date)?"Vencida":billing.status
                         }</Typography>                              
                         </GridCell>
                     </GridRow>
                     {/* <GridRow>
                         {!viewTable && <GridCell>
                             <Typography use="overline">{billing.cards.length>0?billing.cards.length:"0" } comandas faturadas </Typography>
                             <Button onClick={()=>setViewTable(true)}>Visualizar comandas</Button>
                             </GridCell>}
                         { viewTable && <GridCell>
                             
                         <DataTable className={"TabelaProdutos"} label="Comandas">
                         <DataTableContent>                      
                            <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell alignEnd>Cód.</DataTableHeadCell>
                                <DataTableHeadCell alignEnd>Data de faturamento</DataTableHeadCell>
                                <DataTableHeadCell alignEnd>Valor</DataTableHeadCell>

                            </DataTableRow>
                            </DataTableHead>
                            <DataTableBody>
                            {
                                billing.cards.map(card=>{
                                    return(
                                        <DataTableRow key={card.id}>
                                            <DataTableCell>{card.id}</DataTableCell>
                                            <DataTableCell>{convertDate(card.created_at)}</DataTableCell>
                                            <DataTableCell>{card.value}</DataTableCell>
                                        </DataTableRow>
                                    )
                                })
                            }
                            </DataTableBody>
                            </DataTableContent>
                            </DataTable>   
                            <Button onClick={()=>setViewTable(false)}>Fechar</Button> 
                         </GridCell>}
                     </GridRow> */}
                 </Grid>
             </div>
         }
        </>
    )
}

export default ExtractBilling