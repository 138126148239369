import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GET_ATTRIBUTES, GET_PRODUCT, POST_ATTRIBUTE, PUT_PRODUCT_ATTRIBUTE,
    POST_ATTRIBUTE_VALUE,API_URL, PUT_PRODUCT, POST_ADD_IMAGE_PRODUCT, DELETE_IMAGE,
    DELETE_PRODUCT_ATTRIBUTE } from '../../Api'
import { Grid, GridCell, GridRow,
    Typography,
    Button,
    TextField,
    Switch,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,
    SimpleMenu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogButton,
    DialogContent,
    Select,
    Badge,
    IconButton,
    Icon,
    ImageList,
    ImageListItem,
    ImageListImageAspectContainer,
    ImageListImage,
    ImageListSupporting,
    ImageListLabel,
    CircularProgress,
    Snackbar,
    SnackbarAction
  } from "rmwc";
  import MainNav from "../../MainNav";
  import useForm from '../../Hooks/UseForm'
const Product =()=>{
   const {id} = useParams()
   const [loadind, setLoading] = React.useState(true)
   const [openSnack, setOpenSnack] = React.useState(false)
   const [message, setMessage] = React.useState('')
   const [product, setProduct] = React.useState({})
   const [attributes, setAttributes] = React.useState([])
   const [attributeRemove, setAttributeRemove] = React.useState({})
   const [formAttribute, setFormAttribute] = React.useState(false)
   const [open, setOpen] = React.useState(false)
   const [openDelete, setOpenDelete] = React.useState(false);
   const [openRemoveAttribute, setOpenRemoveAttribute] = React.useState(false);
   const [attributeid, setAttributeid] = React.useState(0)
   const [name, setName] = React.useState('')
   const [code, setCode] = React.useState('')
   const [description, setDescription] = React.useState('')
   const [value, setValue] = React.useState('')
   const [status, setStatus] = React.useState('')
   const [img, setImg] = React.useState({})
   const [imgDelete, setImgDelete] = React.useState({})
   const maxItem = useForm()
   const additionalValue = useForm()
   const valueName = useForm()
   const valueDescription = useForm()
   const token = window.localStorage.getItem('token')
    const getData = async ()=>{
        try {
            const token = window.localStorage.getItem('token')
            if(!token) throw new Error(`Error: Token inválido`)
            const {url, options} = GET_PRODUCT(token, id)
            const respose = await fetch(url, options)
            if(!respose.ok) throw new Error(`Error: ${respose.statusText}`)
            const {product} = await respose.json()
            setProduct(product)
            setName(product.name)
            setCode(product.code)
            setDescription(product.description)
            setValue(product.value)
            setStatus(product.status)
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    const getAttributes = async ()=>{
        try {
            const token = window.localStorage.getItem('token')
            if(!token) throw new Error(`Error: Token inválido`)
            const {url, options} = GET_ATTRIBUTES(token)
            const respose = await fetch(url, options)
            if(!respose.ok) throw new Error(`Error: ${respose.statusText}`)
            const {attributes} = await respose.json()
            setAttributes(attributes.data)
        } catch (error) {
            console.log(error)
        }
    }

    const addAttribute = async event =>{
        event.preventDefault()
        try {
            const body ={
                title: event.target.title.value,
                description: event.target.description.value,
                max_item: event.target.max_item.value,
                required: event.target.required.checked
            }
            const token = window.localStorage.getItem('token')
            if(!token) throw new Error(`Error: Token inválido`)
            const {url, options} = POST_ATTRIBUTE(token, body)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(`Error: ${response.statusText}`)
            const {attribute} = await response.json()
            if(attribute.id){
                const valuesApi = PUT_PRODUCT_ATTRIBUTE(token, product.id, attribute.id)
                const resp = await fetch(valuesApi.url, valuesApi.options)
                if(!resp.ok) throw new Error(`Error: ${resp.statusText}`)
                getAttributes()
                getData()
                setFormAttribute(false)
                setMessage(`Complemnto ${attribute.title} cadastrado com sucesso!`)
                setOpenSnack(true)
            } 

        } catch (error) {
            console.log(error)
        }
    }

    const attributeToProduct = async event =>{
        event.preventDefault()
        try {
            const {url, options} = PUT_PRODUCT_ATTRIBUTE(token, product.id, event.target.id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(`Error: ${response.statusText}`)
            getData()
        } catch (error) {
            console.log(error)
        }
    }

    const addAttributeValue = async event =>{
        event.preventDefault()
        try {
           const body={
               name:event.target.name.value,
               description: event.target.description.value,
               additional_value: event.target.additional_value.value,
               max_item: event.target.max_item.value,
               attribute_id: attributeid
           }
           const token = window.localStorage.getItem('token')
           if(!token) throw new Error(`Error: Token inválido`)
           const {url, options} = POST_ATTRIBUTE_VALUE(token, body)
           const response = await fetch(url, options)
           if(!response.ok) throw new Error(`Error: ${response.statusText}`)
           getData()
           valueName.setValue('')
           valueDescription.setValue('')
           maxItem.setValue('')
           additionalValue.setValue('')
        } catch (error) {
            console.log(error)
        }
    }

    const openModalAddId = (event) =>{
        event.preventDefault()
        setOpen(true)
        setAttributeid(event.target.id)
    }

    const updateProduct = async ()=>{
        try {
            const body ={
                name, value, description, code, status
            }
            const {url, options} = PUT_PRODUCT(token, product.id, body)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            setMessage(`Produto atualizado com sucesso!`)
            setOpenSnack(true)
            getData()
        } catch (error) {
            console.log(error)
        }
    }
 const handleImgSubmit = async event=>{
    event.preventDefault()
    try {
        const file = img.raw
        const formData = new FormData()
        for(let i = 0; i < file.length; i++){
            formData.append(`file[${i}]`, file[i])
        }
        formData.append('product_id', product.id)
        const {url, options} = POST_ADD_IMAGE_PRODUCT(token,formData)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {message} = await response.json()
        setMessage("Imagem salva!")
        setOpenSnack(true)
        getData()
    } catch (error) {
        console.log(error)
    }
 }

 const handleImgChange = async ({target})=>{
    setImg({
        raw:target.files
    })
 }
 const deleteImage = (id)=>{
    const idx = product.images.findIndex((image)=> image.id === id)
    setImgDelete( product.images[idx])
    setOpenDelete(true)
  }

  const toDelete = async (id)=>{
    try {
      const {url, options} = DELETE_IMAGE(token, id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      setMessage("Imagem excluido com Sucesso!")
      setOpenSnack(true)
      getData()
    } catch (error) {
      console.log(error)
    }
  }

  const removeAttribute = (id)=>{
    const idx = product.attributes.findIndex((attribute)=> attribute.id === id)
    setAttributeRemove( product.attributes[idx])
    setOpenRemoveAttribute(true)
  }

  const toRemove = async (id)=>{
      try {
        const {url, options} = DELETE_PRODUCT_ATTRIBUTE(token, product.id, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        setMessage(`O complemento ${attributeRemove.title} foi removido de ${product.name}`)
        setOpenSnack(true)
        getData()
      } catch (error) {
          console.log(error)
      }
  }

    React.useEffect(()=>{
        getData()
        getAttributes()
    },[])
    return(
        <>
             
            <MainNav />
            { loadind && <div className="loading" ><CircularProgress size={125} /></div>}
            { !loadind && <>

                <Dialog
                    open={openRemoveAttribute}
                    onClose={evt => {
                    console.log(evt.detail.action);
                    setOpenRemoveAttribute(false);
                    }}
                    onClosed={evt => console.log(evt.detail.action)}
                >
                    <DialogTitle>Remover {attributeRemove && attributeRemove.title} de {product && product.name}</DialogTitle>
                    <DialogContent>O complemento não será excluido!</DialogContent>
                    <DialogActions> 
                    <DialogButton action="Denied">Cancelar</DialogButton>      
                    <DialogButton action="accept" onClick={()=>toRemove(attributeRemove.id)}>
                        Excluir
                    </DialogButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDelete}
                    onClose={evt => {
                    console.log(evt.detail.action);
                    setOpenDelete(false);
                    }}
                    onClosed={evt => console.log(evt.detail.action)}
                >
                    <DialogTitle>Excluir Imagem?</DialogTitle>
                    <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
                    <DialogActions> 
                    <DialogButton action="Denied">Cancelar</DialogButton>      
                    <DialogButton action="accept" onClick={()=>toDelete(imgDelete.id)}>
                        Excluir
                    </DialogButton>
                    </DialogActions>
                </Dialog>

            <Dialog open={open} onClose={evt => {
                console.log(evt.detail.action);
                setOpen(false);
                }}
            onClosed={evt => console.log(evt.detail.action)}>
            <DialogTitle>Cadastrar opção</DialogTitle>
            <form onSubmit={addAttributeValue}>
            <DialogContent>
                <TextField fullwidth placeholder="Nome" name="name" {...valueName}/>
                <TextField fullwidth placeholder="Descrição" name="description" {...valueDescription} />
                <TextField fullwidth placeholder="Valor adicional" name="additional_value" {...additionalValue} />
                <TextField fullwidth placeholder="Qantidade máxima permitida" name="max_item" {...maxItem} />
            </DialogContent>
            <DialogActions>
                <DialogButton className={"BtnDefaultTmenu"} action="accept" isDefaultAction>
                Salvar!
                </DialogButton>
            </DialogActions>
            </form>
            </Dialog>

            <div className={"PageContainer"}>
                <div className={"PageTitle"}>        
                    <h1><Typography use="headline1">{product.name}</Typography></h1>             
                </div>
                <div  className="infoContainer">
                <Grid>
                    <GridRow>
                        <GridCell span={12}>
                            <GridRow>
                                <GridCell span={6}> 
                                <Typography use="overline"><strong>Nome: </strong></Typography>  
                                <input type="text" name="name"  size="50" value={name} onChange={({target})=>setName(target.value)} className="inputCusston"/>
                                </GridCell>
                                <GridCell span={6}>
                                <Typography use="overline"><strong>Código: </strong></Typography>    
                                <input type="text" name="code" value={code} onChange={({target})=>setCode(target.value)} className="inputCusston"/>
                                </GridCell>
                            </GridRow>
                            <GridRow>
                                <GridCell span={6}>
                                    <GridRow>
                                        <GridCell>
                                        <Typography use="overline"><strong>Descrição: </strong></Typography>
                                        </GridCell>
                                    </GridRow>
                                    <GridRow>
                                        <GridCell>
                                        <textarea id="w3review" name="description" rows="2" cols="40" value={description} onChange={({target})=>setDescription(target.value)}/>
                                        </GridCell>
                                    </GridRow>
                                </GridCell>
                                <GridCell span={6}>
                                <Typography use="overline"><strong>Categoria: </strong>{product.category.name}</Typography>
                                </GridCell>
                            </GridRow>
                            <GridRow>
                                <GridCell span={6}> 
                                <Typography use="overline"><strong>Preço: </strong></Typography>   
                                <input type="text" name="value" value={value} onChange={({target})=>setValue(target.value)} className="inputCusston"/>
                                </GridCell>
                                <GridCell span={1}>  
                                <Typography use="overline"><strong>Status: </strong></Typography>  
                                </GridCell>
                                <GridCell span={4}>
                                    <Select label="Selecione" defaultValue={status} value={status} onChange={({target})=>setStatus(target.value)}>
                                        <option value="ATIVO">Ativo</option>
                                        <option value="INATIVO">Desativado</option>
                                        <option value="FORA DE ESTOQUE">Fora de estoque</option>
                                    </Select>
                                </GridCell>
                            </GridRow>
                            <GridRow>
                                <GridCell span={3}>
                                    <Button onClick={updateProduct} className={"BtnDefaultTmenu"} label="Atualizar" icon="update" />
                                </GridCell>
                                <GridCell span={8}>
                                </GridCell>
                                
                            </GridRow>

                        </GridCell>
                    </GridRow>
                </Grid>
                <br/>
                <Grid>
                    <GridRow>
                        <GridCell span={12}>
                            <h5><Typography use="headline5">Fotos de {product.name}</Typography></h5>
                        </GridCell>
                    </GridRow>
                    <GridRow>
                        <GridCell span={12}>
                            <h5><Typography use="overline">Adicionar imagem a {product.name}</Typography></h5>
                        </GridCell>
                    </GridRow>
                        <form onSubmit={handleImgSubmit}>
                            <GridRow>
                                <GridCell span={6}>
                                    <input type="file" name="file[]" onChange={handleImgChange} multiple required/>
                                </GridCell>
                                <GridCell span={6}>
                                <Button label="Salvar imagem" outlined icon="add" className={"BtnDefaultSearch"} type="submit"/>
                                </GridCell>
                            </GridRow>
                            </form>
                    <GridRow>
                        <GridCell span={12}>
                        {product.images.length > 0 && 
                        <ImageList>
                        { product.images.map(image=>(
                            <ImageListItem
                            key={image.id}
                            style={{ margin: '2px', width: 'calc(100% / 5 - 4.2px)' }}
                          > <div  className="prodcutImageDetail">
                            <ImageListImageAspectContainer
                              style={{ paddingBottom: 'calc(100% / 1.5)' }}
                            >
                              <ImageListImage src={`${API_URL}download/img/${image.path}`} />
                            </ImageListImageAspectContainer>
                            
                            <ImageListSupporting className="optionDelete">
                                <ImageListLabel onClick={()=>deleteImage(image.id)}>Excluir imagem</ImageListLabel>
                            </ImageListSupporting>
                            </div>
                          </ImageListItem>
                        ))
                       }                               
                        </ImageList>}
                        </GridCell>
                    </GridRow>
                </Grid>
                <br/>
                <Grid>
                <GridRow>
                    <GridCell span={12}>
                        <GridRow>
                        <GridCell span={6}>
                            <Typography use="headline6">Complementos</Typography>
                        </GridCell>
                        <GridCell span={6}>
                        { formAttribute && <Button className={"BtnDefaultTmenu"} label="Fechar" icon="filter_list" onClick={()=>setFormAttribute(!formAttribute)}/>}
                        {!formAttribute && <SimpleMenu handle={                 
                        <Button className={"BtnDefaultTmenu"} label="Adicionar complemento" icon="filter_list" />
                        }>
                            <MenuItem onClick={()=>setFormAttribute(!formAttribute)}>Cadastrar Complemento</MenuItem>
                            { attributes && attributes.map(attribute=>{
                                return(
                                    <MenuItem key={attribute.id} id={attribute.id} onClick={attributeToProduct}>{attribute.title}</MenuItem>
                                )
                            })}
                        </SimpleMenu>}
                        </GridCell>
                        </GridRow>
                        {
                            formAttribute &&
                            <form onSubmit={addAttribute}> 
                            <GridCell span={12}>
                                <GridRow>
                                    <GridCell span={6}>
                                    <TextField type="text" name="title" fullwidth placeholder="Nome"  />
                                    </GridCell>
                                    <GridCell span={6}>
                                    <TextField type="text" name="description" fullwidth placeholder="Descrição"  />
                                    </GridCell>
                                </GridRow>
                                <GridRow>
                                    <GridCell span={6}>
                                    <TextField type="number" name="max_item" fullwidth placeholder="Número máximo de itens"  />
                                    </GridCell>
                                    <GridCell span={6}>
                                    <Switch  label="Item obrigatório?" name="required" />
                                    </GridCell>
                                </GridRow>
                                <GridRow>
                                    <GridCell span={6}></GridCell>
                                    <GridCell span={6}>
                                    <Button label="Cadastrar" outlined icon="add" className={"BtnDefaultSearch"} type="submit"/>
                                    </GridCell>
                                </GridRow>
                            </GridCell>
                            </form>
                        }
                    </GridCell>
                   
                </GridRow>    
                <br/>
                <GridRow>
                        <GridCell>
                        <DataTable className={"TabelaProdutos"}>
                        <DataTableContent>
                            <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell>Nome</DataTableHeadCell>
                                <DataTableHeadCell alignEnd>Descrição</DataTableHeadCell>
                                <DataTableHeadCell alignEnd>Máximo de itens</DataTableHeadCell> 
                                <DataTableHeadCell alignEnd></DataTableHeadCell>   
                                <DataTableHeadCell alignEnd></DataTableHeadCell>                         
                            </DataTableRow>
                            </DataTableHead>
                            <DataTableBody>
                            {product.attributes &&
                                product.attributes.map(attribute=>{
                                    return(
                                        <DataTableRow key={attribute.id} >
                                            <DataTableCell >{attribute.title}</DataTableCell>
                                            <DataTableCell alignEnd>{attribute.description}</DataTableCell>
                                            <DataTableCell >{attribute.max_item}</DataTableCell>
                                            
                                                <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label="Opções" icon="filter_list" />}>
                                                        <MenuItem onClick={openModalAddId} id={attribute.id}>Adicionar opção</MenuItem>
                                                    {attribute.values.map(value =>{
                                                        return(
                                                            <MenuItem  value={value.id}>{value.name}</MenuItem>
                                                        )
                                                    })
                                                   
                                                    }
                                                </SimpleMenu>          
                                            <DataTableCell><Button onClick={()=>removeAttribute(attribute.id)}>Remover</Button></DataTableCell>
                                        </DataTableRow>
                                    )
                                })
                            }
                            </DataTableBody>
                        </DataTableContent>
                        </DataTable> 
                        </GridCell>       
                    </GridRow>
                </Grid>
                <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="Ok"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
                </div>
            </div>
            </>}
        </>
    )
}

export default Product