import React, { useRef }  from 'react'
import { GET_TABLE, GET_COMPANY,
    PUT_CLOSE_TABLE, API_URL, PUT_CLOSE_ORDER,
    POST_CREATE_ORDER, UPDATE_STATUS_ORDER
} from '../../Api';
import { UserContext } from '../../UserContext';
import { useNavigate, useLocation} from 'react-router-dom';
import { Grid, GridCell, GridRow,
    Typography,
    Button,
    TextField,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,
    SimpleMenu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogButton,
    DialogContent,
    Select,
    Badge,
    IconButton,
    Icon,
    Switch,
    Radio,
    ImageList,
    ImageListItem,
    ImageListImageAspectContainer,
    ImageListImage,
    ImageListSupporting,
    ImageListLabel,
    CircularProgress,
    Snackbar,
    SnackbarAction,
    BadgeAnchor,
    Tooltip
  } from "rmwc";

  import './Tables.css'
  import {PrinterOrder} from '../Printer/PrinterOrder'
  import { PrinterTable } from '../Printer/PrinterTable';
  import { PrinterCard } from '../Printer/PrinterCard'; 
  import {useReactToPrint} from 'react-to-print';
  import { addComman, valorFormatado } from '../../Utils'
const TableAccount =({openNewOrder, setOpenNewOrder, setOpenFather})=>{
    const[loadind, setLoading] = React.useState(true)
    const { tableid, setTableId, getTables } = React.useContext(UserContext);
    const token = window.localStorage.getItem('token')
    const [table, setTable] = React.useState({})
    const [data, setData] = React.useState({})
    const [itens, setItens] = React.useState([])
    const [total, setTotal] = React.useState(0)
    const [company, setCompany] = React.useState({})
    const [message, setMessage] = React.useState('')
    const [openSnack, setOpenSnack] = React.useState(false)
    const [action, setAction]= React.useState(null)
    const [orderToShow, setOrderToShow]= React.useState(null)
    const [dialogOrderToShow, setDialogOrderToShow]= React.useState(null)
    const [openConfirmClose, setOpenConfirmClose] = React.useState(false)
    const [openDescriptionModal, setOpenDescriptionModal] = React.useState(false)
    const [printering, setPrintering] = React.useState(false)
    const [contentDescriptionModal, setContentDescriptionModal] = React.useState([])
    const [cardClose, setCardClose] = React.useState(null)
    const { state } = useLocation();
    const navigate = useNavigate()
    const componentRef = useRef();
    const componentRefClose = useRef();



    const getCompany = async () =>{
        const {url, options} = GET_COMPANY(token)
        const response = await fetch(url, options)
        const {company} = await response.json()
        setCompany(company)
      }
    const getData = async ()=>{
        try {
            getCompany()
            const {url, options} = GET_TABLE(token, tableid)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {table} = await response.json()
            setTable(table)
            setTotal(setTotalValue(table.cards))
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    const setTotalValue=(cards)=>{
        let value = 0
        cards.map(card=>{
            value +=card.value
        })
        return value
    }

    const addItem=(produtos)=>{
        const itens = []
        produtos.map(prod=>{

            const idx = itens.findIndex((pro)=>pro.name === prod.product_name)
            if(prod.status !== "Cancelado"){
                if(idx === -1){
                    itens.push({id: prod.id, quantity:prod.quantity, name:prod.product_name, value:prod.product_value, product_id:prod.product_id, sended: prod.sended, produtos:[prod]})
                }else{
                    itens[idx].quantity +=prod.quantity
                    itens[idx].produtos.push(prod)
                }
            }
            
        })
        return itens
    }

    const closeTable = async () =>{
        //event.preventDefault()
        try {
          const body = {
            data:[]
          }
          const {url, options} = PUT_CLOSE_TABLE(token, tableid, body)
          const response = await fetch(url, options)
          if(!response.ok) throw new Error(`Error: ${response.statusText}`)
          const {pdf} = await response.json()
          setMessage('Conta fechada com sucesso!')
          setOpenSnack(true)
          getTables()
          getData()
          window.open(`${API_URL}download/pdf/${pdf}`)
        } catch (error) {
          console.log(error)
        }
      }

      const closeCard = async (card)=>{
        try {
            const {url, options} = PUT_CLOSE_ORDER(token, card.id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(`Error: ${response.statusText}`)
            const {pdf} = await response.json()
            setMessage(`A conta do cliente ${card.user.name} foi fechada com sucesso!`)
            setOpenSnack(true)
            getTables()
            getData()
            window.open(`${API_URL}download/pdf/${pdf}`)
        } catch (error) {
            console.log(error)
        }
      }

      const addItemToCard = async (order, user_id)=>{
        try {
            const body ={
                table_id:table.id,
                user_id:user_id,
                itens:[{product_id:order.product_id, quantity:1, observations:"", attributes:[]}]
            }
            const {url, options} = POST_CREATE_ORDER(token, body)
            const response = await fetch(url, options)            
            if(!response.ok) throw new Error(`Error: ${response.statusText}`)    
            setMessage(`Item adicionado à conta!`)
            setOpenSnack(true)    
            getTables()
            getData()
        } catch (error) {
            console.log("CLS ",error)
        }
      }

      const openDescription =(produtos)=>{
            setContentDescriptionModal(produtos)
            setOpenDescriptionModal(true)
      }


      const updateStatus = async (id, status)=>{
        try {
          const body = {
            status:status
          }
          const {url, options} = UPDATE_STATUS_ORDER(token, id, body)
          const response = await fetch(url, options)
          if(!response.ok) throw new Error(response.statusText)
          const {item} = await response.json()
          setMessage(`O pedido número ${item.id} foi Cancelado!`)
          setOpenSnack(true)
          getData()
          getTables()
          setOpenDescriptionModal(false)
        } catch (error) {
          console.log(error)
        }
      }

      const confirmClose = (action, card)=>{       
        setAction(action)
        setCardClose(card)
        setOpenConfirmClose(true)
      }

      const closeAccount = async ()=>{

          //navigate(`/printer/${action}/`,{replace:true, state:{id:cardClose}})
        //   if(action === 1){
        //       closeTable()
        //   }else{
        //       closeCard(cardClose)
        //   }
        try {
            if(action === 'table'){
                const body = {
                    data:[]
                  }
                const {url, options} = PUT_CLOSE_TABLE(token, cardClose, body)
                const response = await fetch(url, options)
                if(!response.ok) throw new Error(`Error: ${response.statusText}`)
                const {values} = await response.json()
                setData(values)
            }else if(action === 'card'){
                const {url, options} = PUT_CLOSE_ORDER(token, cardClose)
                const response = await fetch(url, options)
                if(!response.ok) throw new Error(`Error: ${response.statusText}`)
                const {values} = await response.json()
                setData(values)
            }
            getData()
            getTables()
            setPrintering(true)
            handlePrintClose()
        } catch (error) {
            console.log(error)
        }finally{
           
        }
      }

      const openModalAddAttributes=()=>{
        setOpenNewOrder(true)
        setOpenFather(false)
     }

     const showDetailOrder=(id)=>{
         let ido = 0
         let idc = 0
         let idcc =0
         table.cards.map(card=>{            
            const idx = card.orders.findIndex((ord)=>ord.id===id)
            if(idx >=0){
                ido = idx
                idc = idcc
            }
            idcc += 1
         })
         setOrderToShow(table.cards[idc].orders[ido])
         setDialogOrderToShow(true)
         updateSended(id)
         //console.log("CLS",table.cards[idc].orders[ido])
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });
    const handlePrintClose = useReactToPrint({
        content: () => componentRefClose.current,
      });
      
      const updateSended =async (id)=>{
          try {
              const {url, options} = UPDATE_STATUS_ORDER(token, id, {sended:true})
              const response = await fetch(url, options)
              if (!response.ok) throw new Error(response.statusText)
              getData()
          } catch (error) {
              console.log("CLS",error)
          }
      }
    React.useEffect(()=>{
        getData()
    },[])
    return(
        <>

            {printering && <div ref={componentRefClose}> 
                <div>
                    {action === 'table' && <PrinterTable data={data} ref={componentRefClose}/>}
                    {action === 'card' && <PrinterCard data={data}  ref={componentRefClose}/>}        
                </div>
            </div>}
        <Dialog open={dialogOrderToShow} onClose={evt => {
                console.log(evt.detail.action);
                setDialogOrderToShow(false);
                }}
                onClosed={evt => console.log(evt.detail.action)} >
                    { orderToShow && 
                    <div ref={componentRef}>
                        <PrinterOrder data={orderToShow}/>
                    </div>}
                    <DialogActions>
          <DialogButton action="close">Fechar</DialogButton>
          <DialogButton action="accept" isDefaultAction onClick={handlePrint}>
            Imprimir
          </DialogButton>
        </DialogActions>
            </Dialog>

<Dialog open={openConfirmClose} onClose={evt => {
                console.log(evt.detail.action);
                setOpenConfirmClose(false);
                }}
                onClosed={evt => console.log(evt.detail.action)} >
                     <DialogTitle>Deseja Realmente fechar a conta?</DialogTitle>
                    <DialogContent>
                    
                    </DialogContent>
                    <DialogActions>
          <DialogButton action="close">Não</DialogButton>
          <DialogButton action="accept" isDefaultAction onClick={closeAccount}>
            Sim
          </DialogButton>
        </DialogActions>
            </Dialog>

            <Dialog open={openDescriptionModal} onClose={evt => {
                console.log(evt.detail.action);
                setOpenDescriptionModal(false);
                }}
                onClosed={evt => console.log(evt.detail.action)} >
                     <DialogTitle>Remover item da conta</DialogTitle>
                    <DialogContent>
                    <DataTable>
                                    <DataTableContent>
                                        <DataTableBody>
                       {contentDescriptionModal && <div>
                           { contentDescriptionModal.map(produto=>{
                            return(
                               
                                    <DataTableRow>
                                        <DataTableHeadCell>
                                        {produto.id}
                                        </DataTableHeadCell>
                                        <DataTableHeadCell>
                                        {produto.product_name}
                                        </DataTableHeadCell>
                                        <DataTableHeadCell>
                                        <Button onClick={()=>updateStatus(produto.id, "Cancelado")} icon="delete"/>
                                        </DataTableHeadCell>
                                    </DataTableRow>
                                        
                            )
            })}
                           </div>}
                           </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                    </DialogContent>
            </Dialog>

          { loadind && <div className="loadingDialog" ><CircularProgress size={125} /></div>}  
        {!loadind && <div className="accountContainer">
            <div className={"PageTitle"}> 
                <h1><Typography use="headline6">Conta da  Mesa {table.number}</Typography></h1>
            </div> 
            <Grid>
                <GridRow>
                    <GridCell span={12}>
                        <GridRow className={"elementsTopTable"}>
                            <GridCell span={6}>
                            <Button  onClick={openModalAddAttributes} label="Adcionar pedido" className={"BtnDefaultTmenu"} icon="add"/>
                            </GridCell>
                            <GridCell span={6}>
                            <Typography use="headline5">Garçom: {table.waiter?table.waiter.name:"Sem Garçom"}</Typography>
                            </GridCell>
                            
                        </GridRow>
                        <GridRow>
                            <GridCell className={"marginTable"}>
                            <DataTable className={"TabelaProdutos"}>
                                    <DataTableContent>
                                        <DataTableHead>
                                            <DataTableRow>
                                                <DataTableHeadCell>Cliente</DataTableHeadCell>
                                                <DataTableHeadCell>Itens </DataTableHeadCell>
                                                <DataTableHeadCell>Valor</DataTableHeadCell>
                                            </DataTableRow>
                                        </DataTableHead>
                                         { table.cards.length > 0 && 
                                         <DataTableBody>
                                             {
                                                 table.cards.map(card=>{
                                                    const prods = addItem(card.orders)
                                                    return(
                                                        <DataTableRow key={card.id} className={"rowTable"}>
                                                            <DataTableCell>
                                                            <Tooltip content={card.user.name}>
                                                                <div>{`${card.user.name.slice(0,8)}`}</div>
                                                            </Tooltip>    
                                                            </DataTableCell>
                                                            <DataTableCell>
                                                                {<DataTable>
                                                                    <DataTableContent>
                                                                        <DataTableHead>
                                                                            <DataTableRow>
                                                                                <DataTableCell>Quant</DataTableCell>
                                                                                <DataTableCell>Descrição</DataTableCell>
                                                                                <DataTableCell>Preço</DataTableCell>
                                                                            </DataTableRow>
                                                                        </DataTableHead>
                                                                        <DataTableBody>
                                                                    {prods.map(item=>{   
                                                                        return(
                                                                            <DataTableRow key={item.id}>
                                                                                <DataTableCell>{item.quantity}</DataTableCell>
                                                                                <DataTableCell>
                                                                                <Tooltip content="Detalhes do pedido">
                                                                                    <div onClick={()=>showDetailOrder(item.id)} className="descriptionOrder">{`${item.name.slice(0,10)}`}</div>
                                                                                </Tooltip>    
                                                                                    { !item.sended && <Badge inset="0.4rem" label="Atender"/>}
                                                                                
                                                                                    </DataTableCell>
                                                                                <DataTableCell>{addComman(item.value)}</DataTableCell>
                                                                                <DataTableCell><Tooltip content="Adicionar mais um item"><Button icon="add" onClick={()=>addItemToCard(item, card.user_id)}/></Tooltip></DataTableCell>
                                                                                <DataTableCell><Tooltip content="Remover item"><Button icon="delete" onClick={()=>openDescription(item.produtos)}/></Tooltip></DataTableCell>
                                                                            </DataTableRow>        
                                                                       )
                                                                    })
                                                                    }
                                                                    </DataTableBody>
                                                                    </DataTableContent>
                                                                 </DataTable>}
                                                            </DataTableCell>
                                                            <DataTableCell>
                                                            <Tooltip content={`Total do ${card.user.name.slice(0,8)}`}><div>{addComman(card.value)}</div></Tooltip>
                                                            </DataTableCell> 
                                                                    
                                                            <Tooltip content={`Fechar conta do ${card.user.name.slice(0,8)}`}><Button  onClick={()=>confirmClose('card', card.id)} label="Fechar" className={"BtnDefaultTmenu"}/></Tooltip>
                                                          
                                                        </DataTableRow>
                                                        

                                                    )
                                                 })
                                             }
                                          </DataTableBody>}
                                    </DataTableContent>
                                </DataTable>      
                            </GridCell>
                            
                        </GridRow>
                    
                    </GridCell>  
                    <GridCell span={6}>
                            <p>Total da mesa: {valorFormatado(total)}</p>
                    </GridCell>  
                    <GridCell span={6}>
                    <Tooltip content="Fechar conta da mesa"><Button label={table.status?"Fechar conta da mesa":"Conta fechada"} disabled={table.statu} onClick={()=>confirmClose('table', table.id)} className={"BtnDefaultTmenu"}/></Tooltip>
                    </GridCell>  
                </GridRow>    
            </Grid>  
            </div>}
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
        </>
    )
}

export default TableAccount