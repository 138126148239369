import React from "react";
import { Grid,
  GridCell,
  Typography,
  Card,
  Badge,
  Button,
  GridRow,
  Icon,
  SimpleMenu,
  IconButton,
  MenuItem,
  Dialog,
  Select,
  TextField,
  DialogActions,
  DialogButton,
  DialogTitle,
  DialogContent,
  CircularProgress,
  SimpleDialog
 } from "rmwc";
import MainNav from "../../MainNav";
import Modal from '../Modal/Modal'
import CreateOrder from '../Orders/CreateOrder'
import { UserContext } from '../../UserContext';
import "./Tables.css";
import {GET_TABLES, GET_COMPANY, PUT_ADD_WAITER_TO_TABLE, 
  PUT_UPDATE_TABLE, GET_LAST_ORDERS,
  PUT_CLOSE_ORDER, PUT_CLOSE_TABLE} from '../../Api'
import Ws from '@adonisjs/websocket-client'
import DialogCenter from "../Modal/DialogCenter";
import TableAccount from '../Tables/TableAccount'
const TablesIndex = () => {

  //const url = 'wss://api.tmenu.com.br'
  const url = 'ws://localhost:3333'
  const [tables, setTables] = React.useState([])
  const [company, setCompany] = React.useState({})
  const [waiters, setWaiters] = React.useState([])
  const [loadind, setLoading] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const [openWaiter, setOpenWaiter] = React.useState(false)
  const [openOrder, setOpenorder] = React.useState(false)
  const [opneBilling, setOpenbilling] = React.useState(false)
  const [paginate, setPaginate] = React.useState({total:0, perPage:5, page:1, lastpage:0})
  const { tableid, setTableId, setNewOrder, isManager } = React.useContext(UserContext);
  const [closeTableId, setCloseTableId] = React.useState(0)
  const [message, setMessage] = React.useState('')
  const[lastOrder, setOrder] = React.useState(0)
  const token = window.localStorage.getItem('token') 
  const ws = Ws(url).withApiToken(token).connect()
  const ws1 = Ws(url).withApiToken(token).connect()
  const order = ws.subscribe('notifications')
  const account = ws1.subscribe('account')

  const [renderToPortal, setRenderToPortal] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false)

  function getWaiters(waiters){
    const wt = {}
    waiters.map(waiter=>{
      wt[waiter.id] = waiter.name      
    })
    return wt
  }
  
  const getCompany = async () =>{
    const {url, options} = GET_COMPANY(token)
    const response = await fetch(url, options)
    const {company} = await response.json()
    setCompany(company)
    setWaiters(getWaiters(company.waiters))
  }

  const getTables = async ()=>{
    try {     
      const {url, options} = GET_TABLES(token, paginate)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {tables} = await response.json()
      setTables(tables.data)
      setPaginate({total:tables.total, perPage:tables.perPage, page:tables.page, lastpage:tables.lastPage})
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  const setWaiter = async event =>{
    event.preventDefault()  
    try {
      const {url, options} = PUT_ADD_WAITER_TO_TABLE(token, tableid, event.target.waiter.value)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      getTables()      
    } catch (error) {
      console.log(error)
    }
  }

const setTable = (table_id) =>{
  setTableId(table_id)
  setOpenWaiter(true)
}
  order.on('new:order', (response)=>{
    console.log(response)
    getTables()
    setNewOrder(3)
  })
  account.on('new:card', (response)=>{
    getTables()
  })
const openTable = async event =>{
  event.preventDefault()
  try {
    const body ={
      status:true
    }
    const {url, options} = PUT_UPDATE_TABLE(token,body, event.target.id)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(`Error: ${response.statusText}`)
    const {table} = await response.json()
    setMessage(`Mesa ${table.number} aberta`)
    getTables()
  } catch (error) {
    console.log(error)
  }
}


  const getLastOrders = async event =>{
    event.preventDefault()
    try {
      const {url, options} =GET_LAST_ORDERS(token, event.target.id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      const {order} = await response.json()
      setOrder(order)
      setOpenorder(true)
    } catch (error) {
      console.log(error)
    }
  }

  const closeTable = async event =>{
    event.preventDefault()
    try {
      const body = {
        data:[]
      }
      const {url, options} = PUT_CLOSE_TABLE(token, closeTableId, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(`Error: ${response.statusText}`)
      alert('Conta fechada com sucesso!')
      getTables()
    } catch (error) {
      console.log(error)
    }
  }

  const openModalBilling = async event =>{
    event.preventDefault()
    try {
      setCloseTableId(event.target.id)   
      setOpenbilling(true)  
    } catch (error) {
      console.log(error)
    }
    
  }

 const setAsking = async (id) =>{
   try {
     const body ={
      asking:false,
      last_order:0
     }
     const {url, options} = PUT_UPDATE_TABLE(token, body, id)
     const response = await fetch(url, options)
     if(!response.ok) throw new Error(response.statusText)
     getTables()
   } catch (error) {
     console.log(error)
   }
 }

 const openModalAddAttributes=(table_id)=>{
    setTableId(table_id)
    setOpen(true)
 }

 const openDialogAccount=(table_id)=>{
   setTableId(table_id)
   setOpenDialog(true)

 }

  React.useEffect(()=>{
    getTables()
    getCompany()
  },[])
  
  return (
    <>          
    <MainNav/>
    { loadind && <div className="loading" ><CircularProgress size={125} /></div>}

    <Modal open={open} setOpen={setOpen} Content={CreateOrder}/>
    <DialogCenter open={openDialog} setOpen={setOpenDialog} Content={TableAccount}/>
    <Dialog open={opneBilling} onClose={evt => {
      console.log(evt.detail.action);
      setOpenbilling(false);
    }}
  onClosed={evt => console.log(evt.detail.action)} >
      <DialogTitle>Fechar conta da mesa</DialogTitle>
          <form onSubmit={closeTable} >
          <DialogContent>     
              loijnnns
          </DialogContent>
          <DialogActions>
            <DialogButton className={"BtnDefaultTmenu"} action="accept" isDefaultAction>
              Fechar!
            </DialogButton>
          </DialogActions>
          </form>
        </Dialog>

    <Dialog open={openWaiter} onClose={evt => {
      console.log(evt.detail.action);
      setOpenWaiter(false);
    }}
  onClosed={evt => console.log(evt.detail.action)}>
      <DialogTitle>Selecione o garçom</DialogTitle>
      <form onSubmit={setWaiter}>
      <DialogContent>     
          {company && <Select label="Selecione o garçom" name="waiter" options={waiters}/>}
      </DialogContent>
      <DialogActions>
        <DialogButton className={"BtnDefaultTmenu"} action="accept" isDefaultAction>
          Definir!
        </DialogButton>
      </DialogActions>
      </form>
    </Dialog>

    <Dialog open={openOrder} onClose={evt => {
      //console.log(evt.detail.action);
      setAsking(evt.detail.action)
      setOpenorder(false);
    }}
  onClosed={evt => console.log(evt.detail.action)}>
      <DialogTitle>Últimos pedidos</DialogTitle>
      <DialogContent>     
         { lastOrder &&
           lastOrder.itens.map(item=>{
             return(
               <div>{item.quantity} {item.product_name} </div>
             )
           })
         }
      </DialogContent>
      <DialogActions>
        <DialogButton className={"BtnDefaultTmenu"} action={lastOrder.table_id} isDefaultAction={false} id={lastOrder.table_id} onClick={setAsking}>
          ok
        </DialogButton>
      </DialogActions>
    </Dialog>

     { !loadind && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Mesas</Typography></h1>             
          </div>
          <Grid className={"CardsMesas"} z={1}>
            {tables && tables.map(table=>{
              return(
                <GridCell span={3}>
                <Card>
                  <Grid>  
                  
                      <GridRow>
                        <GridCell span={6}>
                          { table.status && <Badge className={"TmenuSuccess"} align="inline" label="Em atendimento" />} 
                          { !table.status && <Badge className={"TmenuDanger"} align="inline" label="Fechada" />} 
                        </GridCell>
                        <GridCell span={8}>
                          <SimpleMenu handle={<Button style={{color: "black"}} label="Ações" icon="settings" />}>
                                  { table.status && <MenuItem onClick={()=>openModalAddAttributes(table.id)}><Icon icon="add_circle_outline" /> Incluir Pedido</MenuItem>}
                                  { table.status &&<MenuItem onClick={() => {setRenderToPortal(true);  openDialogAccount(table.id);}}><Icon icon="visibility" /> 
                                    Ver Extrato
                                  </MenuItem>}
                                  { table.status && <MenuItem onClick={openModalBilling} id={table.id}><Icon icon="account_balance_wallet" /> Fechar Conta</MenuItem>}
                                  {!table.status && <MenuItem onClick={openTable} id={table.id}><Icon icon="add_to_queue" /> Abrir Mesa</MenuItem>}
                                  { table.status && <MenuItem onClick={()=>setTable(table.id)}><Icon icon="switch_account" /> Definir Garçom</MenuItem>}
                          </SimpleMenu>
                        </GridCell>
                      </GridRow>
                  </Grid>                  
                  
                  <span className={"CardsMesasTitle"}>MESA</span>
                  <span className={"CardsMesasValue"}>{table.number}</span>
                  { table.waiter && <span className={"AttendedBy"}>Mesa atendida por: <span className={"strong"}>{table.waiter.name}</span></span>}
                  { !table.waiter && <span className={"AttendedBy"}>Sem garçom definido</span>}
                  { !table.asking && !table.calling &&
                  <a >
                    <div className={`CardsMesasAlertZone`}>
                        Nenhum novo pedido
                    </div>
                  </a>}
                  { table.calling &&
                     <a >
                     <div className={"CardsMesasAlertZone AlertZoneRequestTheBill"} onClick={openModalBilling}>                        
                       <Icon icon="notification_important" />
                       <Typography use="overline" className={"strong"} id={table.id} > Pedindo a conta!</Typography>
                     </div>
                   </a>
                  }
                  { table.asking && !table.calling &&
                    <a>                  
                    <div className={"CardsMesasAlertZone AlertZoneRequestNewOrder"}  onClick={getLastOrders}>                        
                      <Badge align="inline" label={table.orders} style={{ background: '#2196f3' }}/>
                      <Typography use="overline" className={"strong"} id={table.last_order}> Novo(s) pedido(s)!</Typography>
                    </div>
                    </a>
                  }

                </Card>
              </GridCell>
              )
            })
              
            } 
            </Grid> 
        </div>   }
      
    </>
  );
};

export default TablesIndex;
