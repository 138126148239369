import React from 'react';
import {valorFormatado, addComman, calcRates, convertDate} from '../../Utils'
import './PrintOrder.css'

export const PrinterOrder =React.forwardRef(( {data}, props, ref)=>{
    return(
        <div  className="containerPrinterOrder">
            {
                data &&
                <div>
                    <div className="tableInfo"><strong>{`Mesa ${data.table} - ${convertDate(data.created_at)}`}</strong></div>
                    <div className="orderNumber">{`Pedido número ${data.id}`}</div>
                    <div className='descriptionItemOrder'>
                    {data.quantity} {data.product_name} 
                    </div>
                    <div>
                        {
                            data.attributes.length >=1 && data.attributes.map(attr=>{
                                return(
                                    <div>
                                    <div className='attributeName'>{attr.attribute_name}</div>
                                    {
                                       data.valuesAttribute && data.valuesAttribute.length >=1 && data.valuesAttribute.map(value=>{
                                            return(
                                               <div className='value'>
                                                {value.order_attribute_id === attr.id && `- ${value.name_value}`}
                                               </div>         
                                                  
                                            )
                                        })
                                    }
                                    {
                                        attr.values && attr.values.length >=1 && attr.values.map(value=>{
                                            return(
                                               <div className='value'>
                                                {value.order_attribute_id === attr.id && `- ${value.name_value}`}
                                               </div>         
                                                  
                                            )
                                        })
                                    }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='totalOrder'>{`Total pedido: ${valorFormatado(data.value)}`}</div>
                </div>
            }
        </div>
    )
})