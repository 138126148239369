import {serialJson} from './Utils'
import {config} from './ApiUrls'
export const API_URL = config.url.API_URL

// AQUI DEVE SER PRÉ-CONFIGURADO TODOS OS TIPOS DE REQUISIÇÕES QUE A API TRABALHA 
//export const API_URL = 'https://api.tmenu.com.br/v1/';
//export const API_URL = 'http://localhost:3333/v1/';
//USERS

export function LOGIN(body) {
    return {
      url: API_URL + 'auth/login',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    };
  }

export function GET_USER(token){
  return{
    url:API_URL+"auth/getuser",
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
       }
    }
  }
}

export function GET_VALIDATE_TOKEN(token){
return{
  url:`${API_URL}auth/validate`,
  options:{
    method:'GET',
    headers:{
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
     }
  }
}
}

export function UPDATE_USER(token, id, body){
  return {
    url: `${API_URL}manager/profile/${id}`,
    options:{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

//MANAGER

export function POST_MANAGER(token, body){
  return{
    url:API_URL+"manager/manager",
    options:{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

export function PUT_MANAGER(token, id, body){
  let seen = []
  return{
    url:`${API_URL}manager/manager/${id}`,
    options:{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body) 
      // body:JSON.stringify(body, function(key, val){
      //   if (typeof val == "object") {
      //     if (seen.indexOf(val) >= 0) {
      //         return
      //     }
      //     seen.push(val);
      // }
      //return val;
    //})
    }
  }
}

export function DELETE_MANAGER(token, id){
  return{
    url:`${API_URL}manager/manager/${id}`,
    options:{
      method:'DELETE',
      headers:{
       'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`
      }
    }
  }
}

//WAITER

export function POST_WAITER(token, body){
  return{
    url:API_URL+"manager/waiter",
    options:{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

export function PUT_WAITER(token, id, body){
  let seen = []
  return{
    url:`${API_URL}manager/waiter/${id}`,
    options:{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body, function(key, val){
        if (typeof val == "object") {
          if (seen.indexOf(val) >= 0) {
              return
          }
          seen.push(val);
      }
      return val;
    })
    }
  }
}

export function DELETE_WAITER(token, id){
  return{
    url:`${API_URL}manager/waiter/${id}`,
    options:{
      method:'DELETE',
      headers:{
       'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`
      }
    }
  }
}

//PRINTERS
export function GET_PRINTERS(token){
 let url = `${API_URL}manager/printer`
 return{
   url:url,
   options:{
     method:'GET',
     headers:{
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
     }
   }
 }
}

export function POST_PRINTER(token, body){
  return{
    url: API_URL+'manager/printer',
    options:{
      method: 'POST',
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(body)
    }
  }
}
export function DELETE_PRINTER(token, id) {
  return{
    url:`${API_URL}manager/printer/${id}`,
    options:{
      method: 'DELETE',
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
    }
  }
}

export function UPDATE_PRINTER(token, id, body){
  return{
    url:`${API_URL}manager/printer/${id}`,
    options:{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

//PRODUCTS
export function GET_PRODUCTS(token, paginate, search=null){
  const namex = /[a-zA-z]+/g
  const categoryx = /\d+/g
  const statusx = /&status/g
  let url = `${API_URL}manager/product?page=${paginate.page}&limit=${paginate.perPage}`
  if(search){
    if(search.match(statusx)){
      url+=search
    }else if(search.match(namex)){
      url+=`&name=${search}`
      console.log('Aqui é nome')
    }
    else if(search.match(categoryx)){
      console.log(search)
      url+=`&category_id=${search}`
    }else{
      console.log('Aqui é code')
      url+=`&code=${search}`
    }
  }
  return{
    url: url,
   options:{
    method:'GET',
    headers:{
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    }
  }
}

export function POST_PRODUCT(token, body){
  return{
    url:API_URL+'manager/product',
    options:{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}


export function PUT_PRODUCT(token, id, body){
  return{
    url:`${API_URL}manager/product/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

export function PUT_PRODUCT_ATTRIBUTE(token, product_id, attribute_id){
  return{
    url:`${API_URL}manager/product/${product_id}/attribute/${attribute_id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export function DELETE_PRODUCT_ATTRIBUTE(token, product_id, attribute_id){
  return{
    url:`${API_URL}manager/product/${product_id}/attribute/${attribute_id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export function GET_PRODUCT(token, id){
  return{
    url:`${API_URL}manager/product/${id}`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export function DELETE_PRODUCT(token, id){
  return{
    url:`${API_URL}manager/product/${id}`,
    options:{
      method: 'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}


//ATTRIBUTES

export function GET_ATTRIBUTES(token, paginate=null, search = null){
  let url = `${API_URL}manager/attribute`
  if(paginate){
    url += `?page=${paginate.page}&limit=${paginate.perPage}`}
  if(search){
    url+=`&search=${search}`
  }
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }  
  }
}

export function POST_ATTRIBUTE(token, body){
  return{
    url:`${API_URL}manager/attribute`,
    options:{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }, 
      body:JSON.stringify(body)
    }  
  }
}

export function POST_ATTRIBUTE_VALUE(token, body){
  return{
    url:`${API_URL}manager/value-attribute`,
    options:{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }, 
      body:JSON.stringify(body)
    }  
  }
}

export function DELETE_ATTRIBUTE(token, id){
  return{
    url:`${API_URL}manager/attribute/${id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }  
  }
}

export function DELETE_ATTRIBUTE_VALUE(token, id){
  return{
    url:`${API_URL}manager/value-attribute/${id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }  
  }
}


export function PUT_ATTRIBUTE(token, id, body){
  return{
    url:`${API_URL}manager/attribute/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }, 
      body:JSON.stringify(body)
    }  
  }
}

export function GET_ATTRIBUTE(token, id){
  return{
    url:`${API_URL}manager/attribute/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }  
  }
}

//CATEGORIES
export function GET_CATEGORIES(token, paginate, search=null){
  const namex = /[a-zA-z]+/g
  let url = `${API_URL}manager/category?page=${paginate.page}&limit=${paginate.perPage}`
  if(search){
    if(search.match(namex)){
      url+=`&name=${search}`
    }
  }
  return{
    url:url,
    options:{
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}


export function POST_CATEGORY(token, body){
  return{
    url:API_URL+"manager/category",
    options:{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

export function DELETE_CATEGORY(token, id){
  return{
    url:`${API_URL}manager/category/${id}`,
    options:{
      method: 'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
  }
}
}

export function UPDATE_CATEGORY(token, id, body){
  return{
    url:`${API_URL}manager/category/${id}`,
    options:{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}
//TABLES
export function GET_TABLES(token, paginate, search=null){
  let url = `${API_URL}manager/table?page=${paginate.page}&limit=${paginate.perPage}`
  return{
    url:url,
    options:{
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export function PUT_ADD_WAITER_TO_TABLE(token, table_id, user_id){
  return{
    url:`${API_URL}manager/table/${table_id}/user/${user_id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export function PUT_UPDATE_TABLE(token, body, id){

  const url = `${API_URL}manager/table/${id}`
  return{
    url:url,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
  }
}

export function DELETE_TABLE(token, id){
  return{
    url:`${API_URL}manager/table/${id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export function PUT_CLOSE_TABLE(token, id, body){

  return{
    url:`${API_URL}manager/close-table/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      },
      body:JSON.stringify(body)
    }
  }
}

export function GET_TABLE(token, id){
  return{
    url:`${API_URL}manager/table/${id}`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  }
  }
export function POST_TABLE(token, body){
  return{
    url:`${API_URL}manager/table`,
    options:{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(body)
    }
  }
}

//COMPANIES
export function GET_COMPANY(token){
  return{
    url:API_URL+'manager/company',
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

export function PUT_COMPANY(token, body, id){
  return{
    url:`${API_URL}manager/company/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      },
      body:JSON.stringify(body)
    }
  }
}

export function POST_ADD_IMAGE_COMPANY(token,formData){
  return{
    url:`${API_URL}manager/image-company`,
    options:{
      method:'POST',
      headers:{
        Authorization: `Bearer ${token}`  
      },
      body:formData
    }
  }
}

export function DELETE_IMAGE_COMPANY(token, id){
  return {
    url:`${API_URL}manager/image-company/${id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}



//ORDERS

export function GET_LAST_ORDERS(token, id){
  return{
    url:`${API_URL}manager/order-card/${id}`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

export function GET_ORDERS(token, paginate,search=null){
  let url = `${API_URL}manager/order?page=${paginate.page}&limit=${paginate.perPage}`
  //const tablex = /&table/g
  //const statusx = /&status/g
  //const codex = /&code/g
  if(search){
    url+=search
  }
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

export function GET_ORDER(token, id){
  return{
    url:`${API_URL}manager/order/${id}`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

export function POST_CREATE_ORDER(token, body){
  let seen = []
  return{
    url:API_URL+'manager/order',
    options:{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      },
      body: JSON.stringify(body, function(key, val){
          if (typeof val == "object") {
            if (seen.indexOf(val) >= 0) {
                return
            }
            seen.push(val);
        }
        return val;
      })
    }
  }
}


export function UPDATE_STATUS_ORDER(token, id, body){
  return{
    url: `${API_URL}manager/order/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      },
      body:JSON.stringify(body)
  }
}
}
//CARDS

export function PUT_CLOSE_ORDER(token, id){
  return{
    url:`${API_URL}manager/card/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

export function GET_CARDS(token){
  return{
    url:API_URL+"manager/card",
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

//IMAGES

export function POST_ADD_IMAGE_PRODUCT(token,formData){
  return{
    url:`${API_URL}manager/image-product`,
    options:{
      method:'POST',
      headers:{
        //'Content-Type': ' multipart/form-data',
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      },
      body:formData
    }
  }
}

export function DELETE_IMAGE(token, id){
  return {
    url:`${API_URL}manager/image-product/${id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

//CLIENTS

export function GET_CLIENTS(token, paginate, search=null){
  let url = `${API_URL}manager/client?page=${paginate.page}&limit=${paginate.perPage}`
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

//BILLINGS
export function GET_BILLINGS(token, paginate, search=null){
  let url = `${API_URL}manager/billing?page=${paginate.page}&limit=${paginate.perPage}`
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

export function GET_BILLING(token, id){
  return{
    url:`${API_URL}manager/billing/${id}`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

//PROFILE
export function GET_PROFILE(token){
  return{
    url:`${API_URL}manager/profile`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

//SETTINGS
export function PUT_CONFIGURATION(token, id, body){
  return{
    url:`${API_URL}manager/configuration/${id}`,
    options:{
      method:'PUT',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      },
      body:JSON.stringify(body)
    }
  }
}

export function GET_CONFIGURATION(token){
  return{
    url:`${API_URL}manager/configuration`,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }
}

//NOTIFICATIONS

export function DELETE_NOTIFICATION(token, id){
  return{
    url:`${API_URL}manager/notification/${id}`,
    options:{
      method:'DELETE',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
  }

}
///////REPORTS////////

export function CARDS_TO_MONTH(token){
  return{
    url:API_URL+"manager/report/card-month",
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`  
      }
    }
    }
  }
  export function PRODUCTS_TO_MONTH(token){
    return{
      url:API_URL+"manager/report/product-month",
      options:{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`  
        }
      }
      }
    }


export function GET_REPORT_TABLE(token, search=null){
  let url = `${API_URL}manager/report/table-cards?status=false`
  if(search.start && search.end){
    url += `&start=${search.start}&end=${search.end}`
  }

  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` 
      }
    }
  }
}

export function GET_REPORT_CARDS(token, search=null){
  let url = `${API_URL}manager/report/cards`
  if(search.start && search.end){
    url += `?start=${search.start}&end=${search.end}`
  }
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` 
      }
    }
  }
}
export function GET_CARDS_BY_DAY(token, search=null){
  let url = `${API_URL}manager/report/cards-day`
  if(search.start && search.end){
    url += `?start=${search.start}&end=${search.end}`
  }
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` 
      }
    }
  }
}

export function GET_RATES(token, search=null){
  let url = `${API_URL}manager/rate`
  if(search.start && search.end){
    url += `?start=${search.start}&end=${search.end}`
  }
  return{
    url:url,
    options:{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` 
      }
    }
  }
}

