import React from "react";
import {  Grid, GridCell, GridRow,
  Typography,
  Button,
  TextField,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  SimpleMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
  Select,
  Badge,
  IconButton,
  Icon,
  Snackbar,
  SnackbarAction,
  CircularProgress } from "rmwc";
import { DELETE_WAITER, GET_COMPANY, POST_WAITER, PUT_WAITER, POST_MANAGER, PUT_MANAGER, DELETE_MANAGER } from "../../Api";
import MainNav from "../../MainNav";
import useForm from '../../Hooks/UseForm'
const UsersIndex = () => {
  const [loading, setLoading] = React.useState(true)
const token = window.localStorage.getItem('token')
const [company, setCompany] = React.useState([])
const [openAddWaiter, setOpenAddWaiter] = React.useState(false)
const [openWaiterModal, setOpenWaiterModal] = React.useState(false)
const [openSnack, setOpenSnack] = React.useState(false)
const [openAddManager, setOpenAddManager] = React.useState(false)
const [openEditManagerModal, setOpenEditManagerModal]= React.useState(false)
const [openDeleteManagerModal, setOpenDeleteManagerModal]= React.useState(false)
const [message, setMessage] = React.useState('')
const [user, setUser] = React.useState(null)
const [userValue, setUserValue] = React.useState("Garçom")
const [name, setName] = React.useState('')
const [email, setEmail] = React.useState('')
const [cpf, setCpf] = React.useState('')
const [phone, setPhone] = React.useState('')
const [password, setPassword] = React.useState('')
const [openDelete, setOpenDelete] = React.useState(false);
const [error, setError]= React.useState('')
const [isDisabled, setIsDisabled] = React.useState(true)
const userName = useForm()
const userEmail = useForm()
const userCpf = useForm()
const userPhone = useForm()
const userPassword = useForm()
const userPasswordConfirm = useForm()
const getCompany = async ()=>{
  try {
    const {url, options} = GET_COMPANY(token)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    const {company} = await response.json()
    setCompany(company)
  } catch (error) {
    console.log(error)
  }finally{
    setLoading(false)
  }
}
const habilitAddWaiter =()=>{
 if(userName.value && userEmail.value && userPassword.value && userPasswordConfirm.value){
  if(userPasswordConfirm.value.length > 5 && isDisabled)
  setIsDisabled(false)
  }else if(userPasswordConfirm.value.length < 5 && !isDisabled){
    setIsDisabled(true)
  }
} 
habilitAddWaiter()

const handleAddWaiter = async () =>{
  
  try {
    if(userName.value && userEmail.value && userPassword.value && userPasswordConfirm.value){
      const body ={
        name: userName.value,
        email:userEmail.value,
        phone: userPhone.value,
        cpf:userCpf.value,
        password: userPassword.value
      }
    if(userValue === "Gerente"){
      const  {url, options} = POST_MANAGER(token, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {user} = await response.json()
      setMessage(`O Gerente ${user.name} foi cadastrado com sucesso!`)
    }else{
      const {url, options} = POST_WAITER(token, body)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {user} = await response.json()
      setMessage(`O Garçom ${user.name} foi cadastrado com sucesso!`)
    }
   
    userName.setValue('')
    userEmail.setValue('')
    userPhone.setValue('')
    userCpf.setValue('')
    userPassword.setValue('')
    userPasswordConfirm.setValue('')
    setOpenSnack(true)
    getCompany()
    }
  } catch (error) {
    console.log("CLS",error)
  }
} 

const setOpenEditWaiter=(id) =>{
  const idx = company.waiters.findIndex((waiter)=>waiter.id === id)
  setUser(company.waiters[idx])
  setOpenWaiterModal(true)
  setName(company.waiters[idx].name)
  setEmail(company.waiters[idx].email)
  setPhone(company.waiters[idx].phone)
  setCpf(company.waiters[idx].cpf)
} 

const handleEditWaiter = async event=>{
  event.preventDefault()
  try {
    const body ={data:{
      name: name,
      email:email,
      phone: phone,
      cpf:cpf
    }
    }
    const {url, options} = PUT_WAITER(token, user.id, body)
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(response.statusText)
    const waiter = await response.json()
    setMessage(`O garçom ${waiter.user.name} foi atualizado!`)
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    
  }
}

const toDelete = async (id)=>{
  try {
    const {url, options} = DELETE_WAITER(token, id)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    setMessage("Usuário excluído com sucesso!")
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    console.log(error)
  }
}

const deleteWaiter= async (id)=>{
  try {
    const idx = company.waiters.findIndex((waiter)=>waiter.id === id)
    setUser(company.waiters[idx])
    setOpenDelete(true)
  } catch (error) {
    console.log(error)
  }
}
//MANAGERS
const handleAddManager = async event=>{
  event.preventDefault()
  try {
    const body ={
      name: event.target.name.value,
      email:event.target.email.value,
      phone: event.target.phone.value,
      cpf:event.target.cpf.value,
      password: event.target.password.value
    }
    const {url, options} = POST_MANAGER(token, body)
    const response = await fetch(url, options)
    if(!response.ok) throw new Error(response.statusText)
    const {user} = await response.json()
    setMessage(`O Gerente ${user.name} foi cadastrado com sucesso!`)
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    console.log(error)
  }
}

const setOpenEditManager=(id) =>{
  const idx = company.managers.findIndex((manager)=>manager.id === id)
  setUser(company.managers[idx])
  setOpenEditManagerModal(true)
  setName(company.managers[idx].name)
  setEmail(company.managers[idx].email)
  setPhone(company.managers[idx].phone)
  setCpf(company.managers[idx].cpf)
} 

const handleEditManager = async event=>{
  event.preventDefault()
  try {
    const body ={
      name: name,
      email:email,
      phone: phone,
      cpf:cpf
    
    }
    const {url, options} = PUT_MANAGER(token, user.id, body)
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(response.statusText)
    const waiter = await response.json()
    setMessage(`O gerente ${waiter.user.name} foi atualizado!`)
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    
  }
}

const toDeleteManager = async (id)=>{
  try {
    const {url, options} = DELETE_MANAGER(token, id)
    const response = await fetch(url, options)
    console.log(url)
    if(!response.ok) throw new Error(response.statusText)
    setMessage("Usuário excluído com sucesso!")
    setOpenSnack(true)
    getCompany()
  } catch (error) {
    console.log(error)
  }
}

const deleteManager= async (id)=>{
  try {
    const idx = company.managers.findIndex((manager)=>manager.id === id)
    setUser(company.managers[idx])
    setOpenDeleteManagerModal(true)
  } catch (error) {
    console.log(error)
  }
}

React.useEffect(()=>{
  getCompany()
},[])
  return (
    <>   
      <Dialog
        open={openDelete}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenDelete(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Excluir {user?user.name:""}?</DialogTitle>
        <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
        <DialogActions> 
          <DialogButton action="Denied">Cancelar</DialogButton>      
          <DialogButton action="accept" onClick={()=>toDelete(user.id)} className={"btnDelete"} icon="delete">
            Excluir
          </DialogButton>
        </DialogActions>
      </Dialog>

        <Dialog
        open={openWaiterModal}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenWaiterModal(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Editar o garçom {user? user.name:''}</DialogTitle>
        <form onSubmit={handleEditWaiter}>
        <DialogContent>
        <TextField type="text" name="name" fullwidth placeholder="Nome" value={name} onChange={({target})=>setName(target.value)} required/>
        {userName.error && userName.error}
        <TextField type="text" name="email" fullwidth placeholder="Email" value={email} onChange={({target})=>setEmail(target.value)} required/>
        <TextField type="text" name="phone" fullwidth placeholder="Telefone" value={phone} onChange={({target})=>setPhone(target.value)} required/>
        <TextField type="text" name="cpf" fullwidth placeholder="CPF" value={cpf} onChange={({target})=>setCpf(target.value)} required/>
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept" className={"btnSave"} icon="save">
            Salvar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog> 
    <Dialog
        open={openAddWaiter}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenAddWaiter(false);
        }}
        onClosed={evt => {console.log(evt.detail.action);}}
      >
        <DialogTitle>Cadastrar usuário</DialogTitle>

        <DialogContent>
        <Select label="Selecione o usuário" options={['Garçom','Gerente']} name="cargo" 
      onChange={(evt) => setUserValue(evt.currentTarget.value)}/>
        <TextField type="text" name="name" fullwidth placeholder="Nome" {...userName} required/>
        <TextField type="text" name="email" fullwidth placeholder="Email" {...userEmail} required/>
        <TextField type="text" name="phone" fullwidth placeholder="Telefone" {...userPhone}/>
        <TextField type="text" name="cpf" fullwidth placeholder="CPF" {...userCpf}/>
        <TextField type="password" name="password" fullwidth placeholder="Senha" {...userPassword} required/>
        <TextField type="password" name="password" fullwidth placeholder="Confirme a Senha" {...userPasswordConfirm} required/>
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept" className={"btnSave"} icon="save" onClick={handleAddWaiter} disabled={isDisabled}>
            Cadastrar
          </DialogButton>
        </DialogActions>

      </Dialog>      
      <Dialog
        open={openAddManager}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenAddManager(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Cadastrar novo gerente</DialogTitle>
        <form onSubmit={handleAddManager}>
        <DialogContent>
        
        <TextField type="text" name="name" fullwidth placeholder="Nome" />
        <TextField type="text" name="email" fullwidth placeholder="Email" />
        <TextField type="text" name="phone" fullwidth placeholder="Telefone" />
        <TextField type="text" name="cpf" fullwidth placeholder="CPF" />
        <TextField type="password" name="password" fullwidth placeholder="Senha" />
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept" className={"btnEdit"} icon="save">
            Cadastrar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog>    

      <Dialog
        open={openEditManagerModal}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenEditManagerModal(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Editar o gerente {user? user.name:''}</DialogTitle>
        <form onSubmit={handleEditManager}>
        <DialogContent>
        <TextField type="text" name="name" fullwidth placeholder="Nome" value={name} onChange={({target})=>setName(target.value)} required/>
        <TextField type="text" name="email" fullwidth placeholder="Email" value={email} onChange={({target})=>setEmail(target.value)} required/>
        <TextField type="text" name="phone" fullwidth placeholder="Telefone" value={phone} onChange={({target})=>setPhone(target.value)} required/>
        <TextField type="text" name="cpf" fullwidth placeholder="CPF" value={cpf} onChange={({target})=>setCpf(target.value)} required/>
        </DialogContent>
        <DialogActions>      
          <DialogButton action="accept" className={"btnSave"} icon="save">
            Salvar
          </DialogButton>
        </DialogActions>
        </form>
      </Dialog>   

      <Dialog
        open={openDeleteManagerModal}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenDeleteManagerModal(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogTitle>Excluir {user?user.name:""}?</DialogTitle>
        <DialogContent>Esta operação não pode ser desfeita!</DialogContent>
        <DialogActions> 
          <DialogButton action="Denied">Cancelar</DialogButton>      
          <DialogButton action="accept" onClick={()=>toDeleteManager(user.id)} className={"btnDelete"} icon="delete">
            Excluir
          </DialogButton>
        </DialogActions>
      </Dialog>


    <MainNav/> 
    { loading && <div className="loading" ><CircularProgress size={125} /></div>}
     { !loading && <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Usuários</Typography></h1>             
          </div>
          <div  className="panelContainer">
            <Grid>
            <GridRow>
                <GridCell span={6}><Typography use="headline5">Administradores</Typography></GridCell>
                <GridCell span={6}>
                  <Button className={"BtnDefaultTmenu"} label="Adicionar usuário" icon="add"  onClick={()=>setOpenAddWaiter(true)}/>
                  </GridCell>
            </GridRow>
                <GridRow>
                  <GridCell>
                    <DataTable className={"TabelaProdutos"}>
                      <DataTableContent>
                        <DataTableHead>
                          <DataTableRow>
                          <DataTableHeadCell>Código</DataTableHeadCell>
                            <DataTableHeadCell>Nome</DataTableHeadCell>
                            <DataTableHeadCell >Email</DataTableHeadCell>
                            <DataTableHeadCell>CPF</DataTableHeadCell>
                            <DataTableHeadCell >Telefone</DataTableHeadCell>
                            <DataTableHeadCell ></DataTableHeadCell>                            
                          </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                          {
                            company.admins.map(admin=>{
                              return(
                                <DataTableRow key={admin.id} >
                                  <DataTableCell >{admin.id}</DataTableCell>
                                  <DataTableCell >{admin.name}</DataTableCell>
                                  <DataTableCell >{admin.email}</DataTableCell>
                                  <DataTableCell >{admin.cpf}</DataTableCell>
                                  <DataTableCell >{admin.phone}</DataTableCell>
                                  <DataTableCell >
                                  <Button label="Editar" outlined  className={"btnEdit"} icon="edit"/>
                                  </DataTableCell>
                                </DataTableRow>
                              )
                            })
                          }
                        </DataTableBody>
                      </DataTableContent>
                    </DataTable>
                  </GridCell>
                </GridRow>
                <GridRow>
                <GridCell span={6}><Typography use="headline5">Gerentes</Typography></GridCell>
               
                </GridRow>
                
                <GridRow>
                  <GridCell>
                    <DataTable className={"TabelaProdutos"}>
                      <DataTableContent>
                        <DataTableHead>
                          <DataTableRow>
                          <DataTableHeadCell>Código</DataTableHeadCell>
                            <DataTableHeadCell>Nome</DataTableHeadCell>
                            <DataTableHeadCell >Email</DataTableHeadCell>
                            <DataTableHeadCell>CPF</DataTableHeadCell>
                            <DataTableHeadCell >Telefone</DataTableHeadCell>
                            <DataTableHeadCell ></DataTableHeadCell>  
                            <DataTableHeadCell ></DataTableHeadCell>                          
                          </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                          {
                            company.managers.map(manager=>{
                              return(
                                <DataTableRow key={manager.id} >
                                  <DataTableCell >{manager.id}</DataTableCell>
                                  <DataTableCell >{manager.name}</DataTableCell>
                                  <DataTableCell >{manager.email}</DataTableCell>
                                  <DataTableCell >{manager.cpf}</DataTableCell>
                                  <DataTableCell >{manager.phone}</DataTableCell>
                                  <DataTableCell >
                                  <Button label="Editar" outlined  onClick={()=>setOpenEditManager(manager.id)} className={"btnEdit"} icon="edit"/>
                                  <Button label="Excluir" outlined  onClick={()=>deleteManager(manager.id)} className={"btnDelete"} icon="delete"/>
                                  </DataTableCell>

                                </DataTableRow>
                              )
                            })
                          }
                        </DataTableBody>
                      </DataTableContent>
                    </DataTable>
                  </GridCell>
                </GridRow>
                <GridRow>
                <GridCell span={6}><
                  Typography use="headline5">Garçons</Typography>
                  </GridCell>
                 
                </GridRow>
                <GridRow>
                  <GridCell>
                    <DataTable className={"TabelaProdutos"}>
                      <DataTableContent>
                        <DataTableHead>
                          <DataTableRow>
                          <DataTableHeadCell>Código</DataTableHeadCell>
                            <DataTableHeadCell>Nome</DataTableHeadCell>
                            <DataTableHeadCell >Email</DataTableHeadCell>
                            <DataTableHeadCell>CPF</DataTableHeadCell>
                            <DataTableHeadCell >Telefone</DataTableHeadCell>
                            <DataTableHeadCell ></DataTableHeadCell>   
                            <DataTableHeadCell ></DataTableHeadCell>                          
                          </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                          {
                            company.waiters.map(waiter=>{
                              return(
                                <DataTableRow key={waiter.id} >
                                  <DataTableCell >{waiter.id}</DataTableCell>
                                  <DataTableCell >{waiter.name}</DataTableCell>
                                  <DataTableCell >{waiter.email}</DataTableCell>
                                  <DataTableCell >{waiter.cpf}</DataTableCell>
                                  <DataTableCell >{waiter.phone}</DataTableCell>
                                  <DataTableCell >
                                  <Button label="Editar" outlined  onClick={()=>setOpenEditWaiter(waiter.id)} className={"btnEdit"} icon="edit"/>
                                  <Button label="Excluir" outlined onClick={()=>deleteWaiter(waiter.id)} className={"btnDelete"} icon="delete"/>
                                  </DataTableCell>
                                </DataTableRow>
                              )
                            })
                          }
                        </DataTableBody>
                      </DataTableContent>
                    </DataTable>
                  </GridCell>
                </GridRow>
            </Grid> 
            <Snackbar
                        open={openSnack}
                        onClose={evt => setOpenSnack(false)}
                        message={message}
                        dismissesOnAction
                        action={
                        <SnackbarAction
                            label="OK"
                            onClick={() => console.log('Click Me')}
                        />
                        } 
                    />
            </div>
        </div>   }
      
    </>
  );
};

export default UsersIndex;
