import React from 'react'

const Pagination =({paginate, paginateUpdate})=>{
    const [pagination, setPagination] = React.useState([])
    let start = 0
    let and = 0
    if(paginate.lastpage<10){
        and = paginate.lastpage
    }else{
        and = 10
    }
    const addPaginate=()=>{
        const pagin = []
        start = paginate.page>5?paginate.page -5 : 1
        and = paginate.page<=6?and:start+10
        if(paginate.page<=paginate.lastpage){
            for(start; start <= and; start++){
                pagin.push(start)
            }
        }
       
        setPagination([...pagin])
    }

    React.useEffect(()=>{
        addPaginate()
    },[paginateUpdate])
    return (
        <div className="center">
        <div className="pagination">
            { paginate.page > 1 && <p onClick={paginateUpdate} id={parseInt(paginate.page)-1}>&laquo;</p>}
           {pagination.map(page=>{
                return(
                    <>
                    <span key={page}>
                    {
                        page == paginate.page?<p className="active" onClick={paginateUpdate}  id={page}>{page}</p>:
                        <p  onClick={paginateUpdate} id={page} className={page>paginate.lastpage?"desabled":""}>{page}</p>
                        
                    }
                    </span>
                    </>
                )
           })
           }
           { paginate.page != paginate.lastpage && pagination.length > 0 &&<p onClick={paginateUpdate} id={parseInt(paginate.page)+1}>&raquo;</p> }
        </div>
        </div>
    )
}

export default Pagination