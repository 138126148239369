import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import ReactToPrint, {useReactToPrint} from 'react-to-print';
import { GET_TABLE, GET_COMPANY,
    PUT_CLOSE_TABLE, API_URL, PUT_CLOSE_ORDER,
    POST_CREATE_ORDER, UPDATE_STATUS_ORDER
} from '../../Api';
import { Grid, GridCell, GridRow,
    Typography,
    Button,
    TextField,
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell,
    SimpleMenu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogButton,
    DialogContent,
    Select,
    Badge,
    IconButton,
    Icon,
    Switch,
    Radio,
    ImageList,
    ImageListItem,
    ImageListImageAspectContainer,
    ImageListImage,
    ImageListSupporting,
    ImageListLabel,
    CircularProgress,
    Snackbar,
    SnackbarAction
  } from "rmwc";
  import './Printer.css'
  import { useLocation } from 'react-router-dom';
import {PrinterTable} from './PrinterTable';
import {PrinterCard} from './PrinterCard';
const PrinterIndex =({slug, id})=>{
    //const {slug} =  useParams()
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const [company, setCompany] = React.useState(null)
    const [table, setTable] = React.useState(null)
    const [total, setTotal] = React.useState(null)
    const [data, setData] = React.useState(null)
    const location = useLocation();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

    const getData = async ()=>{
        try {
            if(slug === 'table'){
                const body = {
                    data:[]
                  }
                const {url, options} = PUT_CLOSE_TABLE(token, id, body)
                const response = await fetch(url, options)
                if(!response.ok) throw new Error(`Error: ${response.statusText}`)
                const {values} = await response.json()
                setData(values)
            }else if(slug === 'card'){
                const {url, options} = PUT_CLOSE_ORDER(token, id)
                const response = await fetch(url, options)
                if(!response.ok) throw new Error(`Error: ${response.statusText}`)
                const {values} = await response.json()
                console.log("CLS", values)
                setData(values)
            }
        } catch (error) {
            console.log(error)
        }finally{
           
        }
    }

    React.useEffect(()=>{
        getData()
    },[])
    return(
        <div ref={componentRef}> 
        <div className="btnGroup">
        <Button onClick={()=>navigate('/mesas')} label="voltar"/>
        <Button onClick={handlePrint} label="Imprimir"/>
        </div>
    

        <div>
            {slug === 'table' && <PrinterTable data={data} ref={componentRef}/>}
            {slug === 'card' && <PrinterCard data={data}  ref={componentRef}/>}
       
        </div>
        </div>
    )
}

export default PrinterIndex;