import React from 'react';
import { Grid, GridCell, GridRow
  } from "rmwc";
  import {valorFormatado, addComman, calcRates} from '../../Utils'
  import './Printer.css'


  export const PrinterTable = React.forwardRef(({data}, props,  ref)=>{
    const date = new Date()
        const mounht = `0${date.getMonth()+1}`.slice(-2)
        const day = `0${date.getUTCDate()}`.slice(-2)
        const year = date.getUTCFullYear()
        let ordem = 0
    const calcTotal =(values)=>{
        let total = 0
        values.map(value=>{
            total+=value.card.value
        })
        return total
    }
    return(
        <div  className="containerPrinter">
       {
           data && <Grid>
                <GridRow>
                <GridCell span={12}>
                <h3 className="title">{data.data && data.data[0].company.name}</h3>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={8}>
                <p>CNPJ: {data.data[0].company.cnpj}</p>
                </GridCell>
                <GridCell span={4}>
                <p>{`${day}/${mounht}/${year}`}</p>
                </GridCell>
                
            </GridRow>
            <GridRow>
                <GridCell span={12}>
                <p>{`${data.data[0].address.street} Nº ${data.data[0].address.number}`}</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={8}>
                <p>Garçom: {data.data[0].waiter?data.data[0].waiter.name:''}</p>
                </GridCell>
                <GridCell span={4}>
                <p>Mesa: {data.data[0].table.number}</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={2}>
                <p>Item</p>
                </GridCell>
                <GridCell span={4}>
                <p>PRODUTO</p>
                </GridCell>
                <GridCell span={2}>
                <p>QTD.</p>
                </GridCell>
                <GridCell span={2}>
                <p>PREÇO</p>
                </GridCell>
                <GridCell span={2}>
                <p>VALOR</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            {
                data.closed && data.closed.map(clos =>{
                   return (<>
                       {
                        clos.itens && clos.itens.map(item=>{
                            ordem += 1
                            return(
                                <GridRow key={item.id}>
                                    <GridCell span={2}>
                                    <p>{ordem}</p>
                                    </GridCell>
                                    <GridCell span={5}>
                                    <p>{item.name.slice(0,12)}</p>
                                    </GridCell>
                                    <GridCell span={1}>
                                    <p>{item.quantity}</p>
                                    </GridCell>
                                    <GridCell span={2}>
                                    <p>{addComman(item.preco)}</p>
                                    </GridCell>
                                    <GridCell span={2}>
                                    <p>{addComman(item.total)}</p>
                                    </GridCell>
                                </GridRow>
                            )
                        })
                       }
                        <GridRow>
                        <GridCell span={6}>
                            <p>{clos.user.name.slice(0,16)}</p>
                        </GridCell>
                        <GridCell span={6}>
                            <p>Sub-total: {addComman(clos.card.value)}</p>
                        </GridCell>
                    </GridRow>
                       </>
                   )
                   ordem = 0

                })
            }
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
                <GridCell span={12}>
               <p>OUTRAS DESPESAS</p>
                </GridCell>
            </GridRow>
            {
                data.data[2].rates && data.data[2].rates.map(rate=>{
                    return(
                        <GridRow key={rate.id}>
                            <GridCell span={8}>
                                <p>{rate.name}</p>
                            </GridCell>
                            <GridCell span={4}>
                                <p>{addComman((rate.value * rate.quantity))}</p>
                            </GridCell>
                        </GridRow>
                    )
                })
            }
            <GridRow>
                <GridCell span={12}>
               <p>------------------------------------------</p>
                </GridCell>
            </GridRow>
            <GridRow>
            <GridCell span={4}>
               <p></p>
                </GridCell>
                <GridCell span={4}>
               <p>Total: </p>
                </GridCell>
                <GridCell span={4}>
               <p>{valorFormatado(calcRates(data.data[2].rates, calcTotal(data.closed)))}</p>
                </GridCell>
            </GridRow>
           </Grid>
       }
        </div>
    )
})

