import React from 'react';
import {valorFormatado, addComman, calcRates, convertDate} from '../../Utils'
import './PrintNewOrder.css'

export const PrintOrderCard =React.forwardRef(( {data}, props, ref)=>{
    return(
        <div  className="containerPrintNewOrder">
           
            {
                data &&
                <div>
                    <div className="tableInfo"><strong>{`Mesa ${data.table} - ${convertDate(data.created_at)}`}</strong></div>
                    <div className="orderNumber">{`Pedido número ${data.id}`}</div>
                    <div className='descriptionItemOrder'>
                    <p>Itens: </p>
                    </div>
                    <div>
                        {
                            data.itens.length >=1 && data.itens.map(item=>{
                                return(
                                    <div>
                                    <div className='attributeName'>{`${item.quantity} ${item.product_name}`}</div>
                                    {
                                        item.attributes.length >=1 && item.attributes.map(attr=>{
                                           return(
                                               <div>
                                                  <div> {`${attr.attribute_name}`}</div>
                                                   {
                                                       attr.values.map(value=>{
                                                           return(
                                                               <div>
                                                                   {`${value.name_value}`}
                                                               </div>
                                                           )
                                                       })
                                                   }
                                               </div>
                                           )
                                        })
                                    }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='totalOrder'>{`Total pedido: ${valorFormatado(data.value)}`}</div>
                </div>
                    }
        </div>
    )
})